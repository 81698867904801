import { apiGet, apiPost } from "../../utils/axios";

export function GetTopQuestionWinners(id, winning) {
    return apiGet(`/questions/winner/?episode_id=${id}&winning=${winning}`)
}

export function GetChannelsAPI() {
    return apiGet('/channels')
}

export function UpdateTopWinnerAPI(data) {
    // let payload = {
    //     id: data && data.id || '',
    //     is_live: data && data.is_live || '',
    // }
    return apiPost(`/user/top_winners`, data)
}

export function GetEpisodeByChannelAPI(id) {
    return apiGet(`/episodes?channel_id=${id}`)
}