import Validator, { error } from 'is_js'
import { isEmpty } from "lodash";
import moment from 'moment';

function validateEpisode(data) {
    let errors = {}
    let episodeDate = moment(data.episodeDate).format('DD/MM/YYYY')
    let episodeStartTime = moment(data.episodeStartTime).format('HH:mm')
    let episodeDateValidation = moment(episodeDate + ' ' + episodeStartTime, 'DD/MM/YYYY HH:mm');

    episodeDateValidation = moment(episodeDateValidation).valueOf()


    if (!/\S/.test(data.channel)) {
        errors.channel = "Channel is required!"
    }

    if (!(data.episodeDate)) {
        errors.episodeDate = "Episode Date is required!"
    }

    if (!/\S/.test(data.episodeNumber)) {
        errors.episodeNumber = "Episode is required!"
    }

    if (!(data.episodeStartTime)) {
        errors.episodeStartTime = "Episode Start time is required!"
    }


    if ((moment(data.episodeDate).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD"))
        && (moment(data.episodeStartTime).format("HH:mm:ss") === moment().format("HH:mm:ss"))
        && !(moment().valueOf() <= moment(data && data.episodeStartTime).valueOf())) {
        errors.episodeTiming = 'Episode Start time should be greater than Present Time!'
    }
    if (!(data.episodeEndTime)) {
        errors.episodeEndTime = "Episode End time is required!"
    }



    if ((moment(data.episodeStartTime).format("HH:mm:ss") === moment().format("HH:mm:ss"))
        && (moment(data.episodeEndTime).format("HH:mm:ss") === moment().format("HH:mm:ss"))
        && (moment(data && data.episodeStartTime).valueOf() >= moment(data && data.episodeEndTime).valueOf())) {
        errors.episodeTiming = 'Episode end time should be greater than start time!'
    }


    // if (moment(data && data.episodeStartTime).valueOf() >= moment(data && data.episodeEndTime).valueOf()) {
    //     errors.episodeTiming = 'Episode end time should be greater than start time!'
    // }

    // episodeDateValidation
    // console.log("🚀 ~ file: episodeValidation.js ~ line 37 ~ validateEpisode ~ data.episodeStartTime", data.episodeStartTime)
    // if (moment(episodeDateValidation).isBefore(Date.now())) {
    //     errors.episodeTiming = 'Episode Start Time should be greater than Current Time'
    // }


    return {
        isValid: isEmpty(errors),
        errors: errors
    };
}

export default validateEpisode;