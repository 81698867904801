import React, { useEffect, useState } from 'react'
import InputField from '../../components/common/InputField'
import { Button, CircularProgress, FormControl, InputLabel, Select, MenuItem, Grid } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import * as channelAction from '../Top-12-Winners/action'
import * as action from './actions'
import { MuiPickersUtilsProvider, DatePicker, KeyboardTimePicker } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment'
import episodeValidation from '../Validation/episodeValidation'


const initialState = {
    episodeNumber: '',
    channel: '',
    episodeDate: null,
    episodeStartTime: null,
    episodeEndTime: null
}

function CreateEpisode(props) {
    const { onClose, toast, update, startDate, endDate } = props
    const dispatch = useDispatch()

    const [episodeDetails, setEpisodeDetails] = useState({ ...initialState })
    const [errors, setError] = useState({})
    const [dataChange, setDataChange] = useState(false)

    const { isLoading = false } = useSelector(state => state.episode)
    const { isLoading: Loading = false, channels: { data: channelList = [], total = "" } } = useSelector(state => state.topWinner)


    useEffect(() => {
        dispatch(channelAction.GetChannels())
        const { episodeDetails = {} } = props
        const { episode = "", channel_id = "", end_time_with_date = "", start_time_with_date = "" } = episodeDetails
        if (update) {
            setEpisodeDetails({
                ...episodeDetails, episodeNumber: episode, channel: channel_id,
                episodeDate: start_time_with_date, episodeEndTime: end_time_with_date, episodeStartTime: start_time_with_date
            })
        }
    }, [props.episodeDetails])


    const handleDateChange = (date) => {
        setEpisodeDetails({ ...episodeDetails, episodeDate: date })
        setDataChange(true)
        setError({ ...errors, episodeDate: '' })
    }

    const handleStartTime = (time) => {
        setEpisodeDetails({ ...episodeDetails, episodeStartTime: time })
        setDataChange(true)
        setError({ ...errors, episodeStartTime: '' })
    }

    const handleEndTime = (time) => {
        setEpisodeDetails({ ...episodeDetails, episodeEndTime: time })
        setDataChange(true)
        setError({ ...errors, episodeEndTime: '' })
    }

    const isValid = () => {
        const { isValid = true, errors = {} } = episodeValidation({ ...episodeDetails, })
        setError(errors)
        return isValid
    }


    const handleChange = (e) => {
        const { name = "", value = "" } = e.target
        let numberReg = /^[0-9]{0,10}$/;
        if (name == "episodeNumber" && value && !numberReg.test(value)) return;
        if (name == "episodeNumber" && value.length > 4) return;

        if (name === "channel") {
            let channelName = channelList.find(res => res.id === e.target.value)
            setEpisodeDetails({ ...episodeDetails, [name]: value, channel_name: channelName.title })
        } else {
            setEpisodeDetails({ ...episodeDetails, [name]: value })
        }
        setError({ ...errors, [name]: '' })
        setDataChange(true)
    }


    const handleSubmit = () => {
        const { episodeNumber, channel, episodeDate, episodeEndTime, episodeStartTime, channel_name } = episodeDetails
        const { rowsPerPage } = props

        if (isValid()) {
            if (update) {
                const { episodeDetails = {} } = props
                const { id = "" } = episodeDetails
                const formData = {
                    episode: Number(episodeNumber) || '',
                    channel_id: channel || '',
                    channel_name: channel_name || '',
                    broadcast_date: episodeDate && moment.utc(episodeDate).format('YYYY-MM-DD') || '',
                    start_time: episodeStartTime && moment.utc(episodeStartTime).format('HH:mm:ss') || '',
                    end_time: episodeEndTime && moment.utc(episodeEndTime).format('HH:mm:ss') || ''
                }
                if (dataChange) {
                    dispatch(action.updateEpisode(formData, id))
                        .then(({ res = "" }) => {
                            dispatch(action.searchEpisodeByFilter({ limit: rowsPerPage, startDate: moment(startDate).startOf('day').valueOf(), endDate: moment(endDate).endOf('day').valueOf() }))
                            toast.success(res || "Episode updated successfully");
                            props.onClose()
                            setDataChange(false)
                            // setEpisodeDetails(episodeDetails)
                            setEpisodeDetails({
                                ...episodeDetails,
                                episodeDate: null,
                                episodeStartTime: null,
                                episodeEndTime: null
                            })

                        })
                        .catch(({ message = '' }) => {
                            toast.error(message || 'Oops! Something went wrong')
                        })
                    return
                } else {
                    setDataChange(false)
                    props.onClose()
                    return
                }
            }

            const formData = {
                episode: Number(episodeNumber) || '',
                channel_id: channel || '',
                broadcast_date: episodeDate && moment.utc(episodeDate).format('YYYY-MM-DD') || '',
                start_time: episodeStartTime && moment.utc(episodeStartTime).format('HH:mm:ss') || '',
                end_time: episodeEndTime && moment.utc(episodeEndTime).format('HH:mm:ss') || ''
            }
            dispatch(action.createEpisode(formData))
                .then(({ res = "" }) => {
                    dispatch(action.searchEpisodeByFilter({ limit: rowsPerPage, startDate: moment(startDate).startOf('day').valueOf(), endDate: moment(endDate).endOf('day').valueOf() }))
                    toast.success(res || "Episode added  successfully");
                    props.onClose()
                })
                .catch(({ message = '' }) => {
                    toast.error(message || 'Oops! Something went wrong')
                })
        }
    }

    return (
        <React.Fragment>
            <div className="cat-popup add-episode-modal">
                <h6 className="page-heading">{props.update ? 'Edit Episode' : 'Add Episode'}</h6>
                <form onSubmit={handleSubmit} >
                    <InputField name="episodeNumber" value={episodeDetails.episodeNumber} label="Episode" placeholder="Please enter episode number"
                        onChange={handleChange} fullWidth error={errors.episodeNumber} />

                    <FormControl variant="outlined" className="channel-dropdown" fullWidth disabled={Loading}>
                        <InputLabel id="channel-name">Channel Name</InputLabel>
                        {Loading ? <div className="field-loader"> <CircularProgress color="primary" size={25} /> </div> : ''}
                        <Select
                            labelId="channel-name"
                            id="channel-name"
                            value={episodeDetails.channel}
                            onChange={(e) => handleChange(e)}
                            label="Channel Name"
                            name="channel"
                            error={errors.channel}
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            {
                                channelList.map(channel => {
                                    return <MenuItem value={channel?.id}>{channel?.title}</MenuItem>
                                })
                            }
                        </Select>
                    </FormControl>
                    <span className="cus-error mt-2">{errors?.channel}</span>
                    <div className="episode-date-main">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DatePicker
                                autoOk
                                clearable
                                name="episodeDate"
                                minDate={moment().startOf().valueOf()}
                                value={episodeDetails.episodeDate}
                                placeholder="01-12-2001"
                                onChange={date => handleDateChange(date)}
                                format="dd-MM-yyyy"
                                label="Episode Date"
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                fullWidth
                                margin="normal"
                                error={errors.episodeDate}
                            />
                        </MuiPickersUtilsProvider>
                        <span className="cus-error">{errors?.episodeDate}</span>
                    </div>

                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <div className="episode-date-sec">
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardTimePicker
                                        ampm={false}
                                        openTo="hours"
                                        views={["hours", "minutes", "seconds"]}
                                        format="HH:mm:ss"
                                        label="Episode Start Time"
                                        name="episodeStartTime"
                                        value={props.update ? moment.utc(episodeDetails.episodeStartTime).local() : episodeDetails.episodeStartTime}
                                        onChange={handleStartTime}
                                        error={errors.episodeDate}
                                    // minTime={moment().startOf().valueOf()}
                                    // minDate={new Date()}
                                    // clearable={true}
                                    // disablePast={true}

                                    />
                                </MuiPickersUtilsProvider>
                                <span className="cus-error">{errors?.episodeStartTime}</span>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <div className="episode-date-sec">
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardTimePicker
                                        ampm={false}
                                        openTo="hours"
                                        views={["hours", "minutes", "seconds"]}
                                        format="HH:mm:ss"
                                        label="Episode End Time"
                                        name="episodeEndTime"
                                        value={props.update ? moment.utc(episodeDetails.episodeEndTime).local() : episodeDetails.episodeEndTime}
                                        onChange={handleEndTime}
                                        error={errors.episodeDate}
                                        minDate={moment().startOf().valueOf()}
                                    />
                                </MuiPickersUtilsProvider>
                                <span className="cus-error">{errors?.episodeEndTime}</span>
                            </div>
                        </Grid>
                    </Grid>
                    <span className="cus-error">{errors?.episodeTiming}</span>

                </form>
                <div className="cust-buttons mt-4" >
                    <Button onClick={() => onClose()} className="button-btn new-btn-color mr-2" >Cancel</Button>
                    <Button onClick={handleSubmit} className='button-btn secondary-btn ml-2' disabled={isLoading} >
                        {isLoading ? <CircularProgress size="28px" color="white" /> : (update ? 'Update' : 'Add')}
                    </Button>
                </div>

            </div>
        </React.Fragment>
    )
}

export default CreateEpisode