import React, { useEffect, useReducer, useState } from 'react'
import InputField from '../../components/common/InputField'
import { Button, CircularProgress, Grid, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core'
import CustomToolTip from '../../components/common/ToolTip'
import CachedIcon from '@material-ui/icons/Cached';
import cameraIcon from '../../assets/images/camera.svg'
import CrossIconWhite from '../../assets/images/cross-white.svg'
import { useDispatch, useSelector } from 'react-redux'
import * as action from './actions'
import * as channelAction from '../Top-12-Winners/action'
import validateJury from '../Validation/juryValidation'
import moment from 'moment'
import Compress from "browser-image-compression";
import { undefined } from 'is_js';
import { options } from '../../constant';

const initialState = {
    episode: '',
    question: '',
    channel: '',
    imageSizeLimit: '',
    imgCheckLoad: false,
    imageSizeLimitError: '',
}

function AddJuryForm(props) {
    const dispatch = useDispatch()
    const [juryDetails, setJuryDetails] = useState({ ...initialState })
    const [imageBinaryData, setImageBinaryData] = useState('')
    const [image, setImage] = useState('')
    const [errors, setError] = useState({})
    const [dataChange, setDataChange] = useState(false)
    const [removeImg, setRemoveImg] = useState(false)
    const [ids, setIds] = useState({})
    const [selectedEpisodeId, setSelectedEpisodeId] = useState('')
    const [selectedEpisodeObject,setSelectedEpisodeObject] = useState('')
    const { isLoading = false } = useSelector(state => state.juryResult)
    const { channels = {}, episode = {}, isLoading: Loading = false } = useSelector(state => state.topWinner) || {}

    const { data: channelList = [], total } = channels || {}
    const { episodeList = {} } = episode
    const { Items: allEpisodes = [] } = episodeList


    useEffect(() => {
        dispatch(channelAction.GetChannels())
        const { juryResultDetails = {}, update } = props
        const { image = "", episode = "", question = "", channel = "" } = juryResultDetails
        if (update) {
            dispatch(channelAction.GetEpisodeByChannel(channel))
            setImage(image)
            setJuryDetails({ ...juryDetails, episode, question, channel })
        }
    }, [])

    const handleChange = (e) => {
        const { name, value } = e.target;
        setJuryDetails({ ...juryDetails, [name]: value })
        setError({ ...errors, episode: '' })
        name == 'channel' && dispatch(channelAction.GetEpisodeByChannel(value)).then(res => {
            let items = res.episodeList && res.episodeList.Items.length && res.episodeList.Items.map((item) => {
                setIds(item)
            })
        })
        setDataChange(true)
    }

    const handleEpisodeChange = (e) => {
        const { name, value } = e.target
        const selectedEpisodeObject = allEpisodes?.find(episode => episode.episode === value);
        setSelectedEpisodeObject(selectedEpisodeObject)
        const selectedEpisodeId = selectedEpisodeObject ? selectedEpisodeObject.id : null;
        setSelectedEpisodeId(selectedEpisodeId)
        setJuryDetails({ ...juryDetails, [name]: value })
        setError({ ...errors, episode: '' })
        setDataChange(true)
    }

    const isValid = () => {
        const { isValid = true, errors = {} } = validateJury({ ...juryDetails, image })
        setError(errors)
        return isValid
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        const { update, juryResultDetails, toast, startDate, endDate, rowsPerPage } = props

        if (isValid()) {
            if (update) {
                const { id, image_key = "" } = juryResultDetails
                const fd = new FormData()

                fd.append("episode", juryDetails.episode);
                fd.append("question", juryDetails.question);
                imageBinaryData && fd.append("image", imageBinaryData);
                fd.append("remove_image", removeImg ? image_key : '');
                fd.append("channel", juryDetails.channel);
                fd.append('channel_id', ids.channel_id ? ids.channel_id : selectedEpisodeObject?.channel_id);
                fd.append('episode_id', selectedEpisodeId)
                // fd.append('episode_id', ids.id)

                if (dataChange) {
                    dispatch(action.UpdateJuryResult(fd, id))
                        .then(({ res = "" }) => {
                            dispatch(action.SearchJuryByFilter({ limit: rowsPerPage, startDate: moment(startDate).startOf('day').valueOf(), endDate: moment(endDate).endOf('day').valueOf() }))
                            toast.success(res || "Jury result updated successfully");
                            props.onClose()
                            setDataChange(false)
                            setRemoveImg(false)
                            props.afterAction()
                        })
                        .catch(({ message = '' }) => {
                            toast.error(message || 'Oops! Something went wrong')
                        })
                    return
                } else {
                    setDataChange(false)
                    setRemoveImg(false)
                    props.onClose()
                    props.afterAction()
                    return
                }
            }

            const fd = new FormData()

            fd.append("episode", juryDetails.episode);
            fd.append("question", juryDetails.question);
            fd.append("image", imageBinaryData);
            fd.append("channel", juryDetails.channel);
            fd.append('channel_id', ids.channel_id ? ids.channel_id : selectedEpisodeObject?.channel_id);
            fd.append('episode_id', selectedEpisodeId)
            // fd.append('episode_id', ids.id)

            dispatch(action.CreateJuryResult(fd))
                .then(({ res = "" }) => {
                    dispatch(action.SearchJuryByFilter({ limit: rowsPerPage, startDate: moment(startDate).startOf('day').valueOf(), endDate: moment(endDate).endOf('day').valueOf() }))
                    toast.success(res || "Jury result added successfully");
                    props.onClose()
                    props.afterAction()
                })
                .catch(({ message = "" }) => {
                    toast.error(message || 'Oops! Something went wrong')
                })
        }

    }

    const handleImageChange = (e) => {
        setJuryDetails({ ...juryDetails, imgCheckLoad: true, imageSizeLimitError: '' })
        let file = e?.target?.files[0]
        if (!file) {
            setJuryDetails({...juryDetails,
                imageSizeLimitError: '',
            })
            return};
        Compress(file, options).then(res => {
            setJuryDetails({ ...juryDetails, imageSizeLimit: res.size })
            let imageSize = file?.size;
            if (imageSize <=  2000000) {
                
                if (res) {
                    const reader = new FileReader()
                    reader.onload = () => {
                        if (reader.readyState === 2) {
                            setImage(reader.result)
                            setImageBinaryData(Object.assign(res, { url: URL.createObjectURL(res) }))
                            setDataChange(true)
                        }
                    }
                    reader && reader.readAsDataURL(res)
                    setJuryDetails({ ...juryDetails, imgCheckLoad: false, imageSizeLimitError: "" })
                }
                setError({ ...errors, image: '' })
            }
            if(imageSize > 2000000){
                setJuryDetails({ ...juryDetails, imageSizeLimitError: "Image maximum size exceed 2 MB" })
                // props.toast.error("Image maximum size is 2 MB")
            }

        }).catch(err => {
            props.toast.error(err)
        })
    }

    const handleRemoveImage = () => {
        setImage('')
        setRemoveImg(true);
    }

    const replaceImage = () => {
        setRemoveImg(true);
    }


    return (
        <React.Fragment>
            <div className="cat-popup">    
                <h6 className="page-heading">{props.update ? 'Edit Jury Result' : 'Add Jury Result'}</h6>
                <form onSubmit={handleSubmit}>
                    <FormControl variant="outlined" className="channel-dropdown" fullWidth disabled={Loading}>
                        <InputLabel id="channel-name">Channel Name</InputLabel>
                        {Loading ? <div className="field-loader"> <CircularProgress color="primary" size={25} /> </div> : ''}
                        <Select
                            labelId="channel-name"
                            id="channel-name"
                            value={juryDetails.channel}
                            onChange={(e) => handleChange(e)}
                            label="Channel Name"
                            name="channel"
                            error={errors.channel}
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            {
                                channelList.map(channel => {
                                    return <MenuItem value={channel?.title}>{channel?.title}</MenuItem>
                                })
                            }
                        </Select>
                    </FormControl>
                    <span className="cus-error">{errors?.channel}</span>

                    {/* <div className="ques-field">
                        <InputField
                            name="question"
                            value={juryDetails.question}
                            onChange={(e) => handleChange(e)} fullWidth
                            label="Question"
                            placeholder="Please enter question"
                            error={errors.question}
                            // multiline
                            // rows={1}
                            inputProps={{ maxlength: 350 }}
                        />
                    </div> */}

                    <div className="episode-field mt-3">
                        <FormControl variant="outlined" className="channel-dropdown" fullWidth disabled={Loading} >
                            <InputLabel id="episode-name">Episode</InputLabel>
                            {Loading ? <div className="field-loader"> <CircularProgress color="primary" size={25} /> </div> : ''}
                            <Select
                                labelId="episode-name"
                                id="episode-name"
                                value={juryDetails.episode}
                                onChange={(e) => handleEpisodeChange(e)}
                                label="Episode"
                                name="episode"
                                placeholder="Please enter episode name"
                                error={errors?.episode}

                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {
                                    allEpisodes && allEpisodes.map(episode => {
                                        return <MenuItem value={episode?.episode} >{episode?.episode}</MenuItem>
                                    })
                                }
                            </Select>
                        </FormControl>

                        <div className={image ? "img-grid2" : "img-grid"} >
                            <img src={image} alt="" id="img" className={image ? "img-added" : "img-browse"} />
                            <div className="camera" style={{ display: 'inline-block' }}>
                                <label htmlFor="option-img" >
                                    {image ?
                                        <CustomToolTip title="Replace Image"><span className="replace-img" onClick={replaceImage} > <CachedIcon />   </span>
                                        </CustomToolTip>
                                        :
                                        <CustomToolTip title="Add Image">
                                            <span className="add-img" >
                                                {juryDetails?.imgCheckLoad ? <div className="img-camera-loader"> <CircularProgress  size={24} /> </div> : <img src={cameraIcon} />}
                                            </span>
                                        </CustomToolTip>
                                    }

                                </label>

                                <input type="file" accept="image/jpeg, image/png" id="option-img" onChange={(e) => handleImageChange(e)} hidden />
                            </div>

                        </div>

                        {image ? <CustomToolTip title="Remove Image" > <img className="remove-img" src={CrossIconWhite} alt="" onClick={() => handleRemoveImage()} /> </CustomToolTip> : ''}

                    </div>
                    {juryDetails?.imageSizeLimitError &&
                        <div className='d-flex justify-content-end text-danger'>
                            <p>{juryDetails?.imageSizeLimitError}
                            </p>
                        </div>}
                        {juryDetails?.imgCheckLoad &&   
                        <div className='d-flex justify-content-end text-primary'>
                    <p>Image uploading...</p>
                        </div>}
                    <Grid container>
                        <Grid item xs={12} sm={6} md={6} lg={6}>
                            <p className="cus-error">{errors?.episode}</p>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6}>
                            <p className="cus-error" style={{ textAlign: 'end' }} >{errors?.image}</p>
                        </Grid>
                    </Grid>

                    <p className="img-msg my-1">Image maximum size is 2 MB</p>
                </form>
                <div className="cust-buttons mt-4" >
                    <Button onClick={() => props.onClose()} className="button-btn new-btn-color mr-2" >Cancel</Button>
                    <Button onClick={handleSubmit} className='button-btn secondary-btn ml-2' disabled={isLoading} >
                        {isLoading ? <CircularProgress size="28px" color="white" /> : (props.update ? 'Update' : 'Add')}
                    </Button>
                </div>
            </div>
        </React.Fragment>
    )
}

export default AddJuryForm