import {
  
GET_TABLE_WINNER_LIST_REQUEST,
GET_TABLE_WINNER_LIST_REQUEST_SUCCEEDED,
GET_TABLE_WINNER_LIST_REQUEST_FAILED,

DELETE_TABLE_WINNER,
DELETE_TABLE_WINNER_SUCCESS, 
DELETE_TABLE_WINNER_FAILED,

CREATE_TABLE_WINNER,
CREATE_TABLE_WINNER_SUCCESS, 
CREATE_TABLE_WINNER_FAILED,

UPDATE_TABLE_WINNER,
UPDATE_TABLE_WINNER_SUCCESS,
UPDATE_TABLE_WINNER_FAILED,

} from './actions'

const initState = {
  isLoading: false,
  winnerTable : {},
}

export default function (state = { ...initState }, action) {
  switch (action.type) {
    case GET_TABLE_WINNER_LIST_REQUEST : 
      return {...state , isLoading : true}
    
    case GET_TABLE_WINNER_LIST_REQUEST_SUCCEEDED : 
      return {...state , isLoading : false , winnerTable : action.payload || {} }

    case GET_TABLE_WINNER_LIST_REQUEST_FAILED : 
      return {...state , isLoading : false , winnerTable : {}}


    case DELETE_TABLE_WINNER : 
      return {...state, isLoading : true}
    
    case DELETE_TABLE_WINNER_SUCCESS : 
      return {...state, isLoading : false}

    case DELETE_TABLE_WINNER_FAILED : 
      return {...state, isLoading : false}
    

    case CREATE_TABLE_WINNER : 
      return {...state , isLoading : true}
    
    case CREATE_TABLE_WINNER_SUCCESS : 
      return {...state, isLoading : false}
    
    case CREATE_TABLE_WINNER_FAILED : 
      return {...state, isLoading : false}


    case UPDATE_TABLE_WINNER : 
      return {...state, isLoading : true}
    
    case UPDATE_TABLE_WINNER_SUCCESS : 
      return {...state, isLoading : false}

    case UPDATE_TABLE_WINNER_FAILED : 
      return {...state, isLoading : false}
    
    default:
      return state
  }
}