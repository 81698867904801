import {
    GetHostAPI,
    DeleteHostAPI,
    UpdateHostAPI,
    CreateHostAPI,
    SearchHostByFilterAPI
} from './apis'

export function GetHost(data) {
    return dispatch => {
        dispatch({
            type: GET_HOST_LIST_REQUEST,
        })
        return new Promise((resolve, rej) => {
            GetHostAPI(data).then(res => {
                dispatch({
                    type: GET_HOST_LIST_REQUEST_SUCCEEDED,
                    payload: res
                })
                return resolve(res)
            })
                .catch(err => {
                    dispatch({
                        type: GET_HOST_LIST_REQUEST_FAILED,
                        payload: {},
                    })
                    return rej(err)
                })
        })
    }
}

export function SearchHostByFilter(data) {
    return dispatch => {
        dispatch({
            type: GET_HOST_LIST_REQUEST,
        })
        return new Promise((resolve, rej) => {
            SearchHostByFilterAPI(data).then(res => {
                dispatch({
                    type: GET_HOST_LIST_REQUEST_SUCCEEDED,
                    payload: res
                })
                return resolve(res)
            })
                .catch(err => {
                    dispatch({
                        type: GET_HOST_LIST_REQUEST_FAILED,
                        payload: {},
                    })
                    return rej(err)
                })
        })
    }
}

export const GET_HOST_LIST_REQUEST = 'GET_HOST_LIST_REQUEST'
export const GET_HOST_LIST_REQUEST_SUCCEEDED = 'GET_HOST_LIST_REQUEST_SUCCEEDED'
export const GET_HOST_LIST_REQUEST_FAILED = 'GET_HOST_LIST_REQUEST_FAILED'


export function DeleteHost(data) {
    return dispatch => {
        dispatch({
            type: DELETE_HOST
        })
        return new Promise((resolve, rej) => {
            DeleteHostAPI(data).then(res => {
                dispatch({
                    type: DELETE_HOST_SUCCESS,
                })
                return resolve(res)
            })
                .catch(err => {
                    dispatch({
                        type: DELETE_HOST_FAILED,
                    })
                    return rej(err)
                })
        })
    }
}

export const DELETE_HOST = "DELETE_HOST";
export const DELETE_HOST_SUCCESS = "DELETE_HOST_SUCCESS";
export const DELETE_HOST_FAILED = "DELETE_HOST_FAILED"

export function UpdateHost(data, id) {
    return dispatch => {
        dispatch({
            type: 'UPDATE_HOST'
        })
        return new Promise((resolve, rej) => {
            UpdateHostAPI(data, id).then(res => {
                dispatch({
                    type: 'UPDATE_HOST_SUCCESS'
                })
                return resolve(res)
            })
                .catch(err => {
                    dispatch({
                        type: 'UPDATE_HOST_FAILED'
                    })
                    return rej(err)
                })
        })
    }
}

export const UPDATE_HOST = "UPDATE_HOST"
export const UPDATE_HOST_SUCCESS = "UPDATE_HOST_SUCCESS"
export const UPDATE_HOST_FAILED = "UPDATE_HOST_FAILED"

export function CreateHost(data) {
    return dispatch => {
        dispatch({
            type: 'CREATE_HOST'
        })
        return new Promise((resolve, rej) => {
            CreateHostAPI(data).then(res => {
                dispatch({
                    type: 'CREATE_HOST_SUCCESS'
                })
                return resolve(res)
            })
                .catch(err => {
                    dispatch({
                        type: 'CREATE_HOST_FAILED'
                    })
                    return rej(err)
                })
        })
    }
}

export const CREATE_HOST = 'CREATE_HOST'
export const CREATE_HOST_SUCCESS = "CREATE_HOST_SUCCESS"
export const CREATE_HOST_FAILED = "CREATE_HOST_FAILED"
