import {
    GetJuryResultAPI,
    DeleteJuryResultAPI,
    UpdateJuryResultAPI,
    CreateJuryResultAPI,
    SearchJuryByFilterAPI
} from './apis'

export function GetJuryResult(data) {
    return dispatch => {
        dispatch({
            type: GET_JURY_RESULT_LIST_REQUEST,
        })
        return new Promise((resolve, rej) => {
            GetJuryResultAPI(data).then(res => {
                dispatch({
                    type: GET_JURY_RESULT_LIST_REQUEST_SUCCEEDED,
                    payload: res
                })
                return resolve(res)
            })
                .catch(err => {
                    dispatch({
                        type: GET_JURY_RESULT_LIST_REQUEST_FAILED,
                        payload: {},
                    })
                    return rej(err)
                })
        })
    }
}

export function SearchJuryByFilter(data) {
    return dispatch => {
        dispatch({
            type: GET_JURY_RESULT_LIST_REQUEST,
        })
        return new Promise((resolve, rej) => {
            SearchJuryByFilterAPI(data).then(res => {
                dispatch({
                    type: GET_JURY_RESULT_LIST_REQUEST_SUCCEEDED,
                    payload: res
                })
                return resolve(res)
            })
                .catch(err => {
                    dispatch({
                        type: GET_JURY_RESULT_LIST_REQUEST_FAILED,
                        payload: {},
                    })
                    return rej(err)
                })
        })
    }
}

export const GET_JURY_RESULT_LIST_REQUEST = 'GET_JURY_RESULT_LIST_REQUEST'
export const GET_JURY_RESULT_LIST_REQUEST_SUCCEEDED = 'GET_JURY_RESULT_LIST_REQUEST_SUCCEEDED'
export const GET_JURY_RESULT_LIST_REQUEST_FAILED = 'GET_JURY_RESULT_LIST_REQUEST_FAILED'


export function DeleteJuryResult(data) {
    return dispatch => {
        dispatch({
            type: DELETE_JURY_RESULT
        })
        return new Promise((resolve, rej) => {
            DeleteJuryResultAPI(data).then(res => {
                dispatch({
                    type: DELETE_JURY_RESULT_SUCCESS,
                })
                return resolve(res)
            })
                .catch(err => {
                    dispatch({
                        type: DELETE_JURY_RESULT_FAILED,
                    })
                    return rej(err)
                })
        })
    }
}

export const DELETE_JURY_RESULT = "DELETE_JURY_RESULT";
export const DELETE_JURY_RESULT_SUCCESS = "DELETE_JURY_RESULT_SUCCESS";
export const DELETE_JURY_RESULT_FAILED = "DELETE_JURY_RESULT_FAILED"

export function UpdateJuryResult(data, id) {
    return dispatch => {
        dispatch({
            type: 'UPDATE_JURY_RESULT'
        })
        return new Promise((resolve, rej) => {
            UpdateJuryResultAPI(data, id).then(res => {
                dispatch({
                    type: 'UPDATE_JURY_RESULT_SUCCESS'
                })
                return resolve(res)
            })
                .catch(err => {
                    dispatch({
                        type: 'UPDATE_JURY_RESULT_FAILED'
                    })
                    return rej(err)
                })
        })
    }
}

export const UPDATE_JURY_RESULT = "UPDATE_JURY_RESULT"
export const UPDATE_JURY_RESULT_SUCCESS = "UPDATE_JURY_RESULT_SUCCESS"
export const UPDATE_JURY_RESULT_FAILED = "UPDATE_JURY_RESULT_FAILED"

export function CreateJuryResult(data) {
    return dispatch => {
        dispatch({
            type: 'CREATE_JURY_RESULT'
        })
        return new Promise((resolve, rej) => {
            CreateJuryResultAPI(data).then(res => {
                dispatch({
                    type: 'CREATE_JURY_RESULT_SUCCESS'
                })
                return resolve(res)
            })
                .catch(err => {
                    dispatch({
                        type: 'CREATE_JURY_RESULT_FAILED'
                    })
                    return rej(err)
                })
        })
    }
}

export const CREATE_JURY_RESULT = 'CREATE_JURY_RESULT'
export const CREATE_JURY_RESULT_SUCCESS = "CREATE_JURY_RESULT_SUCCESS"
export const CREATE_JURY_RESULT_FAILED = "CREATE_JURY_RESULT_FAILED"
