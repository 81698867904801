import {

    GET_EPISODE_REQUEST,
    GET_EPISODE_REQUEST_SUCCEEDED,
    GET_EPISODE_REQUEST_FAILED,

    DELETE_EPISODE,
    DELETE_EPISODE_SUCCESS,
    DELETE_EPISODE_FAILED,

    CREATE_EPISODE,
    CREATE_EPISODE_SUCCESS,
    CREATE_EPISODE_FAILED,

    UPDATE_EPISODE,
    UPDATE_EPISODE_SUCCESS,
    UPDATE_EPISODE_FAILED,

} from './actions'

const initState = {
    isLoading: false,
    episode: {},
}

export default function (state = { ...initState }, action) {
    switch (action.type) {
        case GET_EPISODE_REQUEST:
            return { ...state, isLoading: true }

        case GET_EPISODE_REQUEST_SUCCEEDED:
            return { ...state, isLoading: false, episode: action.payload || {} }

        case GET_EPISODE_REQUEST_FAILED:
            return { ...state, isLoading: false, episode: {} }


        case DELETE_EPISODE:
            return { ...state, isLoading: true }

        case DELETE_EPISODE_SUCCESS:
            return { ...state, isLoading: false }

        case DELETE_EPISODE_FAILED:
            return { ...state, isLoading: false }


        case CREATE_EPISODE:
            return { ...state, isLoading: true }

        case CREATE_EPISODE_SUCCESS:
            return { ...state, isLoading: false }

        case CREATE_EPISODE_FAILED:
            return { ...state, isLoading: false }


        case UPDATE_EPISODE:
            return { ...state, isLoading: true }

        case UPDATE_EPISODE_SUCCESS:
            return { ...state, isLoading: false }

        case UPDATE_EPISODE_FAILED:
            return { ...state, isLoading: false }

        default:
            return state
    }
}