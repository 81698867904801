import {
    GetTopQuestionWinners,
    GetChannelsAPI,
    GetEpisodeByChannelAPI,
    UpdateTopWinnerAPI
} from './apis'

export function GetTopQuestions(data,winning) {
    return dispatch => {
        dispatch({
            type: GET_TOP_QUESTIONS_WINNERS,
        })
        return new Promise((resolve, rej) => {
            GetTopQuestionWinners(data, winning).then(res => {
                dispatch({
                    type: GET_TOP_QUESTIONS_WINNERS_SUCCEEDED,
                    payload: res
                })
                return resolve(res)
            })
                .catch(err => {
                    dispatch({
                        type: GET_TOP_QUESTIONS_WINNERS_FAILED,
                        payload: {},
                    })
                    return rej(err)
                })
        })
    }
}

export const GET_TOP_QUESTIONS_WINNERS = 'GET_TOP_QUESTIONS_WINNERS'
export const GET_TOP_QUESTIONS_WINNERS_SUCCEEDED = 'GET_TOP_QUESTIONS_WINNERS_SUCCEEDED'
export const GET_TOP_QUESTIONS_WINNERS_FAILED = 'GET_TOP_QUESTIONS_WINNERS_FAILED'

export function GetChannels(data) {
    return dispatch => {
        dispatch({
            type: GET_TOP_CHANNEL,
        })
        return new Promise((resolve, rej) => {
            GetChannelsAPI(data).then(res => {
                dispatch({
                    type: GET_TOP_CHANNEL_SUCCEEDED,
                    payload: res
                })
                return resolve(res)
            })
                .catch(err => {
                    dispatch({
                        type: GET_TOP_CHANNEL_FAILED,
                        payload: {},
                    })
                    return rej(err)
                })
        })
    }
}

export const GET_TOP_CHANNEL = 'GET_TOP_CHANNEL'
export const GET_TOP_CHANNEL_SUCCEEDED = 'GET_TOP_CHANNEL_SUCCEEDED'
export const GET_TOP_CHANNEL_FAILED = 'GET_TOP_CHANNEL_FAILED'

export function GetEpisodeByChannel(data) {
    return dispatch => {
        dispatch({
            type: GET_EPISODE_BY_CHANNEL,
        })
        return new Promise((resolve, rej) => {
            GetEpisodeByChannelAPI(data).then(res => {
                dispatch({
                    type: GET_EPISODE_BY_CHANNEL_SUCCEEDED,
                    payload: res
                })
                return resolve(res)
            })
                .catch(err => {
                    dispatch({
                        type: GET_EPISODE_BY_CHANNEL_FAILED,
                        payload: {},
                    })
                    return rej(err)
                })
        })
    }
}

export const GET_EPISODE_BY_CHANNEL = 'GET_EPISODE_BY_CHANNEL'
export const GET_EPISODE_BY_CHANNEL_SUCCEEDED = 'GET_EPISODE_BY_CHANNEL_SUCCEEDED'
export const GET_EPISODE_BY_CHANNEL_FAILED = 'GET_TOP_CHANNEL_FAILED'

export function CreateTopWinners(data) {
    return dispatch => {
        dispatch({
            type: 'CREATE_TOP_WINNER'
        })
        return new Promise((resolve, rej) => {
            UpdateTopWinnerAPI(data).then(res => {
                dispatch({
                    type: 'CREATE_TOP_WINNER_SUCCESS'
                })
                return resolve(res)
            })
                .catch(err => {
                    dispatch({
                        type: 'CREATE_TOP_WINNER_FAILED'
                    })
                    return rej(err)
                })
        })
    }
}

export const CREATE_TOP_WINNER = 'CREATE_TOP_WINNER'
export const CREATE_TOP_WINNER_SUCCESS = "CREATE_TOP_WINNER_SUCCESS"
export const CREATE_TOP_WINNER_FAILED = "CREATE_TOP_WINNER_FAILED"