import {

    CREATE_QUESTION,
    CREATE_QUESTION_SUCCESS, 
    CREATE_QUESTION_FAILED,

    GET_QUESTION,
    GET_QUESTION_SUCCESS, 
    GET_QUESTION_FAILED,

    UPDATE_QUESTION,
    UPDATE_QUESTION_SUCCESS,
    UPDATE_QUESTION_FAILED,

    DELETE_QUESTION,
    DELETE_QUESTION_SUCCESS,
    DELETE_QUESTION_FAILED,

} from './questionAction'

const initialState = {
    isLoadingQus : false,
    juryCateQuestions : {}
}

export default function (state = {...initialState}, action ) {
    switch(action.type){
        case CREATE_QUESTION : 
            return {...state , isLoadingQus : true }
        
        case CREATE_QUESTION_SUCCESS : 
            return {...state , isLoadingQus : false }

        case CREATE_QUESTION_FAILED : 
            return {...state , isLoadingQus : false }


        case GET_QUESTION : 
            return {...state , isLoadingQus : true }
        
        case GET_QUESTION_SUCCESS : 
            return {...state , isLoadingQus : false , juryCateQuestions : action.payload || {} }

        case GET_QUESTION_FAILED : 
            return {...state , isLoadingQus : false , juryCateQuestions : {} }
        

        case UPDATE_QUESTION : 
            return {...state , isLoadingQus : true }
        
        case UPDATE_QUESTION_SUCCESS : 
            return {...state , isLoadingQus : false }

        case UPDATE_QUESTION_FAILED : 
            return {...state , isLoadingQus : false }


        case DELETE_QUESTION : 
            return {...state , isLoadingQus : true }
        
        case DELETE_QUESTION_SUCCESS : 
            return {...state , isLoadingQus : false }

        case DELETE_QUESTION_FAILED : 
            return {...state , isLoadingQus : false }

        default : 
            return state
    }
}