import { apiDelete, apiGet, apiPost, apiPut } from "../../../utils/axios";

export function createQuestionAPI(data) {
    return apiPost(`/questions`, data)
}

export function updateQuestionAPI(data, id,) {
    return apiPut(`/questions/${id}`, data)
}


export function updateQuestionStatusAPI(id, data) {
    return apiPut(`/questions/status/${id}`, data)
}

export function getQuestionAPI(data) {
    return apiGet(`/questions?cat_id=${data}`)
}

export function deleteQuestionAPI(data) {
    return apiDelete(`/questions/${data}`)
}

export function getQuestionByFilterAPI(data) {
    let payload = {
        term: data && data.term || '',
        type: 'question',
        cat_id: data && data.cat_id || ''
    }
    return apiGet(`/questions`, payload)
}