import {
    GetChannelAPI,
    DeleteChannelAPI,
    UpdateChannelAPI,
    CreateChannelAPI,
    SearchChannelByFilterAPI
} from './apis'

export function GetChannel(data) {
    return dispatch => {
        dispatch({
            type: GET_CHANNEL_LIST_REQUEST,
        })
        return new Promise((resolve, rej) => {
            GetChannelAPI(data).then(res => {
                dispatch({
                    type: GET_CHANNEL_LIST_REQUEST_SUCCEEDED,
                    payload: res
                })
                return resolve(res)
            })
                .catch(err => {
                    dispatch({
                        type: GET_CHANNEL_LIST_REQUEST_FAILED,
                        payload: {},
                    })
                    return rej(err)
                })
        })
    }
}

export function SearchChannelByFilter(data) {
    return dispatch => {
        dispatch({
            type: GET_CHANNEL_LIST_REQUEST,
        })
        return new Promise((resolve, rej) => {
            SearchChannelByFilterAPI(data).then(res => {
                dispatch({
                    type: GET_CHANNEL_LIST_REQUEST_SUCCEEDED,
                    payload: res
                })
                return resolve(res)
            })
                .catch(err => {
                    dispatch({
                        type: GET_CHANNEL_LIST_REQUEST_FAILED,
                        payload: {},
                    })
                    return rej(err)
                })
        })
    }
}

export const GET_CHANNEL_LIST_REQUEST = 'GET_CHANNEL_LIST_REQUEST'
export const GET_CHANNEL_LIST_REQUEST_SUCCEEDED = 'GET_CHANNEL_LIST_REQUEST_SUCCEEDED'
export const GET_CHANNEL_LIST_REQUEST_FAILED = 'GET_CHANNEL_LIST_REQUEST_FAILED'


export function DeleteChannel(data) {
    return dispatch => {
        dispatch({
            type: DELETE_CHANNEL
        })
        return new Promise((resolve, rej) => {
            DeleteChannelAPI(data).then(res => {
                dispatch({
                    type: DELETE_CHANNEL_SUCCESS,
                })
                return resolve(res)
            })
                .catch(err => {
                    dispatch({
                        type: DELETE_CHANNEL_FAILED,
                    })
                    return rej(err)
                })
        })
    }
}

export const DELETE_CHANNEL = "DELETE_CHANNEL";
export const DELETE_CHANNEL_SUCCESS = "DELETE_CHANNEL_SUCCESS";
export const DELETE_CHANNEL_FAILED = "DELETE_CHANNEL_FAILED"

export function UpdateChannel(data, id) {
    return dispatch => {
        dispatch({
            type: 'UPDATE_CHANNEL'
        })
        return new Promise((resolve, rej) => {
            UpdateChannelAPI(data, id).then(res => {
                dispatch({
                    type: 'UPDATE_CHANNEL_SUCCESS'
                })
                return resolve(res)
            })
                .catch(err => {
                    dispatch({
                        type: 'UPDATE_CHANNEL_FAILED'
                    })
                    return rej(err)
                })
        })
    }
}

export const UPDATE_CHANNEL = "UPDATE_CHANNEL"
export const UPDATE_CHANNEL_SUCCESS = "UPDATE_CHANNEL_SUCCESS"
export const UPDATE_CHANNEL_FAILED = "UPDATE_CHANNEL_FAILED"

export function CreateChannel(data) {
    return dispatch => {
        dispatch({
            type: 'CREATE_CHANNEL'
        })
        return new Promise((resolve, rej) => {
            CreateChannelAPI(data).then(res => {
                dispatch({
                    type: 'CREATE_CHANNEL_SUCCESS'
                })
                return resolve(res)
            })
                .catch(err => {
                    dispatch({
                        type: 'CREATE_CHANNEL_FAILED'
                    })
                    return rej(err)
                })
        })
    }
}

export const CREATE_CHANNEL = 'CREATE_CHANNEL'
export const CREATE_CHANNEL_SUCCESS = "CREATE_CHANNEL_SUCCESS"
export const CREATE_CHANNEL_FAILED = "CREATE_CHANNEL_FAILED"
