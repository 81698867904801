import { apiDelete, apiGet, apiPost, apiPut } from "../../utils/axios";

export function getEpisodeAPI(data) {
    let payload = {
        limit: data && data.limit || '',
        // page : data && data.page || ''
    }
    return apiGet('/episodes/all', payload)
}

export function createEpisodeAPI(data) {
    return apiPost('/episodes', data)
}

export function editEpisodeAPI(data, id) {
    return apiPut(`/episodes/${id}`, data)
}

export function deleteEpisodeAPI(id) {
    return apiDelete(`/episodes/${id}`)
}

export function searchEpisodeAPI(data) {

    let term = data && data.term || ''
    let payload = {
        limit: data && data.limit || '',
        page: data && data.page || '',
        term: data && data.term || '',
        type: data && data.type || '',
        start_date: data && data.startDate || '',
        end_date: data && data.endDate || '',
        [data.key]: term || ''
    }
    return apiGet('/episodes/all', payload)
}