import React, { useEffect } from 'react'
import { useState } from 'react'
import { Button, CircularProgress, Grid } from '@material-ui/core'
import InputField from '../../components/common/InputField'
import * as action from './actions'
import { useDispatch, useSelector } from 'react-redux'
import loader from '../../assets/images/loader.gif'
import juryCategoryValidation from '../Validation/JuryCategoryValidation'
import CustomToolTip from '../../components/common/ToolTip'
import cameraIcon from '../../assets/images/camera.svg'
import CrossIconWhite from '../../assets/images/cross-white.svg'
import CachedIcon from '@material-ui/icons/Cached';
import Compress from "browser-image-compression";
import { options } from '../../constant'

const initialState = {
    imageSizeLimit: '',
    imgCheckLoad: false,
    imageSizeLimitError: '',
}

function AddCategoryForm(props) {
    const dispatch = useDispatch()
    const { isLoading = false } = useSelector(state => state.juryCategory) || {}
    const [juryImg, setjuryImg] = useState({ ...initialState })
    const [categoryName, setCategoryName] = useState('')
    const [categoryImage, setCategoryImage] = useState('')
    const [categoryImgBinaryData, setCategoryImgBinaryData] = useState('')
    const [removeChannelImg, setRemoveCategoryImg] = useState(false)
    const [errors, setError] = useState({})
    const [dataChange, setDataChange] = useState(false)
    const [removeImg, setRemoveImg] = useState(false)


    const handleChange = ({ target: { name = '', value = '' } }) => {
        setCategoryName(value)
        setError({ ...errors, [name]: '' })
        setDataChange(true)
    }

    useEffect(() => {
        const { categoryDetails = {}, update = false } = props
        const { cat_name = "", image = "" } = categoryDetails
        if (update) {
            setCategoryName(cat_name)
            setCategoryImage(image)
        }
    }, [])

    const isValid = () => {
        const { isValid = true, errors = {} } = juryCategoryValidation({ categoryName, categoryImage })
        setError(errors)
        return isValid;
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        const { toast, update } = props
        if (isValid()) {
            if (update) {
                const { categoryDetails } = props
                const { id = "", image_key = "" } = categoryDetails

                const fd = new FormData()

                fd.append("cat_name", categoryName);
                categoryImgBinaryData && fd.append('image', categoryImgBinaryData)
                fd.append("remove_image", removeImg ? image_key : '');
                if (dataChange) {
                    dispatch(action.EditCategory(fd, id)).then(response => {
                        props.onClose()
                        props.toast.success(response.message || 'Jury Category updated successfully!')
                        dispatch(action.GetCategories())
                        setDataChange(false)
                        props.afterAction()
                    }).catch(err => {
                        props.toast.error((err?.response?.data?.message) || "Something went wrong.")
                    })
                    return
                } else {
                    dispatch(action.GetCategories())
                    props.onClose()
                    props.afterAction()
                    return
                }
            }

            const fd = new FormData()

            fd.append("cat_name", categoryName);
            categoryImgBinaryData && fd.append('image', categoryImgBinaryData)

            dispatch(action.CreateCategory(fd))
                .then(res => {
                    props.onClose()
                    dispatch(action.GetCategories())
                    toast.success("Jury Category added successfully");
                    props.afterAction()
                })
                .catch(err => {
                    toast.error(err?.message || 'Oops! Something went wrong')
                })
        }

    }

    const handleChannelImageChange = (e) => {
        setjuryImg({ ...juryImg, imgCheckLoad: true, imageSizeLimitError: '' })
        let file = e?.target?.files[0]
        if (!file) {
            setjuryImg(initialState)
            return};
        Compress(file, options).then(res => {
            setjuryImg({ ...juryImg, imageSizeLimit: res.size })
            let imageSize = file?.size;
            if (imageSize <=  2000000) {
            if (res) {
                const reader = new FileReader()
                reader.onload = () => {
                    if (reader.readyState === 2) {
                        setCategoryImage(reader.result)
                        setCategoryImgBinaryData(Object.assign(file, { url: URL.createObjectURL(res) }))
                        setDataChange(true)
                    }
                }
                reader && reader.readAsDataURL(res)
                setjuryImg({ ...juryImg, imgCheckLoad: false,imageSizeLimitError: "" })
            }
            setError({ ...errors, categoryImage: '' })
            // setDataChange(true)
        } 
        if(imageSize > 2000000) {
            setjuryImg({ ...juryImg, imageSizeLimitError: "Image maximum size exceed 2 MB" })
            // props.toast.error("Image maximum size is 2 MB")
        }
    }).catch(err => {
        props.toast.error(err)
    })
    }

    const handleRemoveCategoryImage = () => {
        setCategoryImage('')
        setRemoveCategoryImg(true)
    }

    const replaceChannelImg = () => {
        setRemoveCategoryImg(true)
    }

    const valid = categoryName || false

    return (
        <React.Fragment>
            <div className="cat-popup">
                <h6 className="page-heading">{props.update ? 'Edit Jury Category' : 'Add Jury Category'}</h6>
                <form onSubmit={handleSubmit} className="mt-3" >
                    <div className="episode-field mt-3">
                        <InputField
                            name="categoryName"
                            value={categoryName}
                            label="Jury Category name"
                            placeholder="Please enter jury category name"
                            onChange={handleChange}
                            inputProps={{ maxlength: 40 }}
                            fullWidth
                            required
                        // autoFocus
                        />

                        <div className={categoryImage ? "img-grid2" : "img-grid"} >
                            <img src={categoryImage} alt="" id="img" className={categoryImage ? "img-added" : "img-browse"} />
                            <div className="camera" style={{ display: 'inline-block' }}>
                                <label htmlFor="channel-img" >
                                    {categoryImage ?
                                        <CustomToolTip title="Replace Image"><span className="replace-img" onClick={replaceChannelImg} > <CachedIcon /> </span></CustomToolTip>
                                        :
                                        <CustomToolTip title="Add Image">   <span className="add-img">  <img src={cameraIcon} alt="" /></span></CustomToolTip>
                                    }
                                </label>
                                <input type="file" accept="image/jpeg, image/png" id="channel-img" onChange={(e) => handleChannelImageChange(e)} hidden />
                            </div>
                        </div>
                        {categoryImage ? <CustomToolTip title="Remove Image" > <img className="remove-img" src={CrossIconWhite} alt="" onClick={() => handleRemoveCategoryImage()} /> </CustomToolTip> : ''}
                    </div>
                    {juryImg?.imageSizeLimitError &&
                        <div className='d-flex justify-content-end text-danger'>
                            <p>{juryImg?.imageSizeLimitError}
                            </p>
                        </div>}

                        {juryImg?.imgCheckLoad &&
                        <div className='d-flex justify-content-end text-primary'>
                    <p>Image uploading...</p>
                        </div>}
                    </form>

                    <Grid container>
                        <Grid item xs={12} sm={6} md={6} lg={6}>
                            <p className="cus-error">{errors?.categoryName}</p>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6}>
                            <p className="cus-error" style={{ textAlign: 'end' }} >{errors?.categoryImage}</p>
                        </Grid>
                    </Grid>

                    <p className="img-msg my-1">Image maximum size is 2 MB</p>

                <div className="mt-4 cust-buttons" >
                    <Button onClick={() => props.onClose()} className="button-btn new-btn-color mr-2" >Cancel</Button>
                    <Button onClick={handleSubmit} className='button-btn secondary-btn ml-2' disabled={isLoading} >
                        {isLoading ? <CircularProgress size="28px" color="white" /> : (props.update ? 'Update' : 'Add')}
                    </Button>
                </div>
            </div >
        </React.Fragment >
    )
}

export default AddCategoryForm