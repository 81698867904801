import Validator, { error } from 'is_js'
import { isEmpty } from "lodash";

function validation(data) {
    let errors = {}

    const Data = data.allOptions && data.allOptions.length
    const allOptionsData = data.allOptions

    // For Check Answer is Present or Not
    const ansCheckObject = Data ? allOptionsData?.filter(allOption => allOption.correct) : []

    // For Check option are Filled or not
    const ansObject = Data ? allOptionsData?.filter(allOption => (!/\S/.test(allOption.option))) : []

    // For Array List of All options
    const allOptions = Data ? allOptionsData?.map(data => data?.option) : []

    const optionLength = allOptions && allOptions?.length
    const removeRepeat = allOptions && [...new Set(allOptions)].length
    const isRepeat = optionLength == removeRepeat

    // For Check Image present and its length
    const imageData = Data ? allOptionsData?.map(data => data?.image) : []
    let imageLength = imageData && imageData.filter(e => e).length


    // For find the repeated option with the index
    function getDuplicates(arr = []) {
        var duplicates = {};
        for (var i = 0; i < arr.length; i++) {
            if (duplicates.hasOwnProperty(arr[i])) {
                duplicates[arr[i]].push(i + 1);
            } else if (arr.lastIndexOf(arr[i]) !== i) {
                duplicates[arr[i]] = [i + 1];
            }
        }
        const objectLength = duplicates && Object.keys(duplicates).length

        var data = objectLength ? Object.keys(duplicates).map(key => `Option '${key}' is same in option ${duplicates[key]}.`) : ''
        return data;
    };

    const repeatedOptList = getDuplicates(allOptions)
    // End
    // if (!(imageLength == 0) && !(imageLength == optionLength)) {
    //     errors.image = "Please add image in all fields!"
    // }

    //image mandatory checks
    if ( (imageLength != optionLength) ) {
        errors.image = "Please add image in all fields!"
    }
    if (!/\S/.test(data.question)) {
        errors.question = "Question is required!"
    }

    if ((ansObject.length) && !(imageLength == optionLength)) {
        errors.option = "All option fields is required!"

    } else if (Validator.empty(ansCheckObject)) {
        errors.answer = "Please select at least one answer!"

    } else {
        if (!imageLength) {
            if (repeatedOptList.length) {
                errors.repeated = "Options are repeated:"
                errors.repeatedList = repeatedOptList
            }

            // if(!isRepeat){
            //     errors.option = "Options are can't be same!"
            // }
        }
    }

    return {
        isValid: isEmpty(errors),
        errors: errors
    };
}

export default validation;


