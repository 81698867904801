import {
  
GET_CHANNEL_LIST_REQUEST,
GET_CHANNEL_LIST_REQUEST_SUCCEEDED,
GET_CHANNEL_LIST_REQUEST_FAILED,

DELETE_CHANNEL,
DELETE_CHANNEL_SUCCESS, 
DELETE_CHANNEL_FAILED,

CREATE_CHANNEL,
CREATE_CHANNEL_SUCCESS, 
CREATE_CHANNEL_FAILED,

UPDATE_CHANNEL,
UPDATE_CHANNEL_SUCCESS,
UPDATE_CHANNEL_FAILED,

} from './actions'

const initState = {
  isLoading: false,
  channel : {},
}

export default function (state = { ...initState }, action) {
  switch (action.type) {
    case GET_CHANNEL_LIST_REQUEST : 
      return {...state , isLoading : true}
    
    case GET_CHANNEL_LIST_REQUEST_SUCCEEDED : 
      return {...state , isLoading : false , channel : action.payload || {} }

    case GET_CHANNEL_LIST_REQUEST_FAILED : 
      return {...state , isLoading : false , channel : {}}


    case DELETE_CHANNEL : 
      return {...state, isLoading : true}
    
    case DELETE_CHANNEL_SUCCESS : 
      return {...state, isLoading : false}

    case DELETE_CHANNEL_FAILED : 
      return {...state, isLoading : false}
    

    case CREATE_CHANNEL : 
      return {...state , isLoading : true}
    
    case CREATE_CHANNEL_SUCCESS : 
      return {...state, isLoading : false}
    
    case CREATE_CHANNEL_FAILED : 
      return {...state, isLoading : false}


    case UPDATE_CHANNEL : 
      return {...state, isLoading : true}
    
    case UPDATE_CHANNEL_SUCCESS : 
      return {...state, isLoading : false}

    case UPDATE_CHANNEL_FAILED : 
      return {...state, isLoading : false}
    
    default:
      return state
  }
}