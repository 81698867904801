import React, { useEffect, useState, useCallback } from "react"
import {
    Grid,
    TableContainer,
    Table,
    TableRow,
    TableCell,
    TableBody,
    withStyles,
    Card,
    Box,
    CardContent,
    TextField,
    MenuItem,
    Button,
    Checkbox
} from '@material-ui/core'
import { withTranslation } from "react-i18next"
import EnhancedTableHead from '../../components/common/EnhancedTableHead'
import CustomTablePagination from '../../components/common/CustomPagination';
import InputField from "../../components/common/InputField"
import { useDispatch, useSelector } from "react-redux"
import * as action from './actions'
import 'react-dates/initialize';
import "react-dates/lib/css/_datepicker.css";
import CustomDialogBox from "../../components/common/CustomDialogBox"
import { stableSort, getComparator, tablestyle, getTimeStamps, spaceRegex, } from "../../utils"
import { dateFilter, isPhoneNumber } from '../../constant'
import editIcon from '../../assets/images/editIcon.svg';
import deleteIcon from '../../assets/images/deleteIcon.svg'
import loader from '../../assets/images/loader.gif'
import totalUserIcon from '../../assets/images/totalUserIcon.svg'
import userActiveIcon from '../../assets/images/userActiveIcon.svg'
import userInActiveIcon from '../../assets/images/userInActiveIcon.svg'
import IOSSwitch from "../../components/common/IOSSwitch";
import moment from 'moment'
import CustomSelect from '../../components/common/CustomSelect'
import UserLoader from "../../assets/images/userLoader.gif";
import CustomToolTip from "../../components/common/ToolTip";
import NoDataFound from "../../components/common/NoDataFound";
import Datepicker from "../../components/common/Datepicker";
import CustomModal from "../../components/common/CustomModal";
import AddUserForm from "./userForm";
import UserLiveForm from "./userLiveForm";
import _ from "lodash";

const headCells = [
    { id: "index", numeric: false, disablePadding: false, label: "S.No." },
    // { id: "is_active", numeric: false, disablePadding: false, label: "status" },
    { id: "user_name", numeric: false, disablePadding: false, label: "Username" },
    { id: "email", numeric: false, disablePadding: false, label: "Email" },
    { id: "is_active", numeric: false, disablePadding: false, label: "Status" },
    { id: "authorizedCredit", numeric: false, disablePadding: false, label: "Mobile No." },
    { id: "group.name", numeric: false, disablePadding: false, label: "Live on TV" },
    { id: "a", numeric: false, disablePadding: false, label: "Action" },
];

function User(props) {
    const { toast } = props
    const dispatch = useDispatch()
    const [search, setSearch] = useState('')
    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const [userId, setUserId] = useState(null)
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('')
    const [rowsPerPage, setRowsPerPage] = useState(25)
    const [currentPage, setCurrentPage] = useState(0)
    const [filter, setFilter] = useState({ label: 'All', value: 'all' })
    const [startDate, setStartDate] = useState("")
    const [endDate, setEndDate] = useState("")
    const [focusedInput, setFocusedInput] = useState(false)
    const [timeOut, setTimeOut] = useState(null)
    const [userFilterSelect, setUserFilterSelect] = useState("")
    const [addUserModal, setAddUserModal] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [userDetails, setUserDetails] = useState({})
    const [openUserLiveModal, setOpenUserLiveModal] = useState(false)
    const [isFilterSelected, setIsFilterSelected] = useState(false)
    const [removeLiveConfirmModal, setRemoveLiveConfirmModal] = useState(false)
    const [dataSelected, setDataSelected] = useState(false)


    const { userList = {}, isLoading = false } = useSelector(state => state.users) || {}

    const { data = [], total = "", current_page = "" } = userList || {}

    useEffect(() => {
        if (!startDate || !endDate) return;
        getData()
        // setIsFilterSelected(false)
    }, [startDate, endDate])

    useEffect(() => {
        if (filter?.value) {
            const { startDate: newStartDate, endDate: newEndDate } = getTimeStamps(filter.value, startDate, endDate)
            if (filter.value !== 'customDate') {
                setEndDate(moment(newEndDate))
                setStartDate(moment(newStartDate))
            }
        }
    }, [filter])

    const startDateValue = moment(startDate).startOf('day').valueOf()
    const endDateValue = moment(endDate).endOf('day').valueOf()
    const createdDate = data && data.map(item => item.created_at_ts)

    function getData(value) {
        let data = {
            startDate: startDateValue,
            endDate: endDateValue,
            page: 1,

        }
        if (dataSelected) data.type = 'created_at_ts'

        if (filter.value == 'all') data.type = ''

        dispatch(action.getUserByFilter({ ...data, limit: rowsPerPage, start: currentPage, term: search, userFilterSelect: search, key: userFilterSelect }))
    }

    function handleDateChange({ startDate, endDate }) {
        if (startDate) setStartDate(startDate)
        if (endDate) setEndDate(endDate)
    }

    const handleChange = (e, userFilterSelect) => {
        const { name = "", value = "" } = e.target;
        if (value.length && (!spaceRegex.test(value))) return;

        if (userFilterSelect === "phone" && value) {
            if (!isPhoneNumber.test(value)) return
            if (value.length > 10) return
        }
        setSearch(value)
        searchDebounce(value, userFilterSelect, rowsPerPage, dataSelected, filter, startDateValue, endDateValue);
        props.history.replace(`/user?page=${0}&limit=${5}`)
    }

    const searchDebounce = useCallback(
        _.debounce((value, userFilterSelect, rowsPerPage, dataSelected, filter, startDateValue, endDateValue) => {
            if (value.length != 0) {
                console.log(startDateValue, endDateValue)
                let type
                if (value && value.trim())
                    if (dataSelected) type = 'created_at_ts'
                if (filter.value == 'all') type = ''
                if (filter.value == 'all') startDateValue = null
                if (filter.value == 'all') endDateValue = null


                dispatch(action.getUserByFilter({ limit: rowsPerPage, start: currentPage, userFilterSelect: value, key: userFilterSelect, startDate: startDateValue, endDate: endDateValue, term: value, type: type }));
                setCurrentPage(0)
            } else {
                dispatch(action.getUserByFilter({ limit: rowsPerPage }))
            }
        }, 700),
        []
    );

    const handleItemType = ({ target: { value } }) => {
        setUserFilterSelect(value)
        setSearch('')
        if (search !== '') (
            dispatch(action.getUserList({ limit: rowsPerPage }))
        )
    }


    const deleteModal = (id) => {
        setUserId(id)
        setOpenDeleteModal(true)
    }

    const onUpdateStatus = (e, user) => {
        e.preventDefault();
        update(user)
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc')
        setOrderBy(property)
    }

    const handleChangePage = (event, currentPage, pageLimit) => {


        setCurrentPage(currentPage)
        if (filter.value == 'all') {
            dispatch(action.getUserByFilter({ limit: rowsPerPage, page: currentPage + 1, type: userFilterSelect, term: search, startDate: startDateValue, endDate: endDateValue, userFilterSelect: search, key: userFilterSelect }))
        } else {
            dispatch(action.getUserByFilter({ limit: rowsPerPage, type: userFilterSelect, page: currentPage + 1, term: search, startDate: startDateValue, endDate: endDateValue, type: "created_at_ts", userFilterSelect: search, key: userFilterSelect }))
        }
        // isFilterSelected || !filter.value == 'all' ? dispatch(action.getUserByFilter({ limit: rowsPerPage, type: userFilterSelect, page: currentPage + 1, term: search, startDate: startDateValue, endDate: endDateValue, type: "created_at_ts", userFilterSelect: search, key: userFilterSelect }))
        //     :
        //     dispatch(action.getUserByFilter({ limit: rowsPerPage, page: currentPage + 1, type: userFilterSelect, term: search, startDate: startDateValue, endDate: endDateValue, userFilterSelect: search, key: userFilterSelect }))
        props.history.replace(`/user?page=${currentPage}&limit=${rowsPerPage}`)
    }

    const handleChangeRowsPerPage = (rowsPerPage) => {
        let { value = 10 } = rowsPerPage.target;
        value = value === "All" ? props.customer.length : value
        setRowsPerPage(value)
        setCurrentPage(0)
        isFilterSelected || !filter.value == 'all' ? dispatch(action.getUserByFilter({ limit: value, type: userFilterSelect, start: currentPage, term: search, startDate: startDateValue, endDate: endDateValue, type: "created_at_ts", userFilterSelect: search, key: userFilterSelect }))
            :
            dispatch(action.getUserByFilter({ limit: value, type: userFilterSelect, start: currentPage, term: search, startDate: startDateValue, endDate: endDateValue, userFilterSelect: search, key: userFilterSelect }))
        props.history.replace(`/user?page=${currentPage}&limit=${value}`)
    }

    const update = (user) => {
        let is_active = user?.is_active && JSON.parse(user?.is_active)
        dispatch(action.UpdateActiveUser({ ...user, is_active: (!JSON.parse(user.is_active)).toString() }))
            .then(() => {
                dispatch(action.getUserList({ limit: rowsPerPage, start: currentPage, startDate: startDateValue, endDate: endDateValue }))
                toast.success(`User has been ${is_active ? 'deactivated' : 'activated'} successfully!`)
                afterAction()
            })
            .catch(({ message = '' }) => {
                toast.error(message || 'Oops! Something went wrong')
            })
    }

    const deleteUser = () => {
        dispatch(action.DeleteUser(userId))
            .then(res => {
                dispatch(action.getUserList({ limit: rowsPerPage, start: currentPage, startDate: startDateValue, endDate: endDateValue }))
                toast.success("User has been deleted successfully")
                setOpenDeleteModal(false)
                afterAction()
            })
            .catch(({ message = '' }) => {
                toast.error(message || 'Oops! Something went wrong')
            })
    }

    const handleCloseAddUserModal = () => {
        setAddUserModal(false)
        setIsEdit(false)
        setUserDetails({})
    }

    const handleEditUser = (users) => {
        setUserDetails(users)
        setAddUserModal(true)
        setIsEdit(true)
    }

    const handleAddModal = () => {
        setAddUserModal(true)
    }

    const afterAction = () => {
        setSearch('')
        setUserFilterSelect('')
        setUserId(null)
    }

    const usersFilter = [
        { id: 1, value: 'user_name', label: 'Username' },
        { id: 3, value: 'email', label: 'Email' },
        { id: 4, value: 'phone', label: 'Mobile Number' },
    ]

    const handleCloseUserLiveModal = () => {
        setOpenUserLiveModal(false)
    }

    const handleUserLive = (item) => {
        const { is_live } = item
        console.log("Item is----- ", item)
        if (is_live === "true") {
            setRemoveLiveConfirmModal(true)
            setUserDetails(item)
        } else {
            setOpenUserLiveModal(true)
            setUserDetails(item)
        }
    }

    const removeUserLive = () => {
        const { id = "", is_live = false } = userDetails
        const removeLive = {
            remove_live: 'true',
            users_id: id,
            // episode_id: '',
            // channel_id: ''
        }
        dispatch(action.UserLiveUpdate(removeLive))
            .then(res => {
                props.toast.success('User Updated Successfully!')
                dispatch(action.getUserByFilter({ limit: rowsPerPage, start: currentPage }));
                setRemoveLiveConfirmModal(false)
                setSearch('')
            })
            .catch(({ message = '' }) => {
                props.toast.error(message || 'Something went wrong!')
            })
    }

    let labelData = usersFilter.find(item => item.value === userFilterSelect);

    return (
        <div className="user-page">
            <Grid container spacing={3} className="mb-3 heading-sec" >
                <Grid item xs={12} sm={12} md={12} lg={1} className="align-self-center">
                    <h5 className="page-heading" >Users</h5>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={11} className="custom-date-field d-flex align-items-center justify-content-end">
                    <Box className="cust-formfields">
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={7} lg={7} >
                                {
                                    userFilterSelect ?
                                        <InputField
                                            type="search"
                                            value={search}
                                            name={search}
                                            label={`Search By ${labelData && labelData.label || ''}`}
                                            placeholder=""
                                            onChange={(e) => handleChange(e, userFilterSelect)}
                                            fullWidth
                                        />
                                        :
                                        ''
                                }
                            </Grid>
                            <Grid item xs={12} sm={12} md={5} lg={5} >
                                <TextField
                                    id="outlined-select-currency"
                                    select
                                    fullWidth
                                    label="Search By"
                                    variant="outlined"
                                    onChange={handleItemType}
                                    value={userFilterSelect}
                                    className="form-group"
                                >
                                    {
                                        usersFilter && usersFilter.map(customer => {
                                            return <MenuItem key={customer.id} value={customer.value}>{customer.label} </MenuItem>
                                        })
                                    }

                                </TextField>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box className="custom-box" display="flex" justifyContent="flex-end" alignItems="center">
                        <CustomSelect
                            className="cust-select"
                            minWidth={180}
                            options={dateFilter}
                            placeholder="Filter"
                            isSearchable
                            onChange={(opt) => {

                                setFilter(opt)
                                setDataSelected(true)
                                setCurrentPage(0)
                                setIsFilterSelected(true)
                            }}
                            value={filter}
                        />
                        {
                            filter?.value !== "customDate" ?
                                ''
                                :
                                <div className="date-range-picker">
                                    <Datepicker
                                        startDate={startDate}
                                        endDate={endDate}
                                        focusedInput={focusedInput}
                                        setFocusedInput={setFocusedInput}
                                        handleDateChange={handleDateChange}
                                    />
                                </div>
                        }
                    </Box>
                    <Button onClick={handleAddModal} className="button-btn cat-button new-btn-color" disabled={isLoading} > Add User</Button>
                </Grid>
            </Grid>

            <Grid container className="user-grid" >
                <UserCardField img={totalUserIcon}>
                    <h5>Total Entries</h5>
                    <h6>{isLoading ? <img src={UserLoader} alt="" className="user-loader-img" /> : total || '0'}</h6>
                </UserCardField>

                <UserCardField img={userActiveIcon}>
                    <h5>Active</h5>
                    <h6>{isLoading ? <img src={UserLoader} alt="" className="user-loader-img" /> : (Array.isArray(data) ? data.filter(k => k && JSON.parse(k?.is_active))?.length : '')} </h6>
                </UserCardField>

                <UserCardField img={userInActiveIcon}>
                    <h5>Inactive</h5>
                    <h6>{isLoading ? <img src={UserLoader} alt="" className="user-loader-img" /> : (Array.isArray(data) ? data.filter(k => k && !JSON.parse(k?.is_active))?.length : '')} </h6>
                </UserCardField>

                <UserCardField img={totalUserIcon}>
                    <h5>Live Users</h5>
                    <h6>{isLoading ? <img src={UserLoader} alt="" className="user-loader-img" /> : (Array.isArray(data) ? data.filter(k => k && k?.is_live && JSON.parse(k?.is_live))?.length : '')} </h6>
                </UserCardField>

            </Grid>
            <div className="cust-table">
                {!isLoading ?
                    <div>
                        <TableContainer className={`${props.classes.container} mt-2`}>
                            <Table className="table-program" stickyHeader aria-label="sticky table" id="customer-table">
                                <EnhancedTableHead
                                    order={order}
                                    orderBy={orderBy}
                                    onRequestSort={handleRequestSort}
                                    headCells={headCells}
                                />
                                <TableBody>
                                    {data && data.length ?
                                        stableSort(data || [], getComparator(order, orderBy)).map((item, index) => {
                                            const { is_live = "", user_name = "", email = "", phone = "", id = "", is_active = "" } = item || {}
                                            return (
                                                <TableRow hover key={id} className="cursor_default"  >
                                                    <TableCell className="table-custom-width" data-title="S NO.">{rowsPerPage * ((currentPage + 1) - 1) + index + 1}. </TableCell>
                                                    {/* <TableCell className="table-custom-width" data-title="USER NAME"><IOSSwitch onChange={(e) => onUpdateStatus(e, item)} checked={is_active && JSON.parse(is_active)} /></TableCell> */}
                                                    <TableCell className="table-custom-width" data-title="USER NAME">{user_name || '-'} </TableCell>
                                                    <TableCell className="table-custom-width" data-title="EMAIL">{email || '-'} </TableCell>
                                                    <TableCell className="table-custom-width" data-title="STATUS"><span className={`${JSON.parse(is_active) ? 'user-active' : 'user-inactive'}`}> {JSON.parse(is_active) ? 'Active' : 'Inactive'}</span> </TableCell>
                                                    <TableCell className="table-custom-width" data-title="MOBILE NO."> {phone || '-'}  </TableCell>
                                                    <TableCell className="table-custom-width" data-title="ADDRESS">{true ? <Checkbox checked={is_live && JSON.parse(is_live)} onClick={() => handleUserLive(item)} /> : '-'}  </TableCell>
                                                    <TableCell className="table-custom-width" data-title="ACTION">
                                                        <CustomToolTip title="Edit" >
                                                            <span className="edit-icon mr-2" onClick={() => handleEditUser(item)} >
                                                                <img src={editIcon} alt="" />
                                                            </span>
                                                        </CustomToolTip>
                                                        <CustomToolTip title="Delete" >
                                                            <span className="delete-icon" onClick={() => deleteModal(id)} >
                                                                <img src={deleteIcon} alt="" />
                                                            </span>
                                                        </CustomToolTip>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })
                                        :
                                        <TableRow>
                                            <TableCell colSpan={headCells.length + 1} className="text-center"><NoDataFound /></TableCell>
                                        </TableRow>
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <CustomTablePagination
                            count={total || 0}
                            rowsPerPage={rowsPerPage || 0}
                            currentPage={currentPage}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    </div>
                    :
                    <div className="table-loader">
                        <img src={loader} alt="" />
                    </div>
                }
            </div>

            <CustomModal open={addUserModal} maxWidth="xl">
                <AddUserForm
                    {...props}
                    onClose={handleCloseAddUserModal}
                    toast={toast}
                    rowsPerPage={rowsPerPage}
                    userDetails={userDetails}
                    update={isEdit}
                    afterAction={afterAction}
                />
            </CustomModal>

            <CustomModal open={openUserLiveModal} maxWidth="xl">
                <UserLiveForm
                    {...props}
                    onClose={handleCloseUserLiveModal}
                    toast={toast}
                    rowsPerPage={rowsPerPage}
                    search={search}
                    setSearch={setSearch}
                    userDetails={userDetails}
                    update={isEdit}
                    afterAction={afterAction}
                />
            </CustomModal>

            <CustomDialogBox
                handleClose={() => setOpenDeleteModal(false)}
                confirmAction={deleteUser}
                open={openDeleteModal}
                title="Warning"
                dialogtext={`Are you sure you want to delete this user?`}
                isLoading={isLoading}
                text="Keep User"
            />

            <CustomDialogBox
                handleClose={() => setRemoveLiveConfirmModal(false)}
                confirmAction={removeUserLive}
                open={removeLiveConfirmModal}
                title="Warning"
                dialogtext={`Are you sure you want to remove user from live?`}
                isLoading={isLoading}
                text="Keep User Live"
                removeLive="Remove Live"
            />

        </div >
    )
}

export default withTranslation("translations")(withStyles(tablestyle)(User));

function UserCardField(props) {
    return <Grid item xs={12} sm={3} md={3} lg={3}>
        <Card className="user-cards" >
            <CardContent>
                <Grid container>
                    <Grid item xs={4} sm={4} md={4} lg={4}>
                        <div className="user-card-img">
                            <img src={props.img} className="user-card-img" alt="" />
                        </div>
                    </Grid>
                    <Grid item xs={8} sm={8} md={8} lg={8}>
                        <div className="user-card-connent" >
                            {props?.children}
                        </div>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    </Grid>
}