import React, { Component } from "react"
import { BrowserRouter, Switch, Route } from "react-router-dom"
import PublicRoute from './PublicRoute'
import PrivateRoute from './PrivateRoute'

import Login from '../containers/Auth/Login'
import NotFound from '../containers/NotFound'
import Dashboard from "../containers/Dashboard"
import User from "../containers/User"
import Settings from "../containers/Settings"
import Categories from '../containers/Categories'
import SingleCategory from "../containers/Categories/CategoryQuestion"
import Gifts from '../containers/Gifts'
import SingleGift from '../containers/Gifts/GiftsQuestions'
import Judiciary from "../containers/Judiciary"
import Winner from "../containers/Table-of-Winners"
import TopWinners from "../containers/Top-12-Winners"
import WinnerList from "../containers/Top-12-Winners"
import QuestionList from '../containers/QuestionsWinner'
import Episode from "../containers/Episode"
import JuryCategories from "../containers/JuryCategory"
import SingleJuryCategory from "../containers/JuryCategory/CategoryQuestion"
import Channel from "../containers/Channel"
import Host from "../containers/Host"


class Routes extends Component {
  state = {
    severity: 'success'
  }
  idleTimer = null

  notification = (message, severity) => {
    this.setState({ severity }, () => {
      this.not.open(message)
    })
  }

  render() {
    return (
      <React.Fragment>
        <BrowserRouter>
          <Switch>
            <PublicRoute exact path="/" component={Login} notification={this.notification} />
            <PublicRoute exact path="/login" component={Login} notification={this.notification} />


            <PrivateRoute exact path="/user" component={User} notification={this.notification} />
            <PrivateRoute exact path="/categories" component={Categories} notification={this.notification} />
            <PrivateRoute exact path="/categories/:categoryId" component={SingleCategory} notification={this.notification} />

            <PrivateRoute exact path="/gifts" component={Gifts} notification={this.notification} />
            <PrivateRoute exact path="/gifts/:giftId" component={SingleGift} notification={this.notification} />

            <PrivateRoute exact path="/jury-categories" component={JuryCategories} notification={this.notification} />
            <PrivateRoute exact path="/jury-categories/:categoryId" component={SingleJuryCategory} notification={this.notification} />

            <PrivateRoute exact path="/judiciaries" component={Judiciary} notification={this.notification} />

            <PrivateRoute exact path="/channel" component={Channel} notification={this.notification} />

            <PrivateRoute exact path="/winners" component={Winner} notification={this.notification} />

            <PrivateRoute exact path="/top-winner" component={WinnerList} notification={this.notification} />
            <PrivateRoute exact path="/question-winner" component={QuestionList} notification={this.notification} />
            {/* <PrivateRoute exact path="/top-winner/list" component={WinnerList} notification={this.notification} /> */}

            <PrivateRoute exact path="/episode" component={Episode} notification={this.notification} />

            <PrivateRoute exact path="/host" component={Host} notification={this.notification} />

            {/* <PrivateRoute exact path="/dashboard" component={Dashboard} notification={this.notification} /> */}
            <PrivateRoute exact path="/settings" component={Settings} notification={this.notification} />

            <Route component={NotFound} />
          </Switch>
        </BrowserRouter>
      </React.Fragment>

    );
  }
}

export default Routes;
