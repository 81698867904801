import { apiPost, apiGet, apiDelete, apiPut } from '../../utils/axios'


export function getCategoriesAPI() {
  return apiGet('/categories?limit=12')
}

export function deleteCategoryAPI(data) {
  return apiDelete(`/categories/${data}`)
}

export function getCategoryByIdAPI(data) {
  return apiGet(`/categories/${data}`)
}

export function createCategoryAPI(data) {
  return apiPost('/categories', data)
}

export function updateCategoryAPI(data, id) {
  return apiPut(`/categories/${id}`, data)
}

export function searchCategoryByFilterAPI(data) {
  let payload = {
    term: data && data.term || '',
    type: data && data.type || ''
  }
  return apiGet(`/categories`, payload)
}