import {
  
GET_JURY_CATEGORIES,
GET_JURY_CATEGORIES_SUCCESS,
GET_JURY_CATEGORIES_FAILED,

DELETE_JURY_CATEGORIES,
DELETE_JURY_CATEGORIES_SUCCESS, 
DELETE_JURY_CATEGORIES_FAILED,

GET_JURY_CATEGORIES_BY_ID ,
GET_JURY_CATEGORIES_BY_ID_SUCCESS,
GET_JURY_CATEGORIES_BY_ID_FAILED,

CREATE_JURY_CATEGORIES,
CREATE_JURY_CATEGORIES_SUCCESS, 
CREATE_JURY_CATEGORIES_FAILED,

EDIT_JURY_CATEGORIES,
EDIT_JURY_CATEGORIES_SUCCESS,
EDIT_JURY_CATEGORIES_FAILED,

} from './actions'

const initState = {
  isLoading: false,
  juryCategory : {},
  juryCategoryById : {}
}

export default function (state = { ...initState }, action) {
  switch (action.type) {
    case GET_JURY_CATEGORIES : 
      return {...state , isLoading : true}
    
    case GET_JURY_CATEGORIES_SUCCESS : 
      return {...state , isLoading : false , juryCategory : action.payload || {} }

    case GET_JURY_CATEGORIES_FAILED : 
      return {...state , isLoading : false , juryCategory : {}}


    case DELETE_JURY_CATEGORIES : 
      return {...state, isLoading : true}
    
    case DELETE_JURY_CATEGORIES_SUCCESS : 
      return {...state, isLoading : false}

    case DELETE_JURY_CATEGORIES_FAILED : 
      return {...state, isLoading : false}
    

    case GET_JURY_CATEGORIES_BY_ID : 
      return {...state , isLoading : true}

    case GET_JURY_CATEGORIES_BY_ID_SUCCESS : 
      return {...state, isLoading : false , juryCategoryById : action.payload || {} }
    
    case GET_JURY_CATEGORIES_BY_ID_FAILED : 
      return {...state, isLoading : false, juryCategoryById : {} }


    case CREATE_JURY_CATEGORIES : 
      return {...state , isLoading : true}
    
    case CREATE_JURY_CATEGORIES_SUCCESS : 
      return {...state, isLoading : false}
    
    case CREATE_JURY_CATEGORIES_FAILED : 
      return {...state, isLoading : false}


    case EDIT_JURY_CATEGORIES : 
      return {...state, isLoading : true}
    
    case EDIT_JURY_CATEGORIES_SUCCESS : 
      return {...state, isLoading : false}

    case EDIT_JURY_CATEGORIES_FAILED : 
      return {...state, isLoading : false}
    
    default:
      return state
  }
}