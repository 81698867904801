import {

  GET_GIFT_CATEGORIES,
  GET_GIFT_CATEGORIES_SUCCESS,
  GET_GIFT_CATEGORIES_FAILED,

  DELETE_GIFT_CATEGORY,
  DELETE_GIFT_CATEGORY_SUCCESS,
  DELETE_GIFT_CATEGORY_FAILED,

  GET_GIFT_CATEGORY_BY_ID,
  GET_GIFT_CATEGORY_BY_ID_SUCCESS,
  GET_GIFT_CATEGORY_BY_ID_FAILED,

  CREATE_GIFT_CATEGORY,
  CREATE_GIFT_CATEGORY_SUCCESS,
  CREATE_GIFT_CATEGORY_FAILED,

  EDIT_GIFT_CATEGORY,
  EDIT_GIFT_CATEGORY_SUCCESS,
  EDIT_GIFT_CATEGORY_FAILED,

} from './actions'

const initState = {
  isLoading: false,
  giftCategories: {},
  categoryById: {}
}

export default function (state = { ...initState }, action) {
  switch (action.type) {
    case GET_GIFT_CATEGORIES:
      return { ...state, isLoading: true }

    case GET_GIFT_CATEGORIES_SUCCESS:
      return { ...state, isLoading: false, giftCategories: action.payload || {} }

    case GET_GIFT_CATEGORIES_FAILED:
      return { ...state, isLoading: false, giftCategories: {} }


    case DELETE_GIFT_CATEGORY:
      return { ...state, isLoading: true }

    case DELETE_GIFT_CATEGORY_SUCCESS:
      return { ...state, isLoading: false }

    case DELETE_GIFT_CATEGORY_FAILED:
      return { ...state, isLoading: false }


    case GET_GIFT_CATEGORY_BY_ID:
      return { ...state, isLoading: true }

    case GET_GIFT_CATEGORY_BY_ID_SUCCESS:
      return { ...state, isLoading: false, categoryById: action.payload || {} }

    case GET_GIFT_CATEGORY_BY_ID_FAILED:
      return { ...state, isLoading: false, categoryById: {} }


    case CREATE_GIFT_CATEGORY:
      return { ...state, isLoading: true }

    case CREATE_GIFT_CATEGORY_SUCCESS:
      return { ...state, isLoading: false }

    case CREATE_GIFT_CATEGORY_FAILED:
      return { ...state, isLoading: false }


    case EDIT_GIFT_CATEGORY:
      return { ...state, isLoading: true }

    case EDIT_GIFT_CATEGORY_SUCCESS:
      return { ...state, isLoading: false }

    case EDIT_GIFT_CATEGORY_FAILED:
      return { ...state, isLoading: false }

    default:
      return state
  }
}