import {
    getEpisodeAPI,
    createEpisodeAPI,
    editEpisodeAPI,
    deleteEpisodeAPI,
    searchEpisodeAPI,
} from './apis'


export function getEpisode(data) {
    return dispatch => {
        dispatch({
            type: GET_EPISODE_REQUEST
        })
        return new Promise((resolve, rej) => {
            getEpisodeAPI(data).then(res => {
                dispatch({
                    type: GET_EPISODE_REQUEST_SUCCEEDED,
                    payload: res
                })
                return resolve(res)
            })
                .catch(err => {
                    dispatch({
                        type: GET_EPISODE_REQUEST_FAILED,
                        payload: {}
                    })
                    return rej(err)
                })
        })
    }
}

export function searchEpisodeByFilter(data) {
    return dispatch => {
        dispatch({
            type: GET_EPISODE_REQUEST,
        })
        return new Promise((resolve, rej) => {
            searchEpisodeAPI(data).then(res => {
                dispatch({
                    type: GET_EPISODE_REQUEST_SUCCEEDED,
                    payload: res
                })
                return resolve(res)
            })
                .catch(err => {
                    dispatch({
                        type: GET_EPISODE_REQUEST_FAILED,
                        payload: {},
                    })
                    return rej(err)
                })
        })
    }
}

export const GET_EPISODE_REQUEST = 'GET_EPISODE_REQUEST'
export const GET_EPISODE_REQUEST_SUCCEEDED = 'GET_EPISODE_REQUEST_SUCCEEDED'
export const GET_EPISODE_REQUEST_FAILED = 'GET_EPISODE_REQUEST_FAILED'


export function deleteEpisode(data) {
    return dispatch => {
        dispatch({
            type: DELETE_EPISODE
        })
        return new Promise((resolve, rej) => {
            deleteEpisodeAPI(data).then(res => {
                dispatch({
                    type: DELETE_EPISODE_SUCCESS,
                })
                return resolve(res)
            })
                .catch(err => {
                    dispatch({
                        type: DELETE_EPISODE_FAILED,
                    })
                    return rej(err)
                })
        })
    }
}

export const DELETE_EPISODE = "DELETE_EPISODE";
export const DELETE_EPISODE_SUCCESS = "DELETE_EPISODE_SUCCESS";
export const DELETE_EPISODE_FAILED = "DELETE_EPISODE_FAILED"

export function updateEpisode(data, id) {
    return dispatch => {
        dispatch({
            type: 'UPDATE_EPISODE'
        })
        return new Promise((resolve, rej) => {
            editEpisodeAPI(data, id).then(res => {
                dispatch({
                    type: 'UPDATE_EPISODE_SUCCESS'
                })
                return resolve(res)
            })
                .catch(err => {
                    dispatch({
                        type: 'UPDATE_EPISODE_FAILED'
                    })
                    return rej(err)
                })
        })
    }
}

export const UPDATE_EPISODE = "UPDATE_EPISODE"
export const UPDATE_EPISODE_SUCCESS = "UPDATE_EPISODE_SUCCESS"
export const UPDATE_EPISODE_FAILED = "UPDATE_EPISODE_FAILED"

export function createEpisode(data) {
    return dispatch => {
        dispatch({
            type: 'CREATE_EPISODE'
        })
        return new Promise((resolve, rej) => {
            createEpisodeAPI(data).then(res => {
                dispatch({
                    type: 'CREATE_EPISODE_SUCCESS'
                })
                return resolve(res)
            })
                .catch(err => {
                    dispatch({
                        type: 'CREATE_EPISODE_FAILED'
                    })
                    return rej(err)
                })
        })
    }
}

export const CREATE_EPISODE = 'CREATE_EPISODE'
export const CREATE_EPISODE_SUCCESS = "CREATE_EPISODE_SUCCESS"
export const CREATE_EPISODE_FAILED = "CREATE_EPISODE_FAILED"
