import React, { useEffect } from 'react'
import {  getObject, formatString } from "../../utils"
import userImage from '../../assets/images/userImage.svg'
import * as settingAction from '../../containers/Settings/actions'
import { useDispatch, useSelector } from 'react-redux'

function UserInfo() {
     const dispatch = useDispatch()
     const {admin = {}, isLoading = false} = useSelector(state => state?.admin ) || {}

    const { image = '', contact_no = '', username = '', email = '', id = "", image_key = "" } = admin || {}

    
    useEffect(() => {
        dispatch(settingAction.GetAdminDetails())
    },[])
    
    // let object = getObject("top-challange-admin");
    // let { profileImage = '' , userName = "" } = object && JSON.parse(object) || '';

    return(
        <div className="user-info-header" >
            <span className={image.length ? 'user-img mr-2' : 'user-img-default mr-2'}>
                <img src={image.length ? image : userImage }  alt="" />
            </span> 
            <span className="user-name" > {username || ''} </span>
        </div>
    )
}

export default UserInfo