import React, { useState, useEffect } from "react";
import {
  Button,
  CircularProgress,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import * as action from "./actions";
import * as channelAction from "../Top-12-Winners/action";
import userLiveValidation from "./userLiveValidation";
import TextField from "@material-ui/core/TextField";

function UserLiveForm(props) {
  const dispatch = useDispatch();

  const [errors, setError] = useState({});
  const [channel, setChannel] = useState("");
  const { isLoading: UserLiveLoading = false } =
    useSelector((state) => state.users) || {};
  const { isLoading = false } = useSelector((state) => state.tableWinner);
  const {
    channels = {},
    episode = {},
    isLoading: Loading = false,
  } = useSelector((state) => state.topWinner) || {};
  const { data: channelList = [], total } = channels || {};
  const { episodeList = {} } = episode;
  const { Items: allEpisodes = [] } = episodeList || {};
  const {
    userDetails: { channel_id, episode_id, episodeData, is_live, email },
  } = props;
  const [isLive, setIsLive] = useState(is_live);
  const [episodes, setEpisode] = useState(
    episodeData.length && episodeData[0]?.episode
  );
  const [selectedChannelId, setSelectedChannelId] = useState(channel_id);
  const [selectedEpisodeId, setSelectedEpisodeId] = useState(episode_id);

  const [isUpdateDisabled, setIsUpdateDisabled] = useState(true);

  useEffect(() => {
    setIsUpdateDisabled(!(selectedEpisodeId && selectedChannelId));
  }, [selectedEpisodeId, selectedChannelId]);


  useEffect(() => {
    dispatch(channelAction.GetChannels());
  }, []);

  const handleCheckboxChange = (e) => {
    const { checked } = e.target;
    setIsLive(checked);
  };


  useEffect(() => {
    if (channels?.data?.length) {
      let channelName =
        channels?.data?.find((channel) => channel.id == channel_id)?.title ||
        "";
      setChannel(channelName);
      channelName && dispatch(channelAction.GetEpisodeByChannel(channelName));
    }
  }, [channels]);

  const handleEpisodeChange = (e) => {
    const { name, value } = e.target;
    setEpisode(value);
    setError({ ...errors, episodes: "" });
  };

  //   const isValid = () => {
  //     const { isValid = true, errors = {} } = userLiveValidation({
  //       selectedChannelId,
  //       selectedEpisodeId,
  //     });
  //     setError(errors);
  //     return isValid;
  //   };

  const isValid = () => {
    let isValid = true;
    const errors = {};

    if (!selectedChannelId) {
      errors.channel = "Channel is required";
      isValid = false;
    }

    if (!selectedEpisodeId) {
      errors.episode = "Episode is required";
      isValid = false;
    }

    setError(errors);
    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { userDetails, rowsPerPage, setSearch } = props;
    const { id = "", is_live = false } = userDetails;
    if (isValid()) {
      const formData = {
        is_live:  isLive.toString() || (!JSON.parse(is_live)).toString(),
        // is_live: isLive.toString(),
        users_id: id,
        episode_id: selectedEpisodeId,
        channel_id: selectedChannelId,
      };
      dispatch(action.UserLiveUpdate(formData))
        .then((res) => {
          props.toast.success("User Updated Successfully!");
          props.onClose();
          setSearch("");
          dispatch(action.getUserByFilter({ limit: rowsPerPage }));
        })
        .catch((err = {}) => {
          props.toast.error(
            (err && err.message && err.message) || "Something went wrong!"
          );
          // setSelectedChannelId('')
          // setSelectedEpisodeId('')
        });
    }
  };

  const handleChannelChange = (e) => {
    const { name, value } = e.target;
    setChannel(value);
    dispatch(channelAction.GetEpisodeByChannel(value));
    setError({ ...errors, channel: "" });
    setEpisode("");
  };

  return (
    <React.Fragment>
      <div className="cat-popup winner-modal user-live-data">
        <h6 className="page-heading">User Live Data</h6>
        <form onSubmit={handleSubmit}>
            <div className="w-full">
                <TextField
                    disabled
                    id="email"
                    label="Email"
                    defaultValue={email}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                />
            </div>
          <FormControl
            variant="outlined"
            className="channel-dropdown mt-2"
            fullWidth
            disabled={Loading}
          >
            <InputLabel id="channel-name">Channel Name</InputLabel>
            {Loading ? (
              <div className="field-loader">
                {" "}
                <CircularProgress color="primary" size={25} />{" "}
              </div>
            ) : (
              ""
            )}
            <Select
              labelId="channel-name"
              id="channel-name"
              value={channel}
              onChange={(e) => handleChannelChange(e)}
              label="Channel Name"
              name="channel"
              error={errors.channel}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {channelList.map((channel) => {
                return (
                  <MenuItem
                    value={channel?.title}
                    onClick={() => setSelectedChannelId(channel.id)}
                  >
                    {channel?.title}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <span className="cus-error">{errors?.channel}</span>

          <div className="winner-form d-flex align-items-center mt-3">
            <FormControl
              variant="outlined"
              className="channel-dropdown"
              fullWidth
              disabled={Loading}
            >
              <InputLabel id="episode-name">Episode</InputLabel>
              {Loading ? (
                <div className="field-loader">
                  {" "}
                  <CircularProgress color="primary" size={25} />{" "}
                </div>
              ) : (
                ""
              )}
              <Select
                labelId="episode-name"
                id="episode-name"
                value={episodes}
                onChange={(e) => handleEpisodeChange(e)}
                label="Episode"
                name="episode"
                placeholder="Please enter episode name"
                error={errors.episode}
                disabled={!channel.length}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {allEpisodes &&
                  allEpisodes.map((episode) => {
                    return (
                      <MenuItem
                        value={episode?.episode}
                        onClick={() => setSelectedEpisodeId(episode.id)}
                      >
                        {episode?.episode}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </div>
          <span className="cus-error">{errors?.episode}</span>

          <div className="d-flex align-items-center mt-2">
            <FormControlLabel
              control={
                <Checkbox checked={isLive} onChange={handleCheckboxChange} />
              }
              label="Live On TV"
            />
          </div>
        </form>
        <div className="cust-buttons mt-4">
          <Button
            onClick={() => props.onClose()}
            className="button-btn new-btn-color mr-2"
          >
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            className="button-btn secondary-btn ml-2"
            disabled={UserLiveLoading}
          >
            {UserLiveLoading ? (
              <CircularProgress size="28px" color="white" />
            ) : (
              "Update"
            )}
          </Button>
        </div>
      </div>
    </React.Fragment>
  );
}

export default UserLiveForm;
