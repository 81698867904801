import {
  getJuryCategoriesAPI,
  deleteJuryCategoryAPI,
  createJuryCategoryAPI,
  updateJuryCategoryAPI,
  getJuryCategoryByIdAPI,
  searchJuryCategoryByFilterAPI
} from './apis'


export function GetCategories(data) {
  return dispatch => {
    dispatch({
      type: 'GET_JURY_CATEGORIES'
    })
    return new Promise((resolve, rej) => {
      getJuryCategoriesAPI(data).then(res => {
        dispatch({
          type: 'GET_JURY_CATEGORIES_SUCCESS',
          payload: res
        })
        return resolve(res)
      })
        .catch(err => {
          dispatch({
            type: 'GET_JURY_CATEGORIES_FAILED',
            payload: {}
          })
          return rej(err)
        })
    })
  }
}

export function GetCategoriesByFilter(data) {
  return dispatch => {
    dispatch({
      type: 'GET_JURY_CATEGORIES'
    })
    return new Promise((resolve, rej) => {
      searchJuryCategoryByFilterAPI(data).then(res => {
        dispatch({
          type: 'GET_JURY_CATEGORIES_SUCCESS',
          payload: res
        })
        return resolve(res)
      })
        .catch(err => {
          dispatch({
            type: 'GET_JURY_CATEGORIES_FAILED',
            payload: {}
          })
          return rej(err)
        })
    })
  }
}

export const GET_JURY_CATEGORIES = 'GET_JURY_CATEGORIES'
export const GET_JURY_CATEGORIES_SUCCESS = 'GET_JURY_CATEGORIES_SUCCESS'
export const GET_JURY_CATEGORIES_FAILED = 'GET_JURY_CATEGORIES_FAILED'

export function DeleteCategory(data) {
  return dispatch => {
    dispatch({
      type: 'DELETE_JURY_CATEGORIES'
    })
    return new Promise((resolve, rej) => {
      deleteJuryCategoryAPI(data).then(res => {
        dispatch({
          type: 'DELETE_JURY_CATEGORIES_SUCCESS'
        })
        return resolve(res)
      })
        .catch(err => {
          dispatch({
            type: 'DELETE_JURY_CATEGORIES_FAILED'
          })
          return rej(err)
        })
    })
  }
}

export const DELETE_JURY_CATEGORIES = 'DELETE_JURY_CATEGORIES'
export const DELETE_JURY_CATEGORIES_SUCCESS = 'DELETE_JURY_CATEGORIES_SUCCESS'
export const DELETE_JURY_CATEGORIES_FAILED = 'DELETE_JURY_CATEGORIES_FAILED'

export function GetCategoryById(data) {
  return dispatch => {
    dispatch({
      type: 'GET_JURY_CATEGORIES_BY_ID'
    })
    return new Promise((resolve, rej) => {
      getJuryCategoryByIdAPI(data).then(res => {
        dispatch({
          type: 'GET_JURY_CATEGORIES_BY_ID_SUCCESS',
          payload: res
        })
        return resolve(res)
      })
        .catch(err => {
          dispatch({
            type: 'GET_JURY_CATEGORIES_BY_ID_FAILED',
            payload: {}
          })
          return rej(err)
        })
    })
  }
}

export const GET_JURY_CATEGORIES_BY_ID = "GET_JURY_CATEGORIES_BY_ID"
export const GET_JURY_CATEGORIES_BY_ID_SUCCESS = "GET_JURY_CATEGORIES_BY_ID_SUCCESS"
export const GET_JURY_CATEGORIES_BY_ID_FAILED = "GET_JURY_CATEGORIES_BY_ID_FAILED"

export function CreateCategory(data) {
  return dispatch => {
    dispatch({
      type: 'CREATE_JURY_CATEGORIES'
    })
    return new Promise((resolve, rej) => {
      createJuryCategoryAPI(data).then(res => {
        dispatch({
          type: 'CREATE_JURY_CATEGORIES_SUCCESS'
        })
        return resolve(res)
      })
        .catch(err => {
          dispatch({
            type: 'CREATE_JURY_CATEGORIES_FAILED'
          })
          return rej(err)
        })
    })
  }
}

export const CREATE_JURY_CATEGORIES = 'CREATE_JURY_CATEGORIES'
export const CREATE_JURY_CATEGORIES_SUCCESS = "CREATE_JURY_CATEGORIES_SUCCESS"
export const CREATE_JURY_CATEGORIES_FAILED = "CREATE_JURY_CATEGORIES_FAILED"

export function EditCategory(data, id) {
  return dispatch => {
    dispatch({
      type: 'EDIT_JURY_CATEGORIES'
    })
    return new Promise((resolve, rej) => {
      updateJuryCategoryAPI(data, id).then(res => {
        dispatch({
          type: 'EDIT_JURY_CATEGORIES_SUCCESS'
        })
        return resolve(res)
      })
        .catch(err => {
          dispatch({
            type: 'EDIT_JURY_CATEGORIES_FAILED'
          })
          return rej(err)
        })
    })
  }
}

export const EDIT_JURY_CATEGORIES = "EDIT_JURY_CATEGORIES"
export const EDIT_JURY_CATEGORIES_SUCCESS = "EDIT_JURY_CATEGORIES_SUCCESS"
export const EDIT_JURY_CATEGORIES_FAILED = "EDIT_JURY_CATEGORIES_FAILED"