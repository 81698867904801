import React, { useState, useEffect } from 'react'
import { Button, CircularProgress, Grid, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import * as action from './action'
import * as channelAction from '../Top-12-Winners/action'
import { useLocation } from "react-router-dom";
import userLiveValidation from './userLiveValidation'

function UserLiveForm(props) {
    const dispatch = useDispatch()

    const [episodes, setEpisode] = useState('')
    const [errors, setError] = useState({})
    const [channel, setChannel] = useState('')
    const [selectedEpisodeId, setSelectedEpisodeId] = useState('')


    // const { topWinner = {}, isLoading = false, channels = {}, episode = {} } = useSelector(state => state.topWinner) || {}

    const { isLoading = false } = useSelector(state => state.tableWinner)
    const { channels = {}, episode = {}, isLoading: Loading = false } = useSelector(state => state.topWinner) || {}
    const { data: channelList = [], total } = channels || {}
    const { episodeList = {} } = episode
    const { Items: episodesList = [] } = episodeList || {}

    const paramData = useLocation().search;

    useEffect(() => {
        const { channelName } = props
        dispatch(channelAction.GetEpisodeByChannel(channelName))
    }, [])


    const handleEpisodeChange = (e) => {
        const { name, value } = e.target
        setEpisode(value)
        setError({ ...errors, episodes: '' })
    }

    const isValid = () => {
        const { isValid = true, errors = {} } = userLiveValidation({ selectedEpisodeId })
        setError(errors)
        return isValid;
    }


    const handleSubmit = (e) => {
        e.preventDefault()

        const channel = new URLSearchParams(paramData).get('channel');
        const episode = new URLSearchParams(paramData).get('episode');
        if (isValid()) {
            const { userDetails, channelData = {} } = props
            const { id = "", is_live = false } = userDetails

            const formData = {
                is_live: (!JSON.parse(is_live)).toString(),
                id: id,
                episode_id: selectedEpisodeId,
                channel_id: channelData.id
            }
            dispatch(action.CreateTopWinners(formData))
                .then(res => {
                    props.toast.success('User Updated Successfully!')
                    props.onClose()
                    dispatch(action.GetEpisodeByChannel(channel))
                    dispatch(action.GetTopQuestions(episode))
                })
                .catch(({ err = "" }) => {
                    props.toast.error(err || 'Something went wrong!')
                    // setSelectedEpisodeId('')
                })
        }
    }

    return (
        <React.Fragment>
            <div className="cat-popup winner-modal">
                <h6 className="page-heading">User Live Data</h6>
                <form onSubmit={handleSubmit}>
                    {/* <FormControl variant="outlined" className="channel-dropdown" fullWidth disabled={Loading} >
                        <InputLabel id="channel-name">Channel Name</InputLabel>
                        {Loading ? <div className="field-loader"> <CircularProgress color="primary" size={25} /> </div> : ''}
                        <Select
                            labelId="channel-name"
                            id="channel-name"
                            value={channel}
                            onChange={(e) => handleChannelChange(e)}
                            label="Channel Name"
                            name="channel"
                            error={errors.channel}
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            {
                                channelList.map(channel => {
                                    return <MenuItem value={channel?.title} onClick={() => setSelectedChannelId(channel.id)} >{channel?.title}</MenuItem>
                                })
                            }
                        </Select>
                    </FormControl>
                    <span className="cus-error">{errors?.channel}</span> */}

                    <div className="winner-form d-flex align-items-center mt-2">
                        <FormControl variant="outlined" className="channel-dropdown" fullWidth disabled={Loading} >
                            <InputLabel id="episode-name">Episode</InputLabel>
                            {Loading ? <div className="field-loader"> <CircularProgress color="primary" size={25} /> </div> : ''}
                            <Select
                                labelId="episode-name"
                                id="episode-name"
                                value={episodes}
                                onChange={(e) => handleEpisodeChange(e)}
                                label="Episode"
                                name="episode"
                                placeholder="Please enter episode name"
                                error={errors.episode}
                            // disabled={!channel.length}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {
                                    episodesList && episodesList.map(episode => {
                                        return <MenuItem value={episode?.episode} onClick={() => setSelectedEpisodeId(episode.id)}  >{episode?.episode}</MenuItem>
                                    })
                                }
                            </Select>
                        </FormControl>
                    </div>
                    <span className="cus-error">{errors?.episode}</span>

                </form>
                <div className="cust-buttons mt-4" >
                    <Button onClick={() => props.onClose()} className="button-btn new-btn-color mr-2" >Cancel</Button>
                    <Button onClick={handleSubmit} className='button-btn secondary-btn ml-2' disabled={isLoading} >
                        {isLoading ? <CircularProgress size="28px" color="white" /> : 'Update'}
                    </Button>
                </div>
            </div>
        </React.Fragment>
    )
}

export default UserLiveForm