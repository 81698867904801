import * as EmailValidator from 'email-validator';
import { isEmpty } from "lodash";

function validateHost(data) {
    console.log(data, 'data')
    let errors = {}


    if (!/\S/.test(data.channel_id)) {
        errors.channel_id = "Channel Name is required!"
    }

    // if (!/\S/.test(data.episode_id)) {
    //     errors.episode_id = "Episode is required!"
    // }

    if (!/\S/.test(data.image)) {
        errors.image = "Image is required!"
    }


    if (!/\S/.test(data.username)) {
        errors.username = "Host Name is required!"
    }

    if (!/^[a-zA-Z\s]*$/g.test(data.username)) {
        errors.username = "Host Name Cannot have Special Character and Numbers "
    }

    if (!EmailValidator.validate(data.email)) {
        errors.email = "Please enter a valid email ID!"
    }

    if (!/\S/.test(data.email)) {
        errors.email = "Host Email ID is required!"
    }


    if (!/\S/.test(data.password) && data.isUpdate == false) {
        errors.password = "Host Password is required!"
    }

    return {
        isValid: isEmpty(errors),
        errors: errors
    };
}

export default validateHost;


