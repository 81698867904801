
import { apiPost, apiGet, apiDelete, apiPut } from '../../utils/axios'

export function GetJuryResultAPI(data) {
    let payload = {
        limit: data && data.limit || '',
        // page : data && data.page || ''
    }
    return apiGet('/jury_results', payload)
}

export function SearchJuryByFilterAPI(data) {

    let term = data && data.term || ''

    let payload = {
        limit: data && data.limit || '',
        page: data && data.page || '',
        term: data && data.term || '',
        type: data && data.type || '',
        start_date: data && data.startDate || '',
        end_date: data && data.endDate || '',
        [data.key]: term || ''

    }
    return apiGet('/jury_results', payload)
}

export function UpdateJuryResultAPI(data, id) {
    return apiPut(`/jury_results/${id}`, data)
}


export function DeleteJuryResultAPI(id) {
    return apiDelete(`/jury_results/${id}`)
}

export function CreateJuryResultAPI(data) {
    return apiPost('/jury_results', data)
}