import { combineReducers } from 'redux'

import auth from '../containers/Auth/reducer'
import users from '../containers/User/reducer'
import category from '../containers/Categories/reducer'
import giftCategory from '../containers/Gifts/reducer'
import question from '../containers/Categories/CategoryQuestion/questionReducer'
import tableWinner from '../containers/Table-of-Winners/reducer'
import juryResult from '../containers/Judiciary/reducer'
import topWinner from '../containers/Top-12-Winners/reducer'
import admin from '../containers/Settings/reducer'
import episode from '../containers/Episode/reducers'
import topQuestions from '../containers/QuestionsWinner/reducer'
import channel from '../containers/Channel/reducer'
import juryCategory from '../containers/JuryCategory/reducer'
import JuryCateQuestion from '../containers/JuryCategory/CategoryQuestion/questionReducer'
import host from '../containers/Host/reducer'

export default combineReducers({
  auth,
  users,
  category,
  giftCategory,
  question,
  tableWinner,
  juryResult,
  topWinner,
  admin,
  episode,
  topQuestions,
  channel,
  juryCategory,
  JuryCateQuestion,
  host
})