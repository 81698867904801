import React, { useEffect, useReducer, useState } from 'react'
import InputField from '../../components/common/InputField'
import { Button, CircularProgress, Grid, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core'
import CustomToolTip from '../../components/common/ToolTip'
import deleteIcon from '../../assets/images/deleteIcon.svg'
import CachedIcon from '@material-ui/icons/Cached';
import cameraIcon from '../../assets/images/camera.svg'
import CrossIcon from '../../assets/images/cross.svg'
import CrossIconWhite from '../../assets/images/cross-white.svg'
import { useDispatch, useSelector } from 'react-redux'
import * as action from './actions'
import * as channelAction from '../Top-12-Winners/action'
import moment from 'moment'
import validateChannel from '../Validation/channelValidation'
import Compress from "browser-image-compression";
import { options } from '../../constant'

const initialState = {
    channelName: '',
    imageSizeLimit: '',
    imgCheckLoad: false,
    imageSizeLimitError: '',
}

function AddChannelForm(props) {
    const dispatch = useDispatch()
    const [channelDetail, setChannelDetail] = useState({ ...initialState })
    const [channelImgBinaryData, setChannelImgBinaryData] = useState('')
    const [channelImage, setChannelImage] = useState('')
    const [errors, setError] = useState({})
    const [dataChange, setDataChange] = useState(false)
    const [removeImg, setRemoveImg] = useState(false)
    const [removeChannelImg, setRemoveChannelImg] = useState(false)


    const { isLoading = false } = useSelector(state => state.channel)
    const { channels = {}, episode = {}, isLoading: Loading = false } = useSelector(state => state.topWinner) || {}

    const { data: channelList = [], total } = channels || {}
    const { list = {} } = episode
    const { Items: episodeList = [] } = list || {}

    useEffect(() => {
        const { channelDetails = {}, update } = props
        const { image = "", title: channelName = "" } = channelDetails
        if (update) {
            setChannelImage(image)
            setChannelDetail({ ...channelDetail, channelName })
        }
    }, [])

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === "channelName" && value.length > 40) return;
        setChannelDetail({ ...channelDetail, [name]: value })
        setError({ ...errors, episode: '' })
        setDataChange(true)
    }

    const isValid = () => {
        const { isValid = true, errors = {} } = validateChannel({ ...channelDetail, channelImage })
        setError(errors)
        return isValid
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        const { update, channelDetails, toast, startDate, endDate, rowsPerPage, filter, setFilter } = props
        if (isValid()) {


            if (update) {
                const { id, image_key = "" } = channelDetails
                const fd = new FormData()

                fd.append("title", channelDetail.channelName.trim());
                channelImgBinaryData && fd.append('image', channelImgBinaryData)
                // removeImg && fd.append("remove_image", removeImg ? image_key : '');
                fd.append("remove_image", removeImg ? image_key : '');

                if (dataChange) {
                    dispatch(action.UpdateChannel(fd, id))
                        .then(({ res = "" }) => {
                            dispatch(action.SearchChannelByFilter({ limit: rowsPerPage, startDate: moment(startDate).startOf('day').valueOf(), endDate: moment(endDate).endOf('day').valueOf() }))
                            toast.success(res || "Channel updated successfully");
                            props.onClose()
                            setDataChange(false)
                            setRemoveImg(false)
                            props.afterAction()
                        })
                        .catch(({ message = '' }) => {
                            toast.error(message || 'Channel already exists.')
                        })
                    return
                } else {
                    setDataChange(false)
                    setRemoveImg(false)
                    props.onClose()
                    props.afterAction()
                    return
                }
            }

            const fd = new FormData()

            fd.append("title", channelDetail.channelName.trim());
            channelImgBinaryData && fd.append('image', channelImgBinaryData)


            dispatch(action.CreateChannel(fd))
                .then(({ res = "" }) => {
                    console.log(rowsPerPage, 'rrrrrrrrrrrrrrrrrrrr')
                    dispatch(action.SearchChannelByFilter({ limit: rowsPerPage, startDate: moment(startDate).startOf('day').valueOf(), endDate: moment(endDate).endOf('day').valueOf() }))
                    setFilter({ label: 'All', value: 'all' })
                    toast.success(res || "Channel added successfully");
                    props.onClose()
                    props.afterAction()
                })
                .catch(({ message = "" }) => {
                    toast.error(message || 'Oops! Something went wrong')
                })
        }

    }

    const handleChannelImageChange = (e) => {
        setChannelDetail({ ...channelDetail, imgCheckLoad: true, imageSizeLimitError: '' })
        let file = e?.target?.files[0]
        if (!file) {
            setChannelDetail({
                ...channelDetail,
                imageSizeLimit: '',
                imgCheckLoad: false,
                imageSizeLimitError: '',
            })
            return};
        Compress(file, options).then(res => {
            setChannelDetail({ ...channelDetail, imageSizeLimit: res.size })
            let imageSize = file?.size;
            if (imageSize <=  2000000) {
                if (res) {
                    const reader = new FileReader()
                    reader.onload = () => {
                        if (reader.readyState === 2) {
                            setChannelImage(reader.result)
                            setChannelImgBinaryData(Object.assign(res, { url: URL.createObjectURL(res) }))
                        }
                    }
                    reader && reader.readAsDataURL(res)
                setChannelDetail({ ...channelDetail, imgCheckLoad: false,imageSizeLimitError: "" })
                }
                setError({ ...errors, image: '' })
                setDataChange(true)
            }
            if(imageSize > 2000000){
            setChannelDetail({ ...channelDetail, imageSizeLimitError: "Image maximum size exceed 2 MB" })
                // props.toast.error("Image maximum size is 2 MB")
            }

        }).catch(err => {
            props.toast.error(err)
        })

    }

    const handleRemoveChannelImage = () => {
        setChannelImage('')
        setRemoveChannelImg(true)
    }

    const replaceChannelImg = () => {
        setRemoveChannelImg(true)
    }


    return (
        <React.Fragment>
            <div className="cat-popup">
                <h6 className="page-heading">{props.update ? 'Edit Channel' : 'Add Channel'}</h6>
                <form onSubmit={handleSubmit}>
                    <div className="episode-field mt-3">

                        <InputField value={channelDetail.channelName} name="channelName" label='Channel Name'
                            placeholder="Please enter channel name" onChange={(e) => handleChange(e)} fullWidth />

                        <div className={channelImage ? "img-grid2" : "img-grid"} >
                            <img src={channelImage} alt="" id="img" className={channelImage ? "img-added" : "img-browse"} />
                            <div className="camera" style={{ display: 'inline-block' }}>
                                <label htmlFor="channel-img" >
                                    {channelImage ?
                                        <CustomToolTip title="Replace Image"><span className="replace-img" onClick={replaceChannelImg} > <CachedIcon /> </span></CustomToolTip>
                                        :
                                        <CustomToolTip title="Add Image">   <span className="add-img">  <img src={cameraIcon} alt="" /></span></CustomToolTip>
                                    }
                                </label>
                                <input type="file" accept="image/jpeg, image/png" id="channel-img" onChange={(e) => handleChannelImageChange(e)} hidden />
                            </div>
                        </div>
                        {channelImage ? <CustomToolTip title="Remove Image" > <img className="remove-img" src={CrossIconWhite} alt="" onClick={() => handleRemoveChannelImage()} /> </CustomToolTip> : ''}
                    </div>
                    {channelDetail?.imageSizeLimitError &&
                        <div className='d-flex justify-content-end text-danger'>
                            <p>{channelDetail?.imageSizeLimitError}
                            </p>
                        </div>}

                        {channelDetail?.imgCheckLoad &&
                        <div className='d-flex justify-content-end text-primary'>
                    <p>Image uploading...</p>
                        </div>}

                    <Grid container>
                        <Grid item xs={12} sm={6} md={6} lg={6}>
                            <p className="cus-error">{errors?.channelName}</p>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6}>
                            <p className="cus-error" style={{ textAlign: 'end' }} >{errors?.channelImage}</p>
                        </Grid>
                    </Grid>

                    <p className="img-msg my-1">Image maximum size is 2 MB</p>


                </form>
                <div className="cust-buttons mt-4" >
                    <Button onClick={() => props.onClose()} className="button-btn new-btn-color mr-2" >Cancel</Button>
                    <Button onClick={handleSubmit} className='button-btn secondary-btn ml-2' disabled={isLoading} >
                        {isLoading ? <CircularProgress size="28px" color="white" /> : (props.update ? 'Update' : 'Add')}
                    </Button>
                </div>
            </div>
        </React.Fragment>
    )
}

export default AddChannelForm