import { apiPost, apiGet, apiDelete, apiPut } from '../../utils/axios'


export function getJuryCategoriesAPI() {
  return apiGet('/jury_cat')
}

export function deleteJuryCategoryAPI(data) {
  return apiDelete(`/jury_cat/${data}`)
}

export function getJuryCategoryByIdAPI(data) {
  return apiGet(`/jury_cat/${data}`)
}

export function createJuryCategoryAPI(data) {
  return apiPost('/jury_cat', data)
}

export function updateJuryCategoryAPI(data, id) {
  return apiPut(`/jury_cat/${id}`, data)
}

export function searchJuryCategoryByFilterAPI(data) {
  let payload = {
    term: data && data.term || '',
    type: data && data.type || ''
  }
  return apiGet(`/jury_cat`, payload)
}
