import Validator, { error } from 'is_js'
import { isEmpty } from "lodash";

function validateJury(data) {
    let errors = {}


    if (!/\S/.test(data.episode)) {
        errors.episode = "Episode is required!"
    }

    // if (!/\S/.test(data.question)) {
    //     errors.question = "Question is required!"
    // }

    if (!/\S/.test(data.image)) {
        errors.image = "Image is required!"
    }

    if (Validator.empty(data.channel)) {
        errors.channel = "Channel Name is required!"
    }


    return {
        isValid: isEmpty(errors),
        errors: errors
    };
}

export default validateJury;


