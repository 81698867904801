import React, { useEffect } from 'react'
import { Button, Checkbox, Grid, CircularProgress } from '@material-ui/core'
import { useState } from 'react'
import InputField from '../../components/common/InputField'
import userValidation from '../Validation/userValidation'
import * as action from './actions'
import { useDispatch, useSelector } from 'react-redux'

const initialUserState = {
    name: '',
    email: '',
    password: '',
    mobile: ''
}

function AddUserForm(props) {
    const dispatch = useDispatch()
    const { isLoading = false } = useSelector(state => state.users) || {}
    const [users, setUsers] = useState({ ...initialUserState })
    const [errors, setError] = useState({})
    const [dataChange, setDataChange] = useState(false)

    const { update, rowsPerPage } = props

    useEffect(() => {
        const { userDetails, update } = props
        const { user_name = "", email = "", phone = "" } = userDetails

        if (update) {
            setUsers({ ...users, name: user_name, email, mobile: phone })
        }
    }, [])

    const isValid = () => {
        const { isValid = true, errors = {} } = userValidation({ ...users, update })
        setError(errors)
        return isValid;
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        let numberReg = /^[0-9]{0,10}$/;
        if (name == "mobile" && value && !numberReg.test(value)) return;
        if (name == "mobile" && value.length > 10) return;

        setUsers({ ...users, [name]: value })
        setError({ ...errors, [name]: '' })
        setDataChange(true)
    }

    const handleSubmit = () => {
        const { name = "", email = "", password = "", mobile = "" } = users
        const { toast, update, userDetails } = props
        const { id } = userDetails

        if (isValid()) {
            const formData = {
                user_name: name,
                email,
                password,
                phone: mobile,
                id,
            }

            if (update) {
                if (dataChange) {
                    dispatch(action.UpdateUser(formData, id))
                        .then(({ res = "" }) => {
                            dispatch(action.getUserList({ limit: rowsPerPage }))
                            toast.success(res || "User updated successfully");
                            props.onClose()
                            setDataChange(false)
                            props.afterAction()
                        })
                        .catch(({ message = '' }) => {
                            toast.error(message || 'Oops! Something went wrong')
                        })
                    return
                } else {
                    setDataChange(false)
                    props.onClose()
                    props.afterAction()
                    return
                }
            }

            dispatch(action.CreateUser(formData))
                .then(({ res = "" }) => {
                    dispatch(action.getUserList({ limit: rowsPerPage }))
                    toast.success(res || "User added successfully");
                    props.onClose()
                    props.afterAction()
                })
                .catch(({ message = "" }) => {
                    toast.error(message || 'Oops! Something went wrong')
                })
        }
    }

    return (
        <div className="cat-popup user-modal">
            <h6 className="page-heading">{props.update ? 'Update User' : 'Add User'}</h6>
            <form onSubmit={handleSubmit} >
                <Grid container spacing={3} className="input-fields">
                    <Grid item xs={12} sm={6}>
                        <InputField name="name" label="Username" placeholder="Please enter username"
                            value={users.name} error={errors.name} onChange={handleChange} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <InputField name="email" label="Email" placeholder="Please enter user email"
                            value={users.email} error={errors.email} onChange={handleChange} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <InputField name="mobile" label="Mobile Number" placeholder="Please enter mobile number"
                            value={users.mobile} error={errors.mobile} onChange={handleChange} />
                    </Grid>

                    {
                        !props.update ?
                            <Grid item xs={12} sm={6}>
                                <InputField name="password" type="password" label="Password" placeholder="Please enter password"
                                    value={users.password} error={errors.password} onChange={handleChange} />
                            </Grid>
                            : ''
                    }
                </Grid>
            </form>
            <div className="cust-buttons justify-content-center text-center mt-4" >
                <Button onClick={() => props.onClose()} className="button-btn new-btn-color mr-2" >Close</Button>
                <Button onClick={handleSubmit} className='button-btn secondary-btn ml-2' disabled={isLoading} >
                    {isLoading ? <CircularProgress size="28px" color="white" /> : (props.update ? 'Update' : 'Add')}
                </Button>
            </div>
        </div>
    )
}

export default AddUserForm
