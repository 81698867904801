
import { apiPost, apiGet, apiDelete, apiPut } from '../../utils/axios'


export function GetTableWinnerAPI(data) {
    let payload = {
        limit: data && data.limit || '',
        page: data && data.page || '1'
    }
    return apiGet('/winners', payload)
}

export function UpdateTableWinnerAPI(data, id) {
    return apiPut(`/winners/${id}`, data)
}

export function searchTableWinnerAPI(data) {
   
    let term = data && data.term || ''
    let payload = {
        limit: data && data.limit || '',
        page: data && data.page || '',
        term: data && data.term || '',
        type: data && data.type || '',
        start_date: data && data.startDate || '',
        end_date: data && data.endDate || '',
        [data && data.key]: term || ''
    }
    return apiGet(`/winners`, payload)
}

export function DeleteTableWinerAPI(data) {
    return apiDelete(`/winners/${data}`)
}

export function CreateTableWinnerAPI(data) {
    return apiPost('/winners', data)
}