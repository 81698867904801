import Validator, { error } from 'is_js'
import { isEmpty } from "lodash";

function userLiveValidation(data) {
    let errors = {}


    if (!/\S/.test(data.selectedChannelId)) {
        errors.channel = "Channel is required!"
    }

    if (!/\S/.test(data.selectedEpisodeId)) {
        errors.episode = "Episode is required!"
    }


    return {
        isValid: isEmpty(errors),
        errors: errors
    };
}

export default userLiveValidation;


