import React from 'react'
import comingsoon from '../../assets/images/cm.svg'

function Dashboard() {
   
   return(
      <div className="coming-soon">
         <img src={comingsoon} alt="" />
         <h3>coming soon</h3>
      </div>
   )
}

export default Dashboard