import {
  GET_TOP_QUESTIONS_WINNERS,
  GET_TOP_QUESTIONS_WINNERS_SUCCEEDED,
  GET_TOP_QUESTIONS_WINNERS_FAILED,

  GET_TOP_CHANNEL,
  GET_TOP_CHANNEL_SUCCEEDED,
  GET_TOP_CHANNEL_FAILED,

  GET_EPISODE_BY_CHANNEL,
  GET_EPISODE_BY_CHANNEL_SUCCEEDED,
  GET_EPISODE_BY_CHANNEL_FAILED,

  CREATE_TOP_WINNER,
  CREATE_TOP_WINNER_SUCCESS,
  CREATE_TOP_WINNER_FAILED

} from './action'


const initState = {
  isLoading: false,
  topWinner: {},
  questions: {},
  channels: {},
  episode: {}
}

export default function (state = { ...initState }, action) {
  switch (action.type) {
    case GET_TOP_QUESTIONS_WINNERS:
      return { ...state, isLoading: true }

    case "CLEAR_TOP_WINNERS_SUCCEEDED":
      return { ...state, isLoading: false, topWinner: action.payload || {} }

    case GET_TOP_QUESTIONS_WINNERS_SUCCEEDED:
      return { ...state, isLoading: false, questions: action.payload || {} }

    case GET_TOP_QUESTIONS_WINNERS_FAILED:
      return { ...state, isLoading: false, questions: {} }

    case GET_TOP_CHANNEL:
      return { ...state, isLoading: true }

    case GET_TOP_CHANNEL_SUCCEEDED:
      return { ...state, isLoading: false, channels: action.payload || {} }

    case GET_TOP_CHANNEL_FAILED:
      return { ...state, isLoading: false, channels: {} }

    case GET_EPISODE_BY_CHANNEL:
      return { ...state, isLoading: true }

    case GET_EPISODE_BY_CHANNEL_SUCCEEDED:
      return { ...state, isLoading: false, episode: action.payload || {} }

    case GET_EPISODE_BY_CHANNEL_FAILED:
      return { ...state, isLoading: false, episode: {} }

    case CREATE_TOP_WINNER:
      return { ...state, isLoading: true }

    case CREATE_TOP_WINNER_SUCCESS:
      return { ...state, isLoading: false }

    case CREATE_TOP_WINNER_FAILED:
      return { ...state, isLoading: false }

    default:
      return state
  }
}