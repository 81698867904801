import React, { useEffect } from 'react'
import { useState } from 'react'
import InputField from '../../../components/common/InputField'
import { Button, Checkbox, Grid, CircularProgress } from '@material-ui/core'
import * as action from './questionAction'
import { useDispatch, useSelector } from 'react-redux'
import addQuestionValidation from '../../Validation/questionValidation'
import loader from '../../../assets/images/loader.gif'
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import CachedIcon from '@material-ui/icons/Cached';
import CustomToolTip from '../../../components/common/ToolTip'
import deleteIcon from '../../../assets/images/deleteIcon.svg'
import cameraIcon from '../../../assets/images/camera.svg'
import CrossIcon from '../../../assets/images/cross.svg'
import CrossIconWhite from '../../../assets/images/cross-white.svg'


function AddQuestionForm(props) {
    const { toast } = props
    const dispatch = useDispatch()
    const { isLoadingQus = false } = useSelector(state => state.question) || {}
    const [questionDescription, setQuestionDescription] = useState('')
    const [question, setQuestion] = useState('')
    const [allOptions, setAllOptions] = useState([
        { correct: false, option: '', image: '', remove_img_key: '' },
        { correct: false, option: '', image: '', remove_img_key: '' },
        { correct: false, option: '', image: '', remove_img_key: '' },
        { correct: false, option: '', image: '', remove_img_key: '' }
    ])
    const [disableSubmit, setDisableSubmit] = useState(false)
    const [errors, setError] = useState({})
    const [dataChange, setDataChange] = useState(false)
    const [removeImg, setRemoveImg] = useState(false)


    const handleQuestion = ({ target: { name = '', value = '' } }) => {
        setQuestion(value)
        setError({ ...errors, question: '' })
        setDataChange(true)
    }

    const handleQuestionDescription = ({ target: { name = '', value = '' } }) => {
        setQuestionDescription(value)
        setError({ ...errors, questionDescription: '' })
        setDataChange(true)
    }

    const handleChangeInput = (index, e) => {
        const { name = "", value = "" } = e.target
        const values = [...allOptions]
        values[index][name] = value
        setAllOptions(values)
        setError({ ...errors, [name]: '' })
        setDataChange(true)
    }

    const handleChecked = (index) => {
        let newAns = allOptions && allOptions.map((ans, i) => {
            if (index == i) return { ...ans, correct: !ans.correct }
            return ans;
        })
        setAllOptions(newAns)
        setError({ ...errors, answer: '' })
        setDataChange(true)
    }


    useEffect(() => {
        const { questionDetails = {}, update = false } = props

        let { question = "", options = [], description = "" } = questionDetails

        let optionData = options && options.map((item, parentIndex) => {
            return ({
                option: item.value || '',
                correct: JSON.parse(item.is_correct),
                image: item.image ? item.image : '',
                imageKey: item.image_key || ''
            })
        })
        if (update) {
            setQuestion(question)
            setAllOptions(optionData)
            setQuestionDescription(description)
        }
    }, [])

    const isValid = () => {
        const { isValid = true, errors = {} } = addQuestionValidation({ allOptions, question })
        setError(errors)
        return isValid;
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        const { categoryId } = props.match.params;
        const data = allOptions && allOptions?.map(({ image, imgBlob, ...rest }) => ({ ...rest }))
        const imageData = allOptions && allOptions.length ? allOptions?.map(data => data?.imgBlob) : []
        let imageLength = imageData && imageData.filter(e => e).length

        if (isValid()) {
            const ansObject = allOptions && allOptions.length ? allOptions?.filter(allOption => (allOption.correct == true)) : []
            if (props.update) {
                const { questionDetails } = props
                const { id, cat_id } = questionDetails
                const data = allOptions && allOptions?.map(({ image, imgBlob, ...rest }) => ({ ...rest }))
                const fd = new FormData()

                // fd.append("id", id);
                fd.append("cat_id", cat_id);
                fd.append("question", question || '');
                fd.append("description", questionDescription || '');
                imageLength &&
                    allOptions && allOptions.map((opt, i) => {
                        opt.imgBlob && fd.append(`options[${i}][image]`, opt.imgBlob || '');
                    })
                data && data.forEach((item, i) => {
                    fd.append(`options[${i}][value]`, item.option.trim());
                    fd.append(`options[${i}][is_correct]`, item.correct);
                    item.remove_img_key && fd.append(`remove_images[${i}]`, item.remove_img_key || '');
                });

                if (dataChange) {
                    dispatch(action.EditQuestion(fd, id)).then(({ response = "" }) => {
                        props.onClose()
                        props.toast.success(response.message || 'Question updated successfully!')
                        dispatch(action.GetQuestion(categoryId))
                        setDisableSubmit(false)
                        props.afterAction()
                    }).catch(err => {
                        props.toast.error((err?.response?.data?.message) || "Something went wrong.")
                        setDisableSubmit(false)
                    })
                    return
                } else {
                    props.onClose()
                    props.afterAction()
                    dispatch(action.GetQuestion(categoryId))
                    return
                }
            }

            const fd = new FormData()
            fd.append("cat_id", categoryId);
            fd.append("question", question || '');
            fd.append("description", questionDescription || '');
            imageLength &&
                allOptions && allOptions.map((opt, i) => {
                    fd.append(`options[${i}][image]`, opt.imgBlob || '');
                })
            data && data.forEach((item, i) => {
                fd.append(`options[${i}][value]`, item.option.trim() || '');
                fd.append(`options[${i}][is_correct]`, item.correct);
            });


            dispatch(action.CreateQuestion(fd))
                .then(res => {
                    props.onClose()
                    dispatch(action.GetQuestion(categoryId))
                    toast.success("Question added successfully");
                    setDisableSubmit(false)
                    props.afterAction()
                })
                .catch(err => {
                    toast.error(err.message || 'Oops! Something went wrong')
                    setDisableSubmit(false)
                })
        }
    }

    const addOption = () => {
        setAllOptions([...allOptions, { correct: false, option: '', image: '' }])
    }

    const removeOption = (index) => {
        const values = [...allOptions]
        values.splice(index, 1)
        setAllOptions(values)
        setDataChange(true)
    }

    const imgHandleChange = (e, index) => {
        let file = e?.target?.files[0]
        let imageSize = file && file?.size
        if (imageSize <= 2097152) {
            if (file) {
                const reader = new FileReader()
                reader.onload = () => {
                    if (reader.readyState === 2) {
                        let imageData = allOptions && allOptions.map((img, i) => {

                            if (index == i) {
                                return { ...img, image: reader.result, imgBlob: Object.assign(file, { url: URL.createObjectURL(file) }) }
                            }
                            return img
                        })
                        setAllOptions(imageData)
                        return
                    }
                }
                reader && reader.readAsDataURL(e?.target?.files[0])
                setDataChange(true)
            }
        } else {
            toast.error("Image maximum size is 2 MB")
        }
    }

    const handleRemoveImage = (index) => {
        let removeImage = allOptions && allOptions.map((ans, i) => {
            // var keyValue = ans.imageKey
            // if(ans.image.includes('https://')){
            //     console.log('this will update')
            //     keyValue = ""
            //     console.log('keyValue',keyValue)
            // }
            if (index == i) return { ...ans, image: '', imgBlob: null, remove_img_key: ans.imageKey }
            return ans;
        })
        setAllOptions(removeImage)
        // remove_img_key
    }

    const replaceImage = (index) => {
        let removeImage = allOptions && allOptions.map((ans, i) => {
            if (index == i) return { ...ans, image: '', imgBlob: null, remove_img_key: ans.imageKey }
            return ans;
        })
        setAllOptions(removeImage)
    }

    return (
        <div className="question-popup">
            <h5>{props.update ? 'Edit Question' : 'Add Question'}</h5>
            <form onSubmit={handleSubmit} >
                <div className="cust-input-fields">
                    <InputField
                        name='questionName'
                        value={question}
                        label="Question"
                        placeholder="Please enter question"
                        onChange={handleQuestion}
                        error={errors.question}
                        margin="normal"
                        fullWidth
                        multiline
                        rows={3}
                        required
                        inputProps={{ maxlength: 350 }}
                    />
                    <InputField
                        name='questionDescription'
                        value={questionDescription}
                        label="Question Description"
                        placeholder="Please enter question description"
                        onChange={handleQuestionDescription}
                        error={errors.questionDescription}
                        margin="normal"
                        fullWidth
                        multiline
                        rows={3}
                        inputProps={{ maxlength: 700 }}
                    />
                </div>
                <div className="bottom-part">
                    <h5 className="options">Options</h5>
                    <div className="option-main">
                        {
                            allOptions && allOptions.length ? allOptions.map((option, index) => {
                                return (

                                    <div className={index > 3 ? 'option-field added-field' : 'option-field'} >
                                        <Grid container>
                                            <Grid item key={index} xs={12} sm={12} md={12} lg={12} >
                                                <Checkbox
                                                    checked={option.correct}
                                                    onChange={() => handleChecked(index)}
                                                // checkedIcon
                                                // icon
                                                />
                                                <InputField
                                                    name='option'
                                                    value={option.option}
                                                    label={`Option ${index + 1}`}
                                                    placeholder={`Please enter option ${index + 1}`}
                                                    onChange={(e) => handleChangeInput(index, e)}
                                                    required
                                                    inputProps={{ maxlength: 200 }}
                                                />
                                                {index > 3 ? <CustomToolTip title="Remove Option">  <span className="delete-icon ml-1" onClick={() => removeOption(index)} > <img src={CrossIcon} className="" alt="" /> </span> </CustomToolTip> : ''}
                                                <div className={option.image ? "img-grid2" : "img-grid"} >
                                                    <img src={option.image} alt="" id="img" className={option.image ? "img-added" : "img-browse"} />
                                                    <div className="camera" style={{ display: 'inline-block' }}>
                                                        <label htmlFor={`option-img-${index}`} >{option.image ?
                                                            <CustomToolTip title="Replace Image"><span className="replace-img" onClick={() => replaceImage(index, option)} > <CachedIcon /> </span></CustomToolTip>
                                                            :
                                                            <CustomToolTip title="Add Image">   <span className="add-img">  <img src={cameraIcon} alt="cameraIcon" /></span></CustomToolTip>
                                                        }
                                                        </label>
                                                        <input type="file" accept="image/jpeg, image/png" max-size="2000" id={`option-img-${index}`} onChange={(e) => imgHandleChange(e, index)} hidden />
                                                    </div>
                                                </div>
                                                {option.image ? <CustomToolTip title="Remove Image" > <img className="remove-img" src={CrossIconWhite} onClick={() => handleRemoveImage(index, option)} alt="" /> </CustomToolTip> : ''}
                                            </Grid>
                                        </Grid>
                                    </div>

                                )
                            }) : ''
                        }
                    </div>
                </div>
            </form>
            <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12} >
                    {allOptions?.length < 20 ? <Button onClick={(e) => addOption(e)} className="button-btn add-option new-btn-color" >Add More Option</Button> : ''}
                </Grid>
            </Grid>
            <div className="errors-sec">
                <div className="text-center">
                    {allOptions && allOptions.find(opt => opt.image.length >= 1) ? '' : <p className="img-msg my-1">Image maximum size is 2 MB</p>}
                    <p className="cus-error">{errors?.answer}</p>
                    <p className="cus-error"> {errors?.option}</p>
                    <p className="cus-error"> {errors?.image}</p>
                    <p className="cus-error"> {errors?.repeated}</p>
                </div>
                <ol>
                    {
                        Array.isArray(errors.repeatedList) ?
                            errors.repeatedList.length && errors.repeatedList.map(err => {
                                return <li className="cus-error" > {err} </li>

                            }) : ''
                    }
                </ol>

                {/* className="button-btn new-btn-color" */}
                <div className="bottom-buttons text-center mt-3" >
                    <Button onClick={() => props.onClose()} className="button-btn new-btn-color mr-2" >Close</Button>
                    <Button onClick={handleSubmit} className='button-btn secondary-btn ml-2' disabled={isLoadingQus} >
                        {isLoadingQus ? <CircularProgress size="28px" color="white" /> : (props.update ? 'Update' : 'Add')}
                    </Button>
                </div>
            </div>

        </div>
    )
}

export default AddQuestionForm