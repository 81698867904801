import React, { useEffect, useReducer, useState } from 'react'
import InputField from '../../components/common/InputField'
import { Button, CircularProgress, Grid, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import * as action from './actions'
import * as channelAction from '../Top-12-Winners/action'
import cameraIcon from '../../assets/images/camera.svg'
import CrossIconWhite from '../../assets/images/cross-white.svg'
import moment from 'moment'
import validateHost from '../Validation/hostValidate'
import CustomToolTip from '../../components/common/ToolTip'
import CachedIcon from '@material-ui/icons/Cached';
import Compress from "browser-image-compression";
import { options } from '../../constant'

const initialState = {
    channel_id: '',
    episode_id: '',
    username: '',
    email: '',
    password: '',
    imageSizeLimitError: '',
    imageSizeLimit: '',
}

function AddHostForm(props) {
    const dispatch = useDispatch()
    const [hostDetails, setHostDetails] = useState({ ...initialState })
    const [errors, setError] = useState({})
    const [dataChange, setDataChange] = useState(false)
    const [image, setImage] = useState('')
    const [removeImg, setRemoveImg] = useState(false)
    const [imageBinaryData, setImageBinaryData] = useState('')
    const [selectedChannelId, setSelectedChannelId] = useState('')

    const { isLoading = false } = useSelector(state => state.host)
    const { channels = {}, episode = {}, isLoading: Loading = false } = useSelector(state => state.topWinner) || {}

    const { data: channelList = [], total } = channels || {}
    const { list = {} } = episode
    const { Items: episodeList = [] } = list || {}
    const { update = false } = props


    useEffect(() => {
        const { update, hostDetails = {} } = props
        const { channel_title = "", image = "", channel_id = "" } = hostDetails
        dispatch(channelAction.GetChannels())
        dispatch(channelAction.GetEpisodeByChannel(channel_title))
        if (update) {
            setHostDetails({ ...hostDetails, channel_id: channel_title, password: '' })
            setImage(image)
            setSelectedChannelId(channel_id)
        }
    }, [])


    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === "username" && value.length > 40) return

        setHostDetails({ ...hostDetails, [name]: value })
        setError({ ...errors, episode: '' })
        setDataChange(true)
        name == 'channel_id' && dispatch(channelAction.GetEpisodeByChannel(value))
    }

    const isValid = () => {
        const { isValid = true, errors = {} } = validateHost({ ...hostDetails, image, isUpdate: props.update })
        setError(errors)
        return isValid
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        const { update, toast, startDate, endDate, rowsPerPage } = props
        if (isValid()) {
            if (update) {
                const { id = {}, image_key = "" } = hostDetails
                const fd = new FormData()

                fd.append("channel_id", selectedChannelId);
                // fd.append("episode_id", hostDetails.episode_id);
                fd.append("username", hostDetails.username);
                fd.append("email", hostDetails.email);
                fd.append("password", hostDetails.password);
                imageBinaryData && fd.append("image", imageBinaryData);
                fd.append("remove_image", removeImg ? image_key : '');

                if (dataChange) {
                    dispatch(action.UpdateHost(fd, id))
                        .then(({ res = "" }) => {
                            dispatch(action.SearchHostByFilter({ limit: rowsPerPage, startDate: moment(startDate).startOf('day').valueOf(), endDate: moment(endDate).endOf('day').valueOf() }))
                            toast.success(res || "Host updated successfully");
                            props.onClose()
                            setDataChange(false)
                            props.afterAction()
                            setSelectedChannelId('')
                        })
                        .catch(({ message = '' }) => {
                            toast.error(message || 'File must be a type of  png , jpeg etc')
                        })
                    return
                } else {
                    setDataChange(false)
                    props.onClose()
                    props.afterAction()
                    setSelectedChannelId('')
                    return
                }
            }

            const fd = new FormData()

            fd.append("channel_id", selectedChannelId);
            // fd.append("episode_id", hostDetails.episode_id);
            fd.append("username", hostDetails.username);
            fd.append("email", hostDetails.email);
            fd.append("password", hostDetails.password);
            imageBinaryData && fd.append("image", imageBinaryData);

            dispatch(action.CreateHost(fd))
                .then(({ res = "" }) => {
                    dispatch(action.SearchHostByFilter({ limit: rowsPerPage, startDate: moment(startDate).startOf('day').valueOf(), endDate: moment(endDate).endOf('day').valueOf() }))
                    toast.success(res || "Host added successfully");
                    props.onClose()
                    props.afterAction()
                    setSelectedChannelId('')
                    setSelectedChannelId('')
                })
                .catch(({ message = "" }) => {
                    toast.error(message || 'File must be a type of  png , jpeg etc')
                })
        }

    }

    const handleImageChange = (e) => {
        setHostDetails({ ...hostDetails, imgCheckLoad: true, imageSizeLimitError: '' })
        let file = e?.target?.files[0]
        if (!file) {
            setHostDetails({...hostDetails,
                imageSizeLimitError: '',
            })
            return};
        Compress(file, options).then(res => {
            setHostDetails({ ...hostDetails, imageSizeLimit: res.size })
            let imageSize = file?.size;
            if (imageSize <=  2000000) {
                if (res) {
                    const reader = new FileReader()
                    reader.onload = () => {
                        if (reader.readyState === 2) {
                            setImage(reader.result)
                            setImageBinaryData(Object.assign(res, { url: URL.createObjectURL(res) }))
                            setDataChange(true)
                        }
                    }
                    reader && reader.readAsDataURL(res)
                setHostDetails({ ...hostDetails, imgCheckLoad: false,imageSizeLimitError: "" })
                }
                setError({ ...errors, image: '' })
            }
            if(imageSize > 2000000){
            setHostDetails({ ...hostDetails, imageSizeLimitError: "Image maximum size exceed 2 MB" })
                // props.toast.error("Image maximum size is 2 MB")
            }

        }).catch(err => {
            props.toast.error(err)
        })
    }

    const handleRemoveImage = () => {
        setImage('')
        setRemoveImg(true)
    }

    const replaceImage = () => {
        setRemoveImg(true)
    }

    return (
        <React.Fragment>
            <div className="cat-popup add-host-form-modal">
                <h6 className="page-heading">{props.update ? 'Edit Host' : 'Add Host'}</h6>
                <form onSubmit={handleSubmit}>
                    <div className="episode-field ">
                        <div className="ques-field">
                            <InputField
                                name="username"
                                value={hostDetails.username}
                                onChange={(e) => handleChange(e)}
                                fullWidth
                                label="Host Name"
                                placeholder="Please enter Host Name"

                                inputProps={{ maxlength: 350 }}
                            />
                        </div>

                        <div className={image ? "img-grid2" : "img-grid"} >
                            <img src={image} alt="" id="img" className={image ? "img-added" : "img-browse"} />
                            <div className="camera" style={{ display: 'inline-block' }}>
                                <label htmlFor="option-img" >
                                    {image ?
                                        <CustomToolTip title="Replace Image"><span className="replace-img" onClick={replaceImage} > <CachedIcon /> </span></CustomToolTip>
                                        :
                                        <CustomToolTip title="Add Image">   <span className="add-img">  <img src={cameraIcon} alt="" /></span></CustomToolTip>
                                    }
                                </label>
                                <input type="file" accept="image/jpeg, image/png" id="option-img" onChange={(e) => handleImageChange(e)} hidden />
                            </div>
                        </div>
                        {image ? <CustomToolTip title="Remove Image" > <img className="remove-img" src={CrossIconWhite} alt="" onClick={() => handleRemoveImage()} /> </CustomToolTip> : ''}

                    </div>
                    {hostDetails?.imageSizeLimitError &&
                        <div className='d-flex justify-content-end text-danger'>
                            <p>{hostDetails?.imageSizeLimitError}
                            </p>
                        </div>}

                        {hostDetails?.imgCheckLoad &&
                        <div className='d-flex justify-content-end text-primary'>
                    <p>Image uploading...</p>
                        </div>}
                    <Grid container>
                        <Grid item xs={12} sm={6} md={6} lg={6}>
                            <p className="cus-error">{errors.username}</p>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6}>
                            <p className="cus-error" style={{ textAlign: 'end' }} >{errors?.image}</p>
                        </Grid>
                    </Grid>
                    <p className="img-msg my-1">Image maximum size is 2 MB</p>

                    <FormControl variant="outlined" className="channel-dropdown mt-3" fullWidth disabled={Loading}>
                        <InputLabel id="channel-name">Channel Name</InputLabel>
                        {Loading ? <div className="field-loader"> <CircularProgress color="primary" size={25} /> </div> : ''}
                        <Select
                            labelId="channel-name"
                            id="channel-name"
                            value={hostDetails.channel_id}
                            onChange={(e) => handleChange(e)}
                            label="Channel Name"
                            name="channel_id"
                            error={errors.channel_id}
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            {
                                channelList.map(channel => {
                                    return <MenuItem value={channel?.title} onClick={() => setSelectedChannelId(channel.id)}>{channel?.title}</MenuItem>
                                })
                            }
                        </Select>
                    </FormControl>
                    <span className="cus-error">{errors?.channel_id}</span>

                    {/* <FormControl variant="outlined" className="channel-dropdown mt-3" fullWidth disabled={Loading}>
                        <InputLabel id="channel-name">Episode</InputLabel>
                        {Loading ? <div className="field-loader"> <CircularProgress color="primary" size={25} /> </div> : ''}
                        <Select
                            labelId="channel-name"
                            id="channel-name"
                            value={hostDetails.episode_id}
                            onChange={(e) => handleChange(e)}
                            label="Episode"
                            name="episode_id"
                            error={errors.episode_id}
                            disabled={!hostDetails.channel_id}
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            {
                                episodeList.map(episode => {
                                    return <MenuItem value={episode?.id}>{episode?.episode}</MenuItem>
                                })
                            }
                        </Select>
                    </FormControl>
                    <span className="cus-error">{errors?.episode_id}</span> */}

                    <div className="ques-field">
                        <InputField
                            name="email"
                            value={hostDetails.email}
                            onChange={(e) => handleChange(e)} fullWidth
                            label="Host Email"
                            placeholder="Please enter email"
                            error={errors.email}
                            inputProps={{ maxlength: 350 }}
                        />
                    </div>
                    <div className="ques-field">
                        <InputField
                            type="password"
                            name="password"
                            value={hostDetails.password}
                            onChange={(e) => handleChange(e)} fullWidth
                            label="Host Password"
                            placeholder="Please enter password"
                            error={errors.password}
                            inputProps={{ maxlength: 350 }}
                        />
                    </div>

                </form>

                <div className="cust-buttons mt-4" >
                    <Button onClick={() => props.onClose()} className="button-btn new-btn-color mr-2" >Cancel</Button>
                    <Button onClick={handleSubmit} className='button-btn secondary-btn ml-2' disabled={isLoading} >
                        {isLoading ? <CircularProgress size="28px" color="white" /> : (props.update ? 'Update' : 'Add')}
                    </Button>
                </div>
            </div>
        </React.Fragment>
    )
}

export default AddHostForm