import {
  getCategoriesAPI,
  deleteCategoryAPI,
  createCategoryAPI,
  updateCategoryAPI,
  getCategoryByIdAPI,
  searchCategoryByFilterAPI
} from './apis'


export function GetCategories(data) {
  return dispatch => {
    dispatch({
      type: 'GET_CATEGORIES'
    })
    return new Promise((resolve, rej) => {
      getCategoriesAPI(data).then(res => {
        dispatch({
          type: 'GET_CATEGORIES_SUCCESS',
          payload: res
        })
        return resolve(res)
      })
        .catch(err => {
          dispatch({
            type: 'GET_CATEGORIES_FAILED',
            payload: {}
          })
          return rej(err)
        })
    })
  }
}

export function GetCategoriesByFilter(data) {
  return dispatch => {
    dispatch({
      type: 'GET_CATEGORIES'
    })
    return new Promise((resolve, rej) => {
      searchCategoryByFilterAPI(data).then(res => {
        dispatch({
          type: 'GET_CATEGORIES_SUCCESS',
          payload: res
        })
        return resolve(res)
      })
        .catch(err => {
          dispatch({
            type: 'GET_CATEGORIES_FAILED',
            payload: {}
          })
          return rej(err)
        })
    })
  }
}

export const GET_CATEGORIES = 'GET_CATEGORIES'
export const GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS'
export const GET_CATEGORIES_FAILED = 'GET_CATEGORIES_FAILED'

export function DeleteCategory(data) {
  return dispatch => {
    dispatch({
      type: 'DELETE_CATEGORY'
    })
    return new Promise((resolve, rej) => {
      deleteCategoryAPI(data).then(res => {
        dispatch({
          type: 'DELETE_CATEGORY_SUCCESS'
        })
        return resolve(res)
      })
        .catch(err => {
          dispatch({
            type: 'DELETE_CATEGORY_FAILED'
          })
          return rej(err)
        })
    })
  }
}

export const DELETE_CATEGORY = 'DELETE_CATEGORY'
export const DELETE_CATEGORY_SUCCESS = 'DELETE_CATEGORY_SUCCESS'
export const DELETE_CATEGORY_FAILED = 'DELETE_CATEGORY_FAILED'

export function GetCategoryById(data) {
  return dispatch => {
    dispatch({
      type: 'GET_CATEGORY_BY_ID'
    })
    return new Promise((resolve, rej) => {
      getCategoryByIdAPI(data).then(res => {
        dispatch({
          type: 'GET_CATEGORY_BY_ID_SUCCESS',
          payload: res
        })
        return resolve(res)
      })
        .catch(err => {
          dispatch({
            type: 'GET_CATEGORY_BY_ID_FAILED',
            payload: {}
          })
          return rej(err)
        })
    })
  }
}

export const GET_CATEGORY_BY_ID = "GET_CATEGORY_BY_ID"
export const GET_CATEGORY_BY_ID_SUCCESS = "GET_CATEGORY_BY_ID_SUCCESS"
export const GET_CATEGORY_BY_ID_FAILED = "GET_CATEGORY_BY_ID_FAILED"

export function CreateCategory(data) {
  return dispatch => {
    dispatch({
      type: 'CREATE_CATEGORY'
    })
    return new Promise((resolve, rej) => {
      createCategoryAPI(data).then(res => {
        dispatch({
          type: 'CREATE_CATEGORY_SUCCESS'
        })
        return resolve(res)
      })
        .catch(err => {
          dispatch({
            type: 'CREATE_CATEGORY_FAILED'
          })
          return rej(err)
        })
    })
  }
}

export const CREATE_CATEGORY = 'CREATE_CATEGORY'
export const CREATE_CATEGORY_SUCCESS = "CREATE_CATEGORY_SUCCESS"
export const CREATE_CATEGORY_FAILED = "CREATE_CATEGORY_FAILED"

export function EditCategory(data, id) {
  return dispatch => {
    dispatch({
      type: 'EDIT_CATEGORY'
    })
    return new Promise((resolve, rej) => {
      updateCategoryAPI(data, id).then(res => {
        dispatch({
          type: 'EDIT_CATEGORY_SUCCESS'
        })
        return resolve(res)
      })
        .catch(err => {
          dispatch({
            type: 'EDIT_CATEGORY_FAILED'
          })
          return rej(err)
        })
    })
  }
}

export const EDIT_CATEGORY = "EDIT_CATEGORY"
export const EDIT_CATEGORY_SUCCESS = "EDIT_CATEGORY_SUCCESS"
export const EDIT_CATEGORY_FAILED = "EDIT_CATEGORY_FAILED"