
import { apiPost, apiGet, apiDelete, apiPut } from '../../utils/axios'

export function GetChannelAPI(data) {
    let payload = {
        limit: data && data.limit || '',
        // page : data && data.page || ''
    }
    return apiGet('/channels', payload)
}

export function SearchChannelByFilterAPI(data) {


    let term = data && data.term || ''
    let payload = {
        limit: data && data.limit || '',
        page: data && data.page || '',
        title: data && data.term || '',
        type: data && data.type || '',
        start_date: data && data.startDate || '',
        end_date: data && data.endDate || '',
        [data && data.key]: term || ''
    }
    return apiGet('/channels', payload)
}

export function UpdateChannelAPI(data, id) {
    return apiPut(`/channels/${id}`, data)
}


export function DeleteChannelAPI(id) {
    return apiDelete(`/channels/${id}`)
}

export function CreateChannelAPI(data) {
    return apiPost('/channels', data)
}