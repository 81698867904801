import { apiDelete, apiGet, apiPost, apiPut } from "../../../utils/axios";

export function createQuestionAPI(data) {
    return apiPost(`/questions`, data)
}

export function updateQuestionAPI(data, id,) {
    return apiPut(`/questions/${id}`, data)
}


export function updateQuestionStatusAPI(id, data) {
    return apiPut(`/questions/status/${id}`, data)
}

export function getQuestionAPI(data) {
    return apiGet(`/questions?gift_id=${data}`)
}

export function deleteQuestionAPI(data) {
    return apiDelete(`/questions/${data}`)
}

export function getQuestionByFilterAPI(data) {
    console.log(data, 'data')
    let payload = {
        term: data && data.term || '',
        type: 'question',
        gift_id: data && data.gift_id

    }
    return apiGet(`/questions`, payload)
}