import React, { useEffect, useState } from 'react'
import { Grid, Box, CircularProgress } from '@material-ui/core'
import SettingsIcon from '@material-ui/icons/Settings'
import InputField from '../../components/common/InputField'
import { Button } from '@material-ui/core'
import { updatePassword } from './actions'
import { useDispatch, useSelector } from 'react-redux'
import userImage from '../../assets/images/userImage.svg'
import validateSettingInfo from '../Validation/settingValidation'
import validatePasswordUpdate from '../Validation/passwordValidation'
import * as action from './actions'
import loader from '../../assets/images/loader.gif'
import editcatIcon from '../../assets/images/edit.svg'
import CustomTooltip from '../../components/common/ToolTip'
import CrossIcon from '../../assets/images/cross-black.svg'
import { logOut, getObject } from "../../utils";
import { useHistory } from "react-router-dom";

// import loader from '../../assets/images/loader.gif'

const initInfo = {
    name: '',
    email: '',
    mobNo: '',
}

const initPassword = {
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
}

export default function Settings(props) {

    const [isEdit, setIsEdit] = useState(false)
    const [infoDetails, setInfoDetails] = useState({ ...initInfo })
    const [passwordUpdate, setPasswordUpdate] = useState({ ...initPassword })
    const [errors, setError] = useState({})
    const [passwordErr, setPasswordErr] = useState({})
    const [existPwd, setExistPwd] = useState(false)
    const [passwordChange, setPasswordChange] = useState(false)
    const [imageBinaryData, setImageBinaryData] = useState('')
    const [profileImage, setProfileImage] = useState('')
    const [dataChange, setDataChange] = useState(false)
    const [removeImg, setRemoveImg] = useState(false)
    const [imageLoad, setImageLoad] = useState(true)
    const [image, setImage] = useState("")

    const dispatch = useDispatch()
    const { admin = {}, isLoading = false } = useSelector(state => state?.admin) || {}

    const { image: adminImage = '', contact_no = '', username = '', email = '', id = "", image_key = "" } = admin || {}

    let data = JSON.parse(localStorage.getItem('top-challenge-token'))

    let dataId = data.id

    const history = useHistory()

    useEffect(() => {
        dispatch(action.GetAdminDetails(dataId))
    }, [])


    // console.log(data.id, 'dataIODDDDDD')

    useEffect(() => {
        if (isEdit) {
            setInfoDetails({
                ...infoDetails, mobNo: contact_no, email, name: username
            })
        }
    }, [isEdit])

    useEffect(() => {
        setImage(adminImage)
    }, [adminImage])

    const handleEditClick = () => {
        setIsEdit(!isEdit)
        if (passwordChange) {
            setPasswordChange(false)
        }
        setError({})
        setProfileImage('')
    }

    const handleInfoChange = ({ target: { name = "", value = "" } }) => {
        let numberReg = /^[0-9]{0,10}$/;
        let nameReg = /^[a-zA-Z ]*$/;
        // let regexp = /^\S*$/;
        if (name == "name" && value && !nameReg.test(value)) return;
        // if (name == "name" && value && !regexp.test(value)) return;
        if (name == "name" && value.length > 40) return;

        if (name == "mobNo" && value && !numberReg.test(value)) return;
        if (name == "mobNo" && value.length > 10) return;
        setInfoDetails({ ...infoDetails, [name]: value })
        setError({ ...errors, [name]: '' })
        setDataChange(true)
    }

    const handlePasswordChange = ({ target: { name = "", value = "" } }) => {
        setPasswordUpdate({ ...passwordUpdate, [name]: value })
        setPasswordErr({ ...passwordErr, [name]: '' })
        setDataChange(true)
    }

    const isValid = () => {
        const { isValid = true, errors = {} } = validateSettingInfo({ ...infoDetails })
        setError(errors)
        return isValid
    }

    const passwordValid = () => {
        const { isValid = true, passwordErr = {} } = validatePasswordUpdate({ ...passwordUpdate })

        if (!passwordUpdate.currentPassword && !passwordUpdate.newPassword) {
            props.toast.error('current,new and confirm password are required.')
        }
        else if (passwordUpdate.currentPassword === passwordUpdate.newPassword) {
            props.toast.error('current and new password cant be same.')
            setExistPwd(true)
        } else {
            setPasswordErr(passwordErr)
            return isValid
        }
    }

    const passworsCancel = () => {
        setPasswordChange(!passwordChange)
        setPasswordErr({})
    }
    const handleWithPassword = (e) => {
        e.preventDefault()
        if (isValid() && passwordValid()) {
            const fd = new FormData()
            fd.append("id", id)
            fd.append("username", infoDetails.name)
            fd.append("email", infoDetails.email)
            fd.append("contact_no", infoDetails.mobNo)
            fd.append("current_password", passwordUpdate.currentPassword)
            fd.append("new_password", passwordUpdate.newPassword)
            profileImage.length && fd.append("profile_image", imageBinaryData)
            removeImg && fd.append("remove_image", image_key);
            if (dataChange) {
                dispatch(action.UpdateSetting(fd))
                    .then(response => {
                        dispatch(action.GetAdminDetails(dataId))
                        props.toast.success(response.message || 'Profile information updated successfully!')
                        setIsEdit(false)
                        setDataChange(false)
                        setRemoveImg(false)
                        logOut("top-challenge-token")
                        logOut("top-challange-admin")
                        history.push('/login')
                    })
                    .catch(err => {
                        props.toast.error((err?.response?.data?.message) || "Something went wrong.")
                    })
                return
            } else {
                setIsEdit(false)
                return
            }
        }
    }

    const handleInfoSubmit = (e) => {
        e.preventDefault()
        if (isValid()) {
            const fd = new FormData()
            fd.append("id", id)
            fd.append("username", infoDetails.name)
            fd.append("email", infoDetails.email)
            fd.append("contact_no", infoDetails.mobNo)
            fd.append("image", imageBinaryData)
            removeImg && fd.append("remove_image", image_key);

            if (dataChange) {
                dispatch(action.UpdateSetting(fd))
                    .then(response => {
                        dispatch(action.GetAdminDetails(dataId))
                        props.toast.success(response.message || 'Profile information updated successfully!')
                        setIsEdit(false)
                        setDataChange(false)
                        setRemoveImg(false)
                    })
                    .catch(err => {
                        props.toast.error((err?.response?.data?.message) || "Something went wrong.")
                    })
                return
            } else {
                setIsEdit(false)
                setRemoveImg(false)
                return
            }
        }
    }

    const handleImageChange = (e) => {
        let file = e?.target?.files[0]
        let imageSize = file && file?.size
        if (imageSize <= 2097152) {
            if (file) {
                const reader = new FileReader()
                reader.onload = () => {
                    if (reader.readyState === 2) {
                        setProfileImage(reader.result)
                        setImageBinaryData(Object.assign(file, { url: URL.createObjectURL(file) }))
                    }
                }
                reader && reader.readAsDataURL(e?.target?.files[0])
                setRemoveImg(true)
                setDataChange(true)
            }

        } else {
            props.toast.error("Image maximum size is 2 MB")
        }
    }

    const handleRemoveImage = () => {
        setProfileImage("")
        setImage("")
        setImageBinaryData("")
        setDataChange(true)
    }

    const imageLoadingFun = () => {
        setImageLoad(false)
    }
    return (
        <div className="settings-page">
            <div className="setting-top">
                <Grid container >
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                        <h6 className="page-heading"> Settings </h6>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} className="text-right settings-btn">
                        <Button onClick={handleEditClick} disabled={isLoading}>{isEdit ? 'Cancel' : 'Edit'}</Button>
                    </Grid>
                </Grid>
            </div>
            {!isLoading ?
                <div className="settings-fields">
                    <Grid container >
                        <Grid item xs={12} sm={12} md={2} lg={2} >
                            <div className="profile-sec">
                                {profileImage && isEdit || image && isEdit ? <CustomTooltip title="Remove Image" > <img className="remove-img" src={CrossIcon} onClick={handleRemoveImage} alt="" /> </CustomTooltip> : ''}
                                {
                                    profileImage ?
                                        <span><img src={profileImage} className={image.length ? 'profile-img' : 'default-profile-img'} alt="" /> </span>
                                        :
                                        <span className={image.length ? 'profile-img' : 'default-profile-img'}>
                                            {imageLoad ? <div><img src={loader} alt="" /></div> : <img src={image.length ? image : userImage} alt="" />}
                                        </span>
                                }
                            </div>
                            <img src={image.length ? image : userImage} onLoad={imageLoadingFun} style={{ display: 'none' }} alt="" /> {/*This is use for check image is load or not this not showing on UI*/}
                            <div className="profile-text">
                                {isEdit ?
                                    <label for='profile-img' >
                                        <p>Change picture</p>
                                    </label>
                                    :
                                    ''
                                }
                                <input type="file" accept="image/png, image/jpeg" id='profile-img' onChange={(e) => handleImageChange(e)} hidden />
                            </div>
                        </Grid>

                        <Grid item xs={12} sm={12} md={10} lg={10} >
                            <form onSubmit={handleInfoSubmit}>
                                <p>
                                    <span>Name</span>
                                    {isEdit ?
                                        <InputField value={infoDetails.name} name="name" error={errors.name} autoFocus onChange={handleInfoChange} />
                                        :
                                        <label> {username || '-'} </label>
                                    }
                                </p>

                                <p>
                                    <span>Email</span>
                                    {isEdit ?
                                        <InputField value={infoDetails.email} name="email" error={errors.email} onChange={handleInfoChange} />
                                        :
                                        <label> {email || '-'} </label>
                                    }
                                </p>

                                <p>
                                    <span>Mobile No.</span>
                                    {isEdit ?
                                        <InputField value={infoDetails.mobNo} name="mobNo" error={errors.mobNo} onChange={handleInfoChange} />
                                        :
                                        <label> {contact_no || '-'} </label>
                                    }
                                </p>

                                <p>
                                    <span>Password</span>
                                    <label className="password_hide"> *********** </label>
                                    {isEdit ? <Button className="button-btn change-btn new-btn-color" onClick={passworsCancel} > {passwordChange ? 'Cancel' : 'Change'} </Button> : ''}
                                </p>

                                {passwordChange ?
                                    <div>
                                        <p>
                                            <span>Current Password</span>
                                            <InputField onPaste={(e) => {
                                                e.preventDefault()
                                                return false;
                                            }} onCopy={(e) => {
                                                e.preventDefault()
                                                return false;
                                            }}
                                                value={infoDetails.currentPassword} className="new-input" name="currentPassword" error={passwordErr.currentPassword} type="password" onChange={handlePasswordChange} />
                                        </p>
                                        <p>
                                            <span>New Password</span>
                                            <InputField
                                                onPaste={(e) => {
                                                    e.preventDefault()
                                                    return false;
                                                }} onCopy={(e) => {
                                                    e.preventDefault()
                                                    return false;
                                                }}
                                                value={infoDetails.newPassword} className="new_password" name="newPassword" type="password" error={passwordErr.newPassword} onChange={handlePasswordChange} />
                                        </p>
                                        <p>
                                            <span>Confirm Password</span>
                                            <InputField
                                                onPaste={(e) => {
                                                    e.preventDefault()
                                                    return false;
                                                }} onCopy={(e) => {
                                                    e.preventDefault()
                                                    return false;
                                                }}
                                                value={infoDetails.confirmPassword} className="confirm_password" name="confirmPassword" type="password" error={passwordErr.confirmPassword} onChange={handlePasswordChange} />
                                        </p>
                                    </div>
                                    :
                                    ''
                                }
                            </form>

                            {isEdit ? <Button className="button-btn  new-btn-color" onClick={passwordChange ? handleWithPassword : handleInfoSubmit} >{isLoading ? <CircularProgress /> : 'Update'} </Button> : ''}
                        </Grid>
                    </Grid>
                </div>
                :
                <div className="table-loader">
                    <img src={loader} />
                </div>
            }
        </div>
    )
}