import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CustomDialogBox from '../../../components/common/CustomDialogBox'
import CustomModal from '../../../components/common/CustomModal'
import AddQuestionForm from './addQuestionForm'
import * as action from '../actions'
import * as questionCategory from './questionAction'
import { Button, Grid, Switch } from '@material-ui/core'
import editIcon from '../../../assets/images/editIcon.svg';
import deleteIcon from '../../../assets/images/deleteIcon.svg'
import loader from '../../../assets/images/loader.gif'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import InputField from "../../../components/common/InputField"
import NoDataFound from '../../../components/common/NoDataFound'
import ImgsViewer from 'react-images-viewer'
import CustomToolTip from '../../../components/common/ToolTip'
import Description from '../../../components/common/Description'
import UserLoader from "../../../assets/images/userLoader.gif";


function SingleGift(props) {
    const { toast } = props

    const [addQuestionModal, setAddQuestionModal] = useState(false)
    const [deleteQuestionModal, setDeleteQuestionModal] = useState(false)
    const [questionId, setQuestionId] = useState(null)
    const [questionDetails, setQuestionDetails] = useState({})
    const [isEdit, setIsEdit] = useState(false)
    const [search, setSearch] = useState('')
    const [viewerIsOpen, setViewerIsOpen] = useState(false)
    const [imageToOpen, setImageToOpen] = useState(null)
    const [giftName, setGiftName] = useState('')

    let giftId = props.match.params.giftId
    const dispatch = useDispatch()
    const { categoryById = [], isLoading = false } = useSelector(state => state.category) || {}
    const { questions = {}, isLoadingQus = false } = useSelector(state => state.question) || {}

    const { data } = questions || {}
    const { Count = "", Items = [] } = data || {}
    const { categoryId } = props.match.params;

    useEffect(() => {
        dispatch(action.GetCategoryById(giftId)).then(res => setGiftName(res))
        dispatch(questionCategory.GetQuestion(props?.match?.params?.giftId))
    }, [])


    const handleQuestionModal = () => {
        setAddQuestionModal(true)
    }

    const handleCloseAddQuestionModal = () => {
        setAddQuestionModal(false)
        setIsEdit(false)
        // setQuestionDetails({})
    }

    const handleDeleteQues = (id) => {
        setDeleteQuestionModal(true)
        setQuestionId(id)
    }

    const handleEditQues = (e, ques) => {
        const { id = "" } = ques
        setQuestionDetails(ques)
        setQuestionId(id)
        setIsEdit(true)
        setAddQuestionModal(true)
    }

    const onQuestionDelete = () => {
        const { categoryId } = props.match.params;
        dispatch(questionCategory.DeleteQuestion(questionId))
            .then(res => {
                toast.success("Question deleted successfully");
                dispatch(action.GetCategoryById(categoryId))
                dispatch(questionCategory.GetQuestion(props?.match?.params?.giftId))
                setDeleteQuestionModal(false)
                afterAction()
            })
            .catch(err => {
                toast.error(err.message || 'Oops! Something went wrong')
            })
    }
    console.log(props, 'props')
    const handleChange = (e) => {
        const { name = '', value = "" } = e.target
        setSearch(value)
        if (value.length > 0) dispatch(questionCategory.GetQuestionByFilter({ term: value, gift_id: props?.match?.params?.giftId }));
        if (value.length === 0) dispatch(questionCategory.GetQuestion(props?.match?.params?.giftId))
    }

    const closeQuestionDeleteModal = () => {
        setDeleteQuestionModal(false)
        setQuestionId(null)
    }


    const viewerOpen = (currImg) => {
        setImageToOpen(currImg)
        setViewerIsOpen(true)
    }

    const closeViewer = () => {
        setViewerIsOpen(false)
        setImageToOpen(null)
    }

    const afterAction = () => {
        setSearch('')
        setQuestionId(null)
    }

    const onUpdateStatus = (e, question) => {
        const { categoryId } = props.match.params;
        const { id = "" } = question
        let is_active = question?.is_active && JSON.parse(question?.is_active)

        is_active = { is_active: (!JSON.parse(is_active)).toString() }
        const isActiveStatus = question?.is_active && JSON.parse(question?.is_active)

        dispatch(questionCategory.UpdateQuestionStatus(id, is_active))
            .then(() => {
                dispatch(action.GetCategoryById(categoryId))
                dispatch(questionCategory.GetQuestion(props?.match?.params?.giftId))
                toast.success(`Question has been ${isActiveStatus ? 'Deactivated' : 'Activated'} successfully!`)
                afterAction()
            })
            .catch(({ message = '' }) => {
                toast.error(message || 'Oops! Something went wrong')
            })
    }


    const loading = !isLoading && !isLoadingQus || false
    return (
        <React.Fragment>
            <div className="categories-question">
                <Grid container spacing={3} className="mb-3 heading-sec d-flex align-items-center justify-content-end" >
                    <Grid item xs={12} sm={12} md={3} lg={3} className="align-self-center heading-top">
                        <h5 className="page-heading">
                            <KeyboardBackspaceIcon onClick={() => props.history.goBack()} />
                            <span className="page-heading" >{isLoading ? <img src={UserLoader} alt="" style={{ width: '100px' }} /> : Array.isArray(giftName) ? giftName.map(item => item.giftCategoryName) : ''} </span>
                        </h5>
                    </Grid>
                    <Grid item xs={12} sm={12} md={9} lg={9} className="search-bar">
                        <div className="right-search-btn">
                            <div className="search-inner">
                                <InputField
                                    type="search"
                                    value={search}
                                    name={search}
                                    label="Search Question"
                                    placeholder="Search question"
                                    onChange={(e) => handleChange(e)}
                                    fullWidth
                                    inputProps={{ maxlength: 40 }}
                                />
                            </div>
                            <div className="text-right">
                                <Button onClick={handleQuestionModal} className="button-btn cat-button new-btn-color" disabled={isLoading} > Add Question</Button>
                            </div>
                        </div>
                    </Grid>

                </Grid>
                <div className="questions-bottom">
                    {loading ?
                        <div>
                            {Items && Items.length ? <p className="question-text">Total Questions : {Count || '0'}</p> : ''}
                            <ol>
                                {
                                    Items && Items.length ?
                                        Items.map((ques, parentIndex) => {
                                            let { options = [], question = '', id = '', correct_options = [], description = "", is_active } = ques
                                            return <div key={id} className="question-div" >
                                                <li> {question || '-'}</li>
                                                <ol >
                                                    {
                                                        options && options.length && options.map((data, childIndex) => {
                                                            return <React.Fragment key={childIndex}>
                                                                <li>
                                                                    {data && data?.value || ''}
                                                                    {data?.image ? <img src={data?.image} onClick={() => viewerOpen(data?.image)} alt="" /> : null}
                                                                </li>
                                                            </React.Fragment>
                                                        })
                                                    }
                                                </ol>
                                                <div className="correct-answer">
                                                    <span className="correct-text">{` Correct ${correct_options.length <= 1 ? 'Answer' : 'Answers'}: `}</span>
                                                    <ul type="circle">
                                                        {
                                                            correct_options && correct_options.length && correct_options.map((data, childIndex) => {
                                                                const { value = "", option_id = "", image = "" } = data
                                                                return <React.Fragment key={childIndex}>
                                                                    <li>
                                                                        {`${option_id}. ${value || ''}`}
                                                                        {image ? <img src={image} onClick={() => viewerOpen(image)} alt="" /> : null}
                                                                    </li>
                                                                </React.Fragment>
                                                            })
                                                        }
                                                    </ul>

                                                    <div className="action-btn" >
                                                        <Switch checked={is_active && JSON.parse(is_active)} onChange={(e) => onUpdateStatus(e, ques)} />
                                                        <CustomToolTip title="Edit" > <span className="edit-icon mr-1" onClick={(e) => handleEditQues(e, ques)}> <img src={editIcon} alt="" /></span></CustomToolTip>
                                                        <CustomToolTip title="Delete" > <span className="delete-icon mr-2" onClick={() => handleDeleteQues(ques.id)} ><img src={deleteIcon} alt="" /></span></CustomToolTip>
                                                    </div>
                                                </div>
                                                {description ? <Description data={description || ''} /> : ''}
                                            </div>
                                        })
                                        : <NoDataFound />
                                }
                            </ol>
                        </div>
                        :
                        <div className="table-loader">
                            <img src={loader} alt="" />
                        </div>
                    }
                </div>
            </div>

            <CustomDialogBox
                open={deleteQuestionModal}
                handleClose={closeQuestionDeleteModal}
                title="Warning"
                dialogtext={`Are you sure you want to delete this question?`}
                confirmAction={onQuestionDelete}
                isLoading={isLoadingQus}
                text="Keep Question"
            />

            <CustomModal open={addQuestionModal} maxWidth="xl" >
                <AddQuestionForm
                    {...props}
                    onClose={handleCloseAddQuestionModal}
                    toast={toast}
                    questionDetails={questionDetails}
                    update={isEdit}
                    afterAction={afterAction}
                />
            </CustomModal>

            <ImgsViewer
                imgs={[{ src: imageToOpen }]}
                isOpen={viewerIsOpen}
                onClose={closeViewer}
                closeBtnTitle={'Close'}
                showImgCount={false}
                backdropCloseable={true}
            />
        </React.Fragment>
    )
}

export default SingleGift