import React, { useEffect, useReducer, useState } from 'react'
import InputField from '../../components/common/InputField'
import { Button, CircularProgress, Grid, FormControl, InputLabel, Select, MenuItem, TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete';
import CustomToolTip from '../../components/common/ToolTip'
import deleteIcon from '../../assets/images/deleteIcon.svg'
import CachedIcon from '@material-ui/icons/Cached';
import cameraIcon from '../../assets/images/camera.svg'
import CrossIcon from '../../assets/images/cross.svg'
import CrossIconWhite from '../../assets/images/cross-white.svg'
import validateWinnerTable from '../Validation/winnerTableValidation'
import { useDispatch, useSelector } from 'react-redux'
import * as action from './actions'
import * as channelAction from '../Top-12-Winners/action'
import moment from 'moment';
import Compress from "browser-image-compression";

const initialState = {
    imageSizeLimit: '',
    imgCheckLoad: false,
    imageSizeLimitError: '',
}
function AddWinnerForm(props) {
    const dispatch = useDispatch()

    const [episodes, setEpisode] = useState('')
    const [winnerForm, setWinnerForm] = useState({ ...initialState })
    const [imageBinaryData, setImageBinaryData] = useState('')
    const [image, setImage] = useState('')
    const [errors, setError] = useState({})
    const [dataChange, setDataChange] = useState(false)
    const [removeImg, setRemoveImg] = useState(false)
    const [channel, setChannel] = useState('')
    const [episodeId, setEpisodeId] = useState('')

    // const { topWinner = {}, isLoading = false, channels = {}, episode = {} } = useSelector(state => state.topWinner) || {}

    const { isLoading = false } = useSelector(state => state.tableWinner)
    const { channels = {}, episode = {}, isLoading: Loading = false } = useSelector(state => state.topWinner) || {}
    const { data: channelList = [], total } = channels || {}
    const { episodeList = {} } = episode
    const { Items: allEpisodes = [] } = episodeList || {}

    useEffect(() => {
        dispatch(channelAction.GetChannels())
        const { tableWinnerDetails = {}, update } = props
        const { image = "", episode = "", channel = "" } = tableWinnerDetails
        if (update) {
            dispatch(channelAction.GetEpisodeByChannel(channel))
            setImage(image)
            setEpisode(Number(episode))
            setChannel(channel)
        }
    }, [])


    const handleEpisodeChange = (e) => {
        const { name, value } = e.target
        setEpisode(value)
        setError({ ...errors, episodes: '' })
        setDataChange(true)
    }

    const isValid = () => {
        const { isValid = true, errors = {} } = validateWinnerTable({ episodes, image, channel })
        setError(errors)
        return isValid
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        const { update, tableWinnerDetails = {}, toast, startDate, endDate } = props
        if (isValid()) {

            if (update) {
                const { id = "", image_key = "" } = tableWinnerDetails

                const fd = new FormData()

                fd.append("episode", episodes);
                imageBinaryData && fd.append("image", imageBinaryData);
                removeImg && fd.append("remove_image", image_key);
                fd.append("channel", channel);
                fd.append("episode_id", episodeId);

                // fd.append("remove_image", removeImg ? image_key : '');

                if (dataChange) {
                    dispatch(action.UpdateTableWinner(fd, id))
                        .then(({ res = "" }) => {
                            dispatch(action.GetTableWinnerFilter({ limit: 25, startDate: moment(startDate).startOf('day').valueOf(), endDate: moment(endDate).endOf('day').valueOf() }))
                            toast.success(res || "Table winner updated successfully");
                            props.onClose()
                            setDataChange(false)
                            setRemoveImg(false)
                            setEpisodeId('')
                            props.afterAction()
                        })
                        .catch(({ message = '' }) => {
                            toast.error(message || 'Oops! Something went wrong')
                        })
                    return
                } else {
                    setDataChange(false)
                    setRemoveImg(false)
                    props.onClose()
                    props.afterAction()
                    return
                }
            }

            const fd = new FormData()

            fd.append("episode", episodes);
            fd.append("image", imageBinaryData);
            fd.append("channel", channel);
            fd.append("episode_id", episodeId);

            dispatch(action.CreateTableWinner(fd))
                .then(({ res = "" }) => {
                    dispatch(action.GetTableWinnerFilter({ limit: 25, startDate: moment(startDate).startOf('day').valueOf(), endDate: moment(endDate).endOf('day').valueOf() }))
                    toast.success(res || "Table winner added successfully");
                    props.onClose()
                    setEpisodeId('')
                    props.afterAction()
                })
                .catch(({ message = "" }) => {
                    toast.error(message || 'Oops! Something went wrong')
                })
        }
    }

    const options = {
        maxSizeMB: 0.2,
        useWebWorker: true,
        fileType: 'image/png'
    }

    const handleImageChange = (e) => {
        setWinnerForm({ ...winnerForm, imgCheckLoad: true, imageSizeLimitError: '' })
        let file = e?.target?.files[0]
        if (!file) {
            setWinnerForm({...initialState})
            return};
        Compress(file, options).then(res => {
            setWinnerForm({ ...winnerForm, imageSizeLimit: res.size })
            let imageSize = file?.size;
            if (imageSize <=  2000000) {
                if (res) {
                    const reader = new FileReader()
                    reader.onload = () => {
                        if (reader.readyState === 2) {
                            setImage(reader.result)
                            setImageBinaryData(Object.assign(res, { url: URL.createObjectURL(res) }))
                            setDataChange(true)
                        }
                    }
                    reader && reader.readAsDataURL(res)
                    setWinnerForm({ ...winnerForm, imgCheckLoad: false,imageSizeLimitError: ""  })
                }
                setError({ ...errors, image: '' })
            } 
            if(imageSize > 2000000) {
                setWinnerForm({ ...winnerForm, imageSizeLimitError: "Image maximum size exceed 2 MB" })
                // props.toast.error("Image maximum size is 2 MB")
            }

        }).catch(err => {
            props.toast.error(err)
        })
    }

    const handleRemoveImage = () => {
        setImage('')
        setRemoveImg(true)
    }

    const replaceImage = () => {
        setRemoveImg(true)
    }

    const handleChannelChange = (e) => {
        const { name, value } = e.target
        setChannel(value)
        dispatch(channelAction.GetEpisodeByChannel(value))
        setError({ ...errors, channel: '' })
        setDataChange(true)
        setEpisode('')
    }

    return (
        <React.Fragment>
            <div className="cat-popup winner-modal">
                <h6 className="page-heading">{props.update ? 'Edit Winner Table' : 'Add Winner Table'}</h6>
                <form onSubmit={handleSubmit}>

                    <FormControl variant="outlined" className="channel-dropdown" fullWidth disabled={Loading} >
                        <InputLabel id="channel-name">Channel Name</InputLabel>
                        {Loading ? <div className="field-loader"> <CircularProgress color="primary" size={25} /> </div> : ''}
                        <Select
                            labelId="channel-name"
                            id="channel-name"
                            value={channel}
                            onChange={(e) => handleChannelChange(e)}
                            label="Channel Name"
                            name="channel"
                            error={errors.channel}
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            {
                                channelList.map(channel => {
                                    return <MenuItem value={channel?.title} >{channel?.title}</MenuItem>
                                })
                            }
                        </Select>
                    </FormControl>
                    <span className="cus-error">{errors?.channel}</span>

                    <div className="winner-form d-flex align-items-center mt-2">
                        <FormControl variant="outlined" className="channel-dropdown" fullWidth disabled={Loading} >
                            <InputLabel id="episode-name">Episode</InputLabel>
                            {Loading ? <div className="field-loader"> <CircularProgress color="primary" size={25} /> </div> : ''}
                            <Select
                                labelId="episode-name"
                                id="episode-name"
                                value={episodes}
                                onChange={(e) => handleEpisodeChange(e)}
                                label="Episode"
                                name="episode"
                                placeholder="Please enter episode name"
                                error={errors.episode}
                                disabled={!channel.length}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {
                                    allEpisodes && allEpisodes.map(episode => {
                                        return <MenuItem value={episode?.episode} onClick={() => setEpisodeId(episode.id)} >{episode?.episode}</MenuItem>
                                    })
                                }
                            </Select>
                        </FormControl>

                        <div className={image ? "img-grid2" : "img-grid"} >
                            <img src={image} alt="" id="img" className={image ? "img-added" : "img-browse"} />
                            <div className="camera" style={{ display: 'inline-block' }}>
                                <label htmlFor="option-img" >
                                    {image ?
                                        <CustomToolTip title="Replace Image"><span className="replace-img" onClick={replaceImage} > <CachedIcon /> </span></CustomToolTip>
                                        :
                                        <CustomToolTip title="Add Image">   <span className="add-img">  <img src={cameraIcon} alt="" /></span></CustomToolTip>
                                    }
                                </label>
                                <input type="file" accept="image/jpeg, image/png" id="option-img" onChange={(e) => handleImageChange(e)} hidden />
                            </div>
                        </div>
                        {image ? <CustomToolTip title="Remove Image" > <img className="remove-img" src={CrossIconWhite} alt="" onClick={() => handleRemoveImage()} /> </CustomToolTip> : ''}
                        {/* <p style={{ color: 'red' }}>{errors?.image}</p> */}
                    </div>
                    {winnerForm?.imageSizeLimitError &&
                        <div className='d-flex justify-content-end text-danger'>
                            <p>{winnerForm?.imageSizeLimitError}
                            </p>
                        </div>}

                        {winnerForm?.imgCheckLoad &&
                        <div className='d-flex justify-content-end text-primary'>
                    <p>Image uploading...</p>
                        </div>}
                </form>
                <Grid container>
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                        <p className="cus-error">{errors?.episode}</p>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                        <p className="cus-error" style={{ textAlign: 'end' }} >{errors?.image}</p>
                    </Grid>
                </Grid>
                <p className="img-msg my-1">Image maximum size is 2 MB</p>

                <div className="cust-buttons mt-4" >
                    <Button onClick={() => props.onClose()} className="button-btn new-btn-color mr-2" >Cancel</Button>
                    <Button onClick={handleSubmit} className='button-btn secondary-btn ml-2' disabled={isLoading} >
                        {isLoading ? <CircularProgress size="28px" color="white" /> : (props.update ? 'Update' : 'Add')}
                    </Button>
                </div>
            </div>
        </React.Fragment>
    )
}

export default AddWinnerForm