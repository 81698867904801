import {
  
GET_HOST_LIST_REQUEST,
GET_HOST_LIST_REQUEST_SUCCEEDED,
GET_HOST_LIST_REQUEST_FAILED,

DELETE_HOST,
DELETE_HOST_SUCCESS, 
DELETE_HOST_FAILED,

CREATE_HOST,
CREATE_HOST_SUCCESS, 
CREATE_HOST_FAILED,

UPDATE_HOST,
UPDATE_HOST_SUCCESS,
UPDATE_HOST_FAILED,

} from './actions'

const initState = {
  isLoading: false,
  host : {},
}

export default function (state = { ...initState }, action) {
  switch (action.type) {
    case GET_HOST_LIST_REQUEST : 
      return {...state , isLoading : true}
    
    case GET_HOST_LIST_REQUEST_SUCCEEDED : 
      return {...state , isLoading : false , host : action.payload || {} }

    case GET_HOST_LIST_REQUEST_FAILED : 
      return {...state , isLoading : false , host : {}}


    case DELETE_HOST : 
      return {...state, isLoading : true}
    
    case DELETE_HOST_SUCCESS : 
      return {...state, isLoading : false}

    case DELETE_HOST_FAILED : 
      return {...state, isLoading : false}
    

    case CREATE_HOST : 
      return {...state , isLoading : true}
    
    case CREATE_HOST_SUCCESS : 
      return {...state, isLoading : false}
    
    case CREATE_HOST_FAILED : 
      return {...state, isLoading : false}


    case UPDATE_HOST : 
      return {...state, isLoading : true}
    
    case UPDATE_HOST_SUCCESS : 
      return {...state, isLoading : false}

    case UPDATE_HOST_FAILED : 
      return {...state, isLoading : false}
    
    default:
      return state
  }
}