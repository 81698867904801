import { apiPost, apiGet, apiDelete, apiPut } from '../../utils/axios'


export function getGiftCategoriesAPI() {
  return apiGet('/gifts?limit=12')
}


export function CreateGiftCategoryAPI(data) {
  return apiPost('/gifts', data)
}

export function deleteCategoryAPI(data) {
  return apiDelete(`/gifts/${data}`)
}

export function getCategoryByIdAPI(data) {
  return apiGet(`/gifts/${data}`)
}

export function updateCategoryAPI(data, id) {
  return apiPut(`/gifts/${id}`, data)
}

export function searchCategoryByFilterAPI(data) {
  let payload = {
    term: data && data.term || '',
    type: data && data.type || ''
  }
  return apiGet(`/gifts`, payload)
}