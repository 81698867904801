import React, { useEffect, useState, useCallback } from "react"
import {
    Grid,
    TableContainer,
    Table,
    TableRow,
    TableCell,
    TableBody,
    withStyles,
    Button,
    Box,
    MenuItem,
    TextField,
    InputAdornment,
    IconButton
} from '@material-ui/core'
import { withTranslation } from "react-i18next"
import EnhancedTableHead from '../../components/common/EnhancedTableHead'
import CustomTablePagination from '../../components/common/CustomPagination';
import InputField from "../../components/common/InputField"
import { useDispatch, useSelector } from "react-redux"
import * as action from './actions'
import 'react-dates/initialize';
import "react-dates/lib/css/_datepicker.css";
import CustomDialogBox from "../../components/common/CustomDialogBox"
import { stableSort, getComparator, tablestyle, getTimeStamps, } from "../../utils"
// import { dateFilter } from '../../constant'
import { dateFilter, isPhoneNumber } from '../../constant'
import deleteIcon from '../../assets/images/deleteIcon.svg'
import loader from '../../assets/images/loader.gif'
import AddWinnerForm from "./winnerForm";
import EventIcon from '@material-ui/icons/Event'
import moment from 'moment'
import CustomSelect from '../../components/common/CustomSelect'
import CustomToolTip from "../../components/common/ToolTip";
import UserInfo from "../../components/common/UserInfoHeader";
import NoDataFound from "../../components/common/NoDataFound";
import CustomModal from "../../components/common/CustomModal";
import editIcon from '../../assets/images/editIcon.svg';
import ImgsViewer from 'react-images-viewer'
import Datepicker from "../../components/common/Datepicker";
import ClearIcon from '@material-ui/icons/Clear';
import _ from "lodash";


const headCells = [
    { id: "index", numeric: false, disablePadding: false, label: "S.No." },
    { id: "channel", numeric: false, disablePadding: false, label: "Channel" },
    { id: "episode", numeric: false, disablePadding: false, label: "Episode" },
    { id: "Image", numeric: false, disablePadding: false, label: "Image" },
    { id: "a", numeric: false, disablePadding: false, label: "Action" },
];

function User(props) {
    const { toast } = props
    const dispatch = useDispatch()
    const [search, setSearch] = useState('')
    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const [winnerId, setWinnerId] = useState(null)
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('')
    const [rowsPerPage, setRowsPerPage] = useState(25)
    const [currentPage, setCurrentPage] = useState(0)
    const [filter, setFilter] = useState({ label: 'All', value: 'all' })
    const [startDate, setStartDate] = useState("")
    const [endDate, setEndDate] = useState("")
    const [focusedInput, setFocusedInput] = useState(false)
    const [timeOut, setTimeOut] = useState(null)
    // const [winnerFilterSelect, setWinnerFilterSelect] = useState("")
    const [openTableWinnerModal, setOpenTableWinnerModal] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [tableWinnerDetails, setTableWinnerDetails] = useState({})
    const [viewerIsOpen, setViewerIsOpen] = useState(false)
    const [imageToOpen, setImageToOpen] = useState(null)
    const [caption, setCaption] = useState('')
    const [tablleWinnerFilter, setTablleWinnerFilter] = useState("")


    const { winnerTable = {}, isLoading = false } = useSelector(state => state.tableWinner) || {}
    const { data = [], total = "", current_page = "" } = winnerTable || {}

    // useEffect(() => {
    //     dispatch(action.GetTableWinner({limit : rowsPerPage,startDate,endDate,}))
    // }, [])

    useEffect(() => {
        if (!startDate || !endDate) return;
        getData()
    }, [startDate, endDate])

    useEffect(() => {
        if (filter?.value) {
            const { startDate: newStartDate, endDate: newEndDate } = getTimeStamps(filter.value, startDate, endDate)
            if (filter.value !== 'customDate') {
                setEndDate(moment(newEndDate))
                setStartDate(moment(newStartDate))
            }
        }
    }, [filter])

    const startDateValue = moment(startDate).startOf('day').valueOf()
    const endDateValue = moment(endDate).endOf('day').valueOf()

    function getData() {
        const data = {
            startDate: startDateValue,
            endDate: endDateValue,
        }
        dispatch(action.GetTableWinnerFilter({ key: tablleWinnerFilter, startDate: data?.startDate, endDate: data?.endDate, limit: rowsPerPage, start: currentPage, term: search }))
    }

    function handleDateChange({ startDate, endDate }) {
        if (startDate) setStartDate(startDate)
        if (endDate) setEndDate(endDate)
    }

    const handleChange = (e) => {

        const { name = "", value = "" } = e.target;
        if (tablleWinnerFilter === "episode" && value) {
            if (!isPhoneNumber.test(value)) return
        }

        if (timeOut) {
            clearTimeout(timeOut);
        }
        setSearch(value)
        searchDebounce(value, tablleWinnerFilter);

        // setTimeOut(setTimeOut(() => {
        //     if (value && value.trim()) {
        //         searchUser(value)
        //     } else {
        //         dispatch(action.GetTableWinner())
        //     }
        // }, 700))
        props.history.replace(`/winners?page=${0}&limit=${5}`)
    }



    const searchDebounce = useCallback(
        _.debounce((value, tablleWinnerFilter) => {
            if (value.length != 0) {
                if (value && value.trim())
                    dispatch(action.GetTableWinnerFilter({ key: tablleWinnerFilter, startDate: startDateValue, endDate: endDateValue, limit: rowsPerPage, start: currentPage, term: value, type: tablleWinnerFilter }));
                setCurrentPage(0)
            } else {
                dispatch(action.GetTableWinnerFilter({ key: tablleWinnerFilter, startDate: data?.startDate, endDate: data?.endDate, limit: rowsPerPage, start: currentPage, term: search }))
            }
        }, 700),
        []
    );


    const searchUser = (value) => {
        // if (value.length != 1) {
        //     dispatch(action.GetTableWinnerFilter({ startDate,endDate, limit: rowsPerPage, start: currentPage, term: value }));
        // }
        dispatch(action.GetTableWinnerFilter({ startDate: startDateValue, endDate: endDateValue, limit: rowsPerPage, start: currentPage, term: value, type: tablleWinnerFilter }));

    }

    const deleteModal = (id) => {
        setWinnerId(id)
        setOpenDeleteModal(true)
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc')
        setOrderBy(property)
    }

    const handleChangePage = (event, currentPage, pageLimit) => {
        setCurrentPage(currentPage)
        dispatch(action.GetTableWinnerFilter({ key: tablleWinnerFilter, startDate: startDateValue, endDate: endDateValue, limit: rowsPerPage, page: currentPage + 1, term: search }));
        props.history.replace(`/winners?page=${currentPage}&limit=${rowsPerPage}`)
    }

    const handleChangeRowsPerPage = (rowsPerPage) => {
        let { value = 10 } = rowsPerPage.target;
        value = value === "All" ? props.customer.length : value
        setRowsPerPage(value)
        setCurrentPage(0)
        dispatch(action.GetTableWinnerFilter({ key: tablleWinnerFilter, startDate: startDateValue, endDate: endDateValue, limit: value, start: currentPage, term: search }))
        props.history.replace(`/winners?page=${currentPage}&limit=${value}`)
    }

    const handleAddModal = () => {
        setOpenTableWinnerModal(true)
    }

    const closeWinnerModal = () => {
        setOpenTableWinnerModal(false)
        setIsEdit(false)
    }

    const handleItemType = ({ target: { value } }) => {
        setTablleWinnerFilter(value)
        setSearch('')
        if (search !== '') (
            dispatch(action.GetTableWinner())
        )
    }

    const editModal = (e, item) => {
        const { id = '' } = item
        setWinnerId(id)
        setOpenTableWinnerModal(true)
        setTableWinnerDetails(item)
        setIsEdit(true)
    }

    const deleteUser = () => {
        dispatch(action.DeleteTableWiner(winnerId))
            .then(res => {
                dispatch(action.GetTableWinnerFilter({ startDate: data?.startDate, endDate: data?.endDate, limit: rowsPerPage, start: currentPage, term: search }))
                toast.success("Table of winner has been deleted successfully")
                setOpenDeleteModal(false)
                afterAction()
            })
            .catch(({ message = '' }) => {
                toast.error(message || 'Oops! Something went wrong')
            })
    }

    const viewerOpen = (currImg, caption) => {
        setImageToOpen(currImg)
        setViewerIsOpen(true)
        setCaption(caption)
    }

    const closeViewer = () => {
        setViewerIsOpen(false)
        setImageToOpen(null)
        setCaption('')
    }

    const winnerSelect = [
        { id: 1, value: 'channel', label: 'Channel' },
        { id: 2, value: 'episode', label: 'Episode' },
    ]

    const afterAction = () => {
        setSearch('')
        setWinnerId(null)
    }

    const clearSearch = () => {
        setSearch('')
        dispatch(action.GetTableWinnerFilter())
    }

    let labelData = winnerSelect.find(item => item.value === tablleWinnerFilter);

    return (
        <div className="user-page winner">
            <Grid container spacing={3} className="mb-3 heading-sec">
                <Grid item xs={12} sm={12} md={12} lg={2} className="align-self-center">
                    <h5 className="page-heading" >Table of Winners</h5>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={10} className="custom-date-field d-flex align-items-center justify-content-end">
                    <Box className="cust-formfields">
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={7} lg={7} >
                                {
                                    tablleWinnerFilter ?
                                        <InputField type="search" value={search} name={search} label={`Search By ${labelData && labelData.label || ''}`} inputProps={{ maxlength: 40 }}
                                            placeholder="Search Table of Winners" onChange={(e) => handleChange(e)} fullWidth
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    {search.length ? <IconButton aria-label="toggle password visibility" edge="end" onClick={() => clearSearch()}>
                                                        <ClearIcon />
                                                    </IconButton>
                                                        : null}
                                                </InputAdornment>
                                            } />
                                        :
                                        ''
                                }
                            </Grid>
                            <Grid item xs={12} sm={12} md={5} lg={5} >
                                <TextField
                                    id="outlined-select-currency"
                                    select
                                    fullWidth
                                    label="Search By"
                                    variant="outlined"
                                    onChange={handleItemType}
                                    value={tablleWinnerFilter}
                                    className="form-group"
                                >
                                    {
                                        winnerSelect && winnerSelect.map(customer => {
                                            return <MenuItem key={customer.id} value={customer.value}>{customer.label} </MenuItem>
                                        })
                                    }

                                </TextField>
                            </Grid>
                        </Grid>

                        {/* <Grid item xs={12} sm={12} md={12} lg={12} className="search-bar" >
                            <InputField type="search" value={search} name={search} label='Search Table of Winners' inputProps={{ maxlength: 40 }}
                                placeholder="Search Table of Winners" onChange={(e) => handleChange(e)} fullWidth />
                        </Grid> */}
                    </Box>

                    <Box className="custom-box" display="flex" justifyContent="flex-end" alignItems="center">
                        <CustomSelect
                            minWidth={180}
                            className="cust-select"
                            options={dateFilter}
                            placeholder="Filter"
                            isSearchable
                            onChange={(opt) => {
                                setFilter(opt)
                                setCurrentPage(0)
                            }}
                            value={filter}
                        />
                        {
                            filter?.value !== "customDate" ?
                                ''
                                :
                                <div className="date-range-picker">
                                    <Datepicker
                                        startDate={startDate}
                                        endDate={endDate}
                                        focusedInput={focusedInput}
                                        setFocusedInput={setFocusedInput}
                                        handleDateChange={handleDateChange}
                                    />
                                </div>
                        }
                    </Box>
                    <Button onClick={handleAddModal} className="button-btn cat-button new-btn-color" disabled={isLoading} > Add Winner Table</Button>
                </Grid>

            </Grid>

            <div className="cust-table">
                {!isLoading ?
                    <div>
                        <TableContainer className={`${props.classes.container} cus-winners-inner`}>
                            <Table className="table-program" stickyHeader aria-label="sticky table" id="customer-table">
                                <EnhancedTableHead
                                    order={order}
                                    orderBy={orderBy}
                                    onRequestSort={handleRequestSort}
                                    headCells={headCells}
                                />
                                <TableBody>
                                    {data && data.length ?
                                        stableSort(data || [], getComparator(order, orderBy)).map((item, index) => {
                                            const { episode = "", image = "", channel = "" } = item
                                            return (
                                                <TableRow hover key={item.id} className="cursor_default"  >
                                                    <TableCell className="table-custom-width" data-title="S NO.">{rowsPerPage * ((currentPage + 1) - 1) + index + 1}. </TableCell>
                                                    <TableCell className="table-custom-width" data-title="channel">{channel || '-'} </TableCell>
                                                    <TableCell className="table-custom-width" data-title="episode">{episode || '-'} </TableCell>
                                                    <TableCell className="table-custom-width" data-title="USER NAME" >
                                                        {image ? <img className="popup-img" src={image} alt="" onClick={() => viewerOpen(image, episode)} /> : '-'}
                                                    </TableCell>
                                                    <TableCell className="table-custom-width" data-title="ACTION">
                                                        <CustomToolTip title="Edit" >
                                                            <span className="edit-icon mr-1" onClick={(e) => editModal(e, item)}>
                                                                <img src={editIcon} alt="" />
                                                            </span>
                                                        </CustomToolTip>
                                                        <CustomToolTip title="Delete" >
                                                            <span className="delete-icon" onClick={() => deleteModal(item.id)} >
                                                                <img src={deleteIcon} alt="" />
                                                            </span>
                                                        </CustomToolTip>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })
                                        :
                                        <TableRow>
                                            <TableCell colSpan={headCells.length + 1} className="text-center"><NoDataFound /></TableCell>
                                        </TableRow>
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <CustomTablePagination
                            count={total || 0}
                            rowsPerPage={rowsPerPage || 0}
                            currentPage={currentPage}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    </div>
                    :
                    <div className="table-loader">
                        <img src={loader} />
                    </div>
                }
            </div>

            <CustomDialogBox
                handleClose={() => setOpenDeleteModal(false)}
                confirmAction={deleteUser}
                open={openDeleteModal}
                title="Warning"
                dialogtext={`Are you sure you want to delete?`}
                isLoading={isLoading}
                text="Keep Episode"
            />

            <CustomModal open={openTableWinnerModal} >
                <AddWinnerForm
                    {...props}
                    onClose={closeWinnerModal}
                    toast={toast}
                    tableWinnerDetails={tableWinnerDetails}
                    update={isEdit}
                    afterAction={afterAction}
                    startDate={startDate}
                    endDate={endDate}
                />
            </CustomModal>

            <ImgsViewer
                imgs={[{ src: imageToOpen, caption }]}
                isOpen={viewerIsOpen}
                onClose={closeViewer}
                closeBtnTitle={'Close'}
                showImgCount={false}
                backdropCloseable={true}
            />

        </div>
    )
}

export default withTranslation("translations")(withStyles(tablestyle)(User));

