import {
  getGiftCategoriesAPI,
  CreateGiftCategoryAPI,
  getCategoryByIdAPI,
  deleteCategoryAPI,
  searchCategoryByFilterAPI,
  updateCategoryAPI
} from './apis'


export function GetGiftCategories(data) {
  return dispatch => {
    dispatch({
      type: 'GET_GIFT_CATEGORIES'
    })
    return new Promise((resolve, rej) => {
      getGiftCategoriesAPI(data).then(res => {
        dispatch({
          type: 'GET_GIFT_CATEGORIES_SUCCESS',
          payload: res
        })
        return resolve(res)
      })
        .catch(err => {
          dispatch({
            type: 'GET_GIFT_CATEGORIES_FAILED',
            payload: {}
          })
          return rej(err)
        })
    })
  }
}

export function GetCategoriesByFilter(data) {
  return dispatch => {
    dispatch({
      type: 'GET_GIFT_CATEGORIES'
    })
    return new Promise((resolve, rej) => {
      searchCategoryByFilterAPI(data).then(res => {
        dispatch({
          type: 'GET_GIFT_CATEGORIES_SUCCESS',
          payload: res
        })
        return resolve(res)
      })
        .catch(err => {
          dispatch({
            type: 'GET_GIFT_CATEGORIES_FAILED',
            payload: {}
          })
          return rej(err)
        })
    })
  }
}

export const GET_GIFT_CATEGORIES = 'GET_GIFT_CATEGORIES'
export const GET_GIFT_CATEGORIES_SUCCESS = 'GET_GIFT_CATEGORIES_SUCCESS'
export const GET_GIFT_CATEGORIES_FAILED = 'GET_GIFT_CATEGORIES_FAILED'

export function DeleteCategory(data) {
  return dispatch => {
    dispatch({
      type: 'DELETE_GIFT_CATEGORY'
    })
    return new Promise((resolve, rej) => {
      deleteCategoryAPI(data).then(res => {
        dispatch({
          type: 'DELETE_GIFT_CATEGORY_SUCCESS'
        })
        return resolve(res)
      })
        .catch(err => {
          dispatch({
            type: 'DELETE_GIFT_CATEGORY_FAILED'
          })
          return rej(err)
        })
    })
  }
}

export const DELETE_GIFT_CATEGORY = 'DELETE_GIFT_CATEGORY'
export const DELETE_GIFT_CATEGORY_SUCCESS = 'DELETE_GIFT_CATEGORY_SUCCESS'
export const DELETE_GIFT_CATEGORY_FAILED = 'DELETE_GIFT_CATEGORY_FAILED'

export function GetCategoryById(data) {
  return dispatch => {
    dispatch({
      type: 'GET_GIFT_CATEGORY_BY_ID'
    })
    return new Promise((resolve, rej) => {
      getCategoryByIdAPI(data).then(res => {
        dispatch({
          type: 'GET_GIFT_CATEGORY_BY_ID_SUCCESS',
          payload: res
        })
        return resolve(res)
      })
        .catch(err => {
          dispatch({
            type: 'GET_GIFT_CATEGORY_BY_ID_FAILED',
            payload: {}
          })
          return rej(err)
        })
    })
  }
}

export const GET_GIFT_CATEGORY_BY_ID = "GET_GIFT_CATEGORY_BY_ID"
export const GET_GIFT_CATEGORY_BY_ID_SUCCESS = "GET_GIFT_CATEGORY_BY_ID_SUCCESS"
export const GET_GIFT_CATEGORY_BY_ID_FAILED = "GET_GIFT_CATEGORY_BY_ID_FAILED"

export function CreateGiftCategory(data) {
  return dispatch => {
    dispatch({
      type: 'CREATE_GIFT_CATEGORY'
    })
    return new Promise((resolve, rej) => {
      CreateGiftCategoryAPI(data).then(res => {
        dispatch({
          type: 'CREATE_GIFT_CATEGORY_SUCCESS'
        })
        return resolve(res)
      })
        .catch(err => {
          dispatch({
            type: 'CREATE_GIFT_CATEGORY_FAILED'
          })
          return rej(err)
        })
    })
  }
}

export const CREATE_GIFT_CATEGORY = 'CREATE_GIFT_CATEGORY'
export const CREATE_GIFT_CATEGORY_SUCCESS = "CREATE_GIFT_CATEGORY_SUCCESS"
export const CREATE_GIFT_CATEGORY_FAILED = "CREATE_GIFT_CATEGORY_FAILED"

export function EditCategory(data, id) {
  return dispatch => {
    dispatch({
      type: 'EDIT_GIFT_CATEGORY'
    })
    return new Promise((resolve, rej) => {
      updateCategoryAPI(data, id).then(res => {
        dispatch({
          type: 'EDIT_GIFT_CATEGORY_SUCCESS'
        })
        return resolve(res)
      })
        .catch(err => {
          dispatch({
            type: 'EDIT_GIFT_CATEGORY_FAILED'
          })
          return rej(err)
        })
    })
  }
}

export const EDIT_GIFT_CATEGORY = "EDIT_GIFT_CATEGORY"
export const EDIT_GIFT_CATEGORY_SUCCESS = "EDIT_GIFT_CATEGORY_SUCCESS"
export const EDIT_GIFT_CATEGORY_FAILED = "EDIT_GIFT_CATEGORY_FAILED"