import Joi from "joi";

export default function juryCategoryValidation(data) {
    let isValid = true
    let errors = {}
    const schema = Joi.object({
        categoryName: Joi.string()
            .required()
            .messages({
                "string.empty": `Jury Category name is required!`
            }),

        categoryImage: Joi.string()
            .required()
            .messages({
                "string.empty": `Image is required!`
            }),

    }).validate(data, { abortEarly: false })

    const { error } = schema
    if (error) {
        isValid = false
        error.details.forEach(item => errors[item.context.key] = item.message)
    }
    return { isValid, errors }
}