
import { apiPost, apiGet, apiDelete, apiPut } from '../../utils/axios'

export function GetHostAPI(data) {
    let payload = {
        limit: data && data.limit || '',
        // page : data && data.page || ''
    }
    return apiGet('/admin/host', payload)
}

export function SearchHostByFilterAPI(data) {
    let term = data && data.term || ''
    let payload = {
        limit: data && data.limit || '',
        page: data && data.page || '',
        term: data && data.term || '',
        type: data && data.type || '',
        start_date: data && data.startDate || '',
        end_date: data && data.endDate || '',
        [data && data.key]: term || ''
    }
    return apiGet('/admin/host', payload)
}

export function UpdateHostAPI(data, id) {
    return apiPut(`/admin/host/${id}`, data)
}


export function DeleteHostAPI(id) {
    return apiDelete(`/admin/host/${id}`)
}

export function CreateHostAPI(data) {
    return apiPost('/admin/host', data)
}