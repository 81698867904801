import React, { useEffect, useState } from 'react'
import {
    InputLabel,
    TableContainer,
    Table,
    TableRow,
    TableCell,
    TableBody,
    FormControl,
    Button,
    Select,
    CircularProgress,
    Grid,
    MenuItem,
    withStyles,
    Checkbox,
    InputAdornment,
    IconButton
} from '@material-ui/core'
import EnhancedTableHead from '../../components/common/EnhancedTableHead'
import CustomTablePagination from '../../components/common/CustomPagination';
import InputField from "../../components/common/InputField"
import { useDispatch, useSelector } from "react-redux"
import * as action from './action'
import { stableSort, getComparator, tablestyle, getTimeStamps, } from "../../utils"
import loader from '../../assets/images/loader.gif'
import NoDataFound from "../../components/common/NoDataFound";
import UserInfo from "../../components/common/UserInfoHeader";
import { useLocation } from "react-router-dom";
import CustomDialogBox from '../../components/common/CustomDialogBox';
import CustomModal from '../../components/common/CustomModal';
import UserLiveForm from './userLiveForm';
import ClearIcon from '@material-ui/icons/Clear';
import FirstPage from '@material-ui/icons/FirstPage';


const headCells = [
    { id: "index", numeric: false, disablePadding: false, label: "S.No." },
    { id: "channel", numeric: false, disablePadding: false, label: "UserName" },
    { id: "question", numeric: false, disablePadding: false, label: "Question" },
    // { id: "episode", numeric: false, disablePadding: false, label: "Address" },
    // { id: "user_name", numeric: false, disablePadding: false, label: "Position" },
];

const initialState = {
    channel: '',
    episode: '',
    winning: ''
}

function QuestionList(props) {
    const { toast } = props

    const dispatch = useDispatch()

    const [topWinners, setTopWinner] = useState({ ...initialState })
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('')
    const [search, setSearch] = useState('')
    const [timeOut, setTimeOut] = useState(null)
    const [selected, setSelected] = useState([])
    const [userDetails, setUserDetails] = useState({})
    const [openUserLiveModal, setOpenUserLiveModal] = useState(false)
    const [removeLiveConfirmModal, setRemoveLiveConfirmModal] = useState(false)
    const [selectedChannelId, setSelectedChannelId] = useState('')


    const { questions = {}, isLoading = false, channels = {}, episode = {} } = useSelector(state => state.topQuestions) || {}
    const { data: questionsData = {} } = questions || {}
    // const topWinnerList = questionsData?.Items || []
    const topWinnerList = questionsData || []

    const { data: channelList = [] } = channels || {}

    const { episodeList = {}, channelData = {} } = episode

    const { Items: episodesList = [] } = episodeList || {}

    const paramData = useLocation().search;
    const channelName = new URLSearchParams(paramData).get('channel');


    useEffect(() => {
        const channel = new URLSearchParams(paramData).get('channel');
        const episode = new URLSearchParams(paramData).get('episode');
        const winning = new URLSearchParams(paramData).get('winning');
        setTopWinner({ ...topWinners, channel, episode, winning })
        dispatch(action.GetChannels());
        dispatch(action.GetEpisodeByChannel(channel))
        // dispatch(action.GetTopWinners(channel))
        return () => {
            dispatch({ type: "CLEAR_TOP_WINNERS_SUCCEEDED", payload: {} })
            dispatch({ type: "GET_TOP_QUESTIONS_WINNERS_SUCCEEDED", payload: {} })
        }
    }, [])

    useEffect(() => {
        const { channel = "", episode = "", winning="" } = topWinners
        channel && episode && dispatch(action.GetTopQuestions(episode,winning))
        channel && episode && props.history.push(`/question-winner?channel=${channel}&episode=${episode}&winning=${winning}`)

    }, [topWinners])

    const handleSelectChange = (e) => {
        const { name, value } = e.target
        setTopWinner({ ...topWinners, [name]: value })
        name == 'channel' && dispatch({ type: "CLEAR_TOP_WINNERS_SUCCEEDED", payload: {} })
        name == 'channel' && dispatch(action.GetEpisodeByChannel(value))
        name == 'channel' && setTopWinner({ ...topWinners, [name]: value, episode: '', winning:'' })
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc')
        setOrderBy(property)
    }


    const handleChange = (e) => {
        if (timeOut) {
            clearTimeout(timeOut);
        }
        const { name = "", value = "" } = e.target;
        setSearch(value)
        setTimeOut(setTimeOut(() => {
            searchUser(value)
        }, 700))
    }

    const searchUser = (value) => {
        // if (value.length != 1) {
        //     dispatch(action.SearchJuryByFilter({ limit: rowsPerPage, start: currentPage, term: value, type : juryResultFilterSelect }));
        // }
        // dispatch(action.SearchJuryByFilter({ limit: rowsPerPage, start: currentPage, term: value, type : juryResultFilterSelect }));
    }


    const valid = topWinners?.channel && topWinners?.episode || false

    // const handleSubmit = () => {
    //     const { channel = "", episode = "", winning="" } = topWinners
    //     props.history.push(`/question-winner?channel=${channel}&episode=${episode}&winning=${winning}`)
    //     dispatch(action.GetTopQuestions(episode))
    // }

    // const handleCheckBox = (e, item) => {
    //     const { id = "", is_live = "" } = item
    //     const { channel = "", episode = "" } = topWinners

    //     let newSelected = [...selected]
    //     let alreadyExist = newSelected.findIndex((val) => val === id)
    //     if (alreadyExist === -1) newSelected.push(id)
    //     else newSelected.splice(alreadyExist, 1)
    //     setSelected(newSelected)
    //     JSON.parse(is_live)
    //     dispatch(action.CreateTopWinners({ id, is_live: (!JSON.parse(is_live)).toString() }))
    //         .then(res => {
    //             props.toast.success('Top 12 Winner Updated Successfully!')
    //             dispatch(action.GetChannels());
    //             dispatch(action.GetEpisodeByChannel(channel))
    //             dispatch(action.GetTopWinners(episode))
    //         })
    //         .catch(({ err = "" }) => {
    //             props.toast.error(err || 'Something went wrong!')
    //         })
    // }

    const onSelectAllItems = (value) => {
        let selected = []
        if (value) {
            if (topWinnerList.length) {
                topWinnerList.map(item => selected.push(item.id))
            }
        } else selected = []

        setSelected(selected)
    }

    const handleCloseUserLiveModal = () => {
        setOpenUserLiveModal(false)
    }

    const handleUserLive = (item = {}) => {
        const { is_live = "" } = item || {}
        if (is_live == "true") {
            setRemoveLiveConfirmModal(true)
            setUserDetails(item)
        } else {
            setOpenUserLiveModal(true)
            setUserDetails(item)
        }
    }

    const removeUserLive = () => {
        const { id = "", is_live = false } = userDetails
        const { channel = "", episode = "" } = topWinners

        const removeLive = {
            // is_live: (!JSON.parse(is_live)).toString(),
            // episode_id: episode,
            // channel_id: channelData?.id
            id: id,
            remove_live: 'true',

        }
        dispatch(action.CreateTopWinners(removeLive))
            .then(res => {
                props.toast.success('User Updated Successfully!')
                dispatch(action.GetEpisodeByChannel(channel))
                dispatch(action.GetTopQuestions(episode))
                setRemoveLiveConfirmModal(false)
            })
            .catch(({ err = "" }) => {
                props.toast.error(err || 'Something went wrong!')
            })
    }

    const clearSearch = () => {
        setSearch('')
        // dispatch(action.SearchHostByFilter())
    }
    // const isSelected = (id) => selected.indexOf(id) !== -1;

    const isChecked = selected.length ? selected.length == topWinnerList.length : false


    return (
        <div className="top-winner-pg">
            <Grid container spacing={3} className="mb-3 heading-sec d-flex align-items-center justify-content-end" >
                <Grid item xs={12} sm={12} md={5} lg={3} className="align-self-center heading-top">
                    <h5 className="page-heading">
                        Questions Winners
                    </h5>
                </Grid>
                <Grid item xs={12} sm={12} md={7} lg={9} className="search-bar search-top-winners">
                    <div className="right-search-btn">
                        <div className="search-inner">
                            {/* <InputField type="search" value={search} name={search} label='Search Winner'
                                placeholder="Search Winner" onChange={(e) => handleChange(e)} fullWidth
                            // endAdornment={
                            //     <InputAdornment position="end">
                            //         {search.length ? <IconButton aria-label="toggle password visibility" edge="end" onClick={() => clearSearch()}>
                            //             <ClearIcon />
                            //         </IconButton>
                            //             : null}
                            //     </InputAdornment>
                            // }
                            /> */}
                        </div>
                    </div>
                </Grid>

            </Grid>
            <Grid container spacing={2} className="mt-4" >
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <FormControl variant="outlined" className="" fullWidth disabled={isLoading} >
                        <InputLabel id="channel">Channel Name</InputLabel>
                        {isLoading ? <div className="field-loader"> <CircularProgress color="primary" size={25} /> </div> : ''}
                        <Select
                            labelId="channel"
                            id="channel"
                            value={topWinners.channel}
                            onChange={(e) => handleSelectChange(e)}
                            label="Channel Name"
                            name="channel"
                        >
                            <MenuItem value="" disabled > <em>None</em> </MenuItem>
                            {
                                channelList?.map(channel => {
                                    return <MenuItem value={channel?.title} onClick={() => setSelectedChannelId(channel.id)}>{channel?.title}</MenuItem>
                                })
                            }
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <FormControl variant="outlined" className="" fullWidth disabled={isLoading}>
                        <InputLabel id="episode">Episode Name </InputLabel>
                        {isLoading ? <div className="field-loader"> <CircularProgress color="primary" size={25} /> </div> : ''}
                        <Select
                            labelId="episode"
                            id="episode"
                            value={topWinners.episode}
                            onChange={(e) => handleSelectChange(e)}
                            label="Episode Name"
                            name="episode"
                        >
                            <MenuItem value=""> <em>None</em> </MenuItem>
                            {
                                episodesList?.map(episode => {
                                    return <MenuItem value={episode?.id}>{episode?.episode}</MenuItem>
                                })
                            }
                        </Select>

                    </FormControl>
                </Grid>

                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <FormControl variant="outlined" className="" fullWidth disabled={isLoading}>
                        <InputLabel id="winning">Winning </InputLabel>
                        {isLoading ? <div className="field-loader"> <CircularProgress color="primary" size={25} /> </div> : ''}
                        <Select
                            labelId="winning"
                            id="winning"
                            value={topWinners.winning}
                            onChange={(e) => handleSelectChange(e)}
                            label="Winning"
                            name="winning"
                        >
                            <MenuItem value="global">Global</MenuItem>
                            <MenuItem value="live">Live</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>

                {/* <Grid item xs={12} sm={12} md={2} lg={2}>
                    <Button onClick={handleSubmit} className={`button-btn cat-button new-btn-color winner-submit-btn ${valid ? '' : 'disabled'} `} >Submit</Button>
                </Grid> */}

            </Grid>

            <div className="cust-table mt-5">
                {!isLoading ?
                    <div>
                        <TableContainer className={`${'props.classes.container'} mt-2`}>
                            <Table className="table-program" stickyHeader aria-label="sticky table" id="customer-table">
                                <EnhancedTableHead
                                    order={order}
                                    orderBy={orderBy}
                                    onRequestSort={handleRequestSort}
                                    headCells={headCells}
                                    onChangeClick={onSelectAllItems}
                                // isChecked={isChecked}
                                // showCheck
                                />
                                <TableBody>
                                    {topWinnerList && topWinnerList.length ?
                                        stableSort(topWinnerList || [], getComparator(order, orderBy)).map((item, index) => {
                                            const { first_answer = {}, question = "", user_email = "", phone = "", is_live = "", id = "",top12Users=[] } = item || {}
                                            // const { user_name = '' } = first_answer || {}                                      
                                            const filteredData = top12Users.find(item => item.global_rank === 1);
                                            const user_name = filteredData.user_name                        
                                            return (
                                                <TableRow hover key={index} role="checkbox" className="cursor_default" selected={""} >
                                                    <TableCell className="table-custom-width" data-title="S NO.">{index + 1}. </TableCell>
                                                    <TableCell className="table-custom-width" data-title="channel">{user_name || '-'} </TableCell>
                                                    <TableCell className="table-custom-width" data-title="question">{question || '-'} </TableCell>
                                                    {/* <TableCell className="table-custom-width" data-title="ADDRESS">{true ? <Checkbox /> : '-' }  </TableCell> */}
                                                    {/* <TableCell padding="checkbox" onClick={(e) => { e.stopPropagation() }} >
                                                        <div className="d-flex align-center justify-content-center">
                                                            <Checkbox checked={is_live && JSON.parse(is_live)} onClick={() => handleUserLive(item)} />
                                                        </div>
                                                    </TableCell> */}
                                                </TableRow>
                                            )
                                        })
                                        :
                                        <TableRow>
                                            <TableCell colSpan={headCells.length + 1} className="text-center">
                                                {valid ? <NoDataFound /> : <p className="top-12-winner-channel-select" >{`Please Select ${topWinners.channel ? '' : 'Channel &'}  Episode.`}</p>}
                                            </TableCell>
                                        </TableRow>
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                    :
                    <div className="table-loader">
                        <img src={loader} alt="" />
                    </div>
                }
            </div>
            <CustomModal open={openUserLiveModal} maxWidth="xl">
                <UserLiveForm
                    {...props}
                    onClose={handleCloseUserLiveModal}
                    toast={toast}
                    userDetails={userDetails}
                    selectedChannelId={selectedChannelId}
                    channelName={channelName}
                    channelData={channelData}
                // update={isEdit}
                // afterAction={afterAction}
                />
            </CustomModal>

            <CustomDialogBox
                handleClose={() => setRemoveLiveConfirmModal(false)}
                confirmAction={removeUserLive}
                open={removeLiveConfirmModal}
                title="Warning"
                dialogtext={`Are you sure you want to remove user from live?`}
                isLoading={isLoading}
                text="Keep User Live"
                removeLive="Remove Live"
            />
        </div>
    )
}

export default withStyles(tablestyle)(QuestionList);