import React, { useEffect, useState, useCallback } from "react"
import {
   Grid,
   Button,
   InputAdornment,
   IconButton
} from '@material-ui/core'
import _ from "lodash";
import CustomDialogBox from '../../components/common/CustomDialogBox'
import InputField from "../../components/common/InputField"
import { useDispatch, useSelector } from "react-redux"
import CustomModal from '../../components/common/CustomModal'
import AddCategoryForm from "./AddCategoryForm"
import * as action from './actions'
import loader from '../../assets/images/loader.gif'
import editIcon from '../../assets/images/editIcon.svg';
import deleteIcon from '../../assets/images/deleteIcon.svg'
import CustomToolTip from "../../components/common/ToolTip"
import NoDataFound from "../../components/common/NoDataFound"
import ClearIcon from '@material-ui/icons/Clear';
import { spaceRegex, alphabetsWithSpaceRegex } from "../../utils"


function Categories(props) {
   const { toast } = props
   const [search, setSearch] = useState('')
   const [addCategoryModal, setAddCategoryModal] = useState(false)
   const [categoryId, setCategoryId] = useState(null)
   const [categoryDetails, setCategoryDetails] = useState({})
   const [openDeleteModal, setOpenDeleteModal] = useState(false)
   const [isEdit, setIsEdit] = useState(false)
   const [hoverId, setHoverId] = useState(null)

   const dispatch = useDispatch()

   const { isLoading = false, giftCategories = {} } = useSelector(state => state.giftCategory) || {}

   const { categories = {} } = giftCategories || {}
   const { Count = '', Items = [] } = categories || {}


   const handleChange = (e) => {
      const { name = '', value = "" } = e.target
      const filterType = 'giftCategoryName';
      if (value.length && (!spaceRegex.test(value))) return;
      if (value.length && (!alphabetsWithSpaceRegex.test(value))) return;

      setSearch(value)
      searchDebounce(value, filterType);
   }

   const searchDebounce = useCallback(
      _.debounce((value, filterType) => {
         if (value.length != 0) {
            if (value && value.trim())
               dispatch(action.GetCategoriesByFilter({ term: value, type: filterType }));
         }
         if (value.length === 0) dispatch(action.GetGiftCategories())
      }, 1000), []
   );


   useEffect(() => {
      dispatch(action.GetGiftCategories())
   }, [])

   const openCategoryModal = () => {
      setAddCategoryModal(true)
      setSearch('')
   }

   const closeCategoryModal = () => {
      setIsEdit(false)
      setAddCategoryModal(false)
   }

   const onSingleCategory = (category) => {
      const { id = "" } = category
      props.history.push(`/gifts/${id}`)
   }

   const onDeleteButton = (id) => {
      setCategoryId(id)
      setOpenDeleteModal(true)
   }

   const onEditCategory = (e, category) => {
      const { id = "" } = category
      setCategoryId(id)
      setAddCategoryModal(true)
      setCategoryDetails(category)
      setIsEdit(true)
      setSearch('')
   }


   const onCategoryDelete = () => {
      dispatch(action.DeleteCategory(categoryId))
         .then(res => {
            toast.success("Category deleted successfully");
            dispatch(action.GetGiftCategories())
            setOpenDeleteModal(false)
            afterAction()
         })
         .catch(err => {
            toast.error(err.message || 'Oops! Something went wrong')
         })
   }

   const mouseEnter = (id) => {
      setHoverId(id)
   }

   const mouseLeave = (id) => {
      setHoverId(null)
   }

   const afterAction = () => {
      setSearch('')
      setCategoryId(null)
   }

   const clearSearch = () => {
      setSearch('')
      dispatch(action.GetGiftCategories())
   }
   return (
      <div className="category-page">
         <Grid container spacing={3} className="mb-3 heading-sec d-flex align-items-center justify-content-end" >
            <Grid item xs={12} sm={12} md={3} lg={3} className="align-self-center" >
               <h5 className="page-heading">Gift Categories</h5>
               {/* <p>Total Categories: {Count || ''} </p> */}
            </Grid>
            <Grid item xs={12} sm={12} md={9} lg={9} className="search-bar">
               <div className="right-search-btn">
                  <div className="search-inner">
                     <InputField
                        type="search"
                        value={search}
                        name={search}
                        label="Search Gift Category"
                        placeholder="Search category"
                        onChange={(e) => handleChange(e)}
                        inputProps={{ maxlength: 40 }}
                        endAdornment={
                           <InputAdornment position="end">
                              {search.length ?
                                 <IconButton
                                    aria-label="toggle password visibility"
                                    edge="end"
                                    onClick={() => clearSearch()}
                                 >
                                    <ClearIcon />
                                 </IconButton>
                                 : null}
                           </InputAdornment>
                        }
                     />
                  </div>
                  <div className="text-right">
                     <Button onClick={openCategoryModal} className="button-btn cat-button new-btn-color" disabled={isLoading} > Add Gift Category</Button>
                  </div>
               </div>
            </Grid>
         </Grid>

         <div className="category-grid" >
            {!isLoading ?
               <Grid container spacing={4} className="category-section">
                  {
                     categories && categories.Items && categories.Items.length ?
                        categories.Items.map(category => {
                           const { image = "", giftCategoryName = "", total_questions = "0", pending_question = "0", id = "" } = category
                           return <Grid item xs={12} sm={12} md={6} lg={3} className="category-box" >
                              <div className="inner-category" onClick={() => onSingleCategory(category)}
                                 key={id} onMouseEnter={() => mouseEnter(id)} onMouseLeave={() => mouseLeave(id)} >
                                 <div className="inner-category-left">
                                    <img src={image} alt="" />
                                 </div>
                                 <div className="inner-category-right">
                                    <h6> {giftCategoryName || '-'} </h6>
                                    {/* <p>Total Questions: {total_questions || '0'}</p>
                                    <p>Pending Questions: {pending_question || '0'}</p> */}

                                    {
                                       hoverId === id ?
                                          <div className="action-btn">
                                             <CustomToolTip title="Delete">
                                                <span className="delete-icon mr-1" onClick={(e) => { e.stopPropagation() }}> <img src={deleteIcon} onClick={() => onDeleteButton(category.id)} alt="" /></span>
                                             </CustomToolTip>
                                             <CustomToolTip title="Edit">
                                                <span className="edit-icon" onClick={(e) => { e.stopPropagation() }}> <img src={editIcon} onClick={(e) => onEditCategory(e, category)} alt="" /></span>
                                             </CustomToolTip>
                                          </div>
                                          :
                                          ''
                                    }
                                 </div>
                              </div>
                           </Grid>
                        })
                        : <Grid item lg={12}> <NoDataFound /> </Grid>
                  }
               </Grid>

               :
               <div className="table-loader">
                  <img src={loader} alt="" />
               </div>
            }
         </div>
         <CustomDialogBox
            open={openDeleteModal}
            handleClose={() => setOpenDeleteModal(false)}
            title="Warning"
            dialogtext={`Are you sure you want to delete this category?`}
            confirmAction={onCategoryDelete}
            isLoading={isLoading}
            text="Keep Category"
         />

         <CustomModal open={addCategoryModal}>
            <AddCategoryForm
               {...props}
               onClose={closeCategoryModal}
               toast={toast}
               categoryDetails={categoryDetails}
               update={isEdit}
               afterAction={afterAction}
            />
         </CustomModal>
      </div>
   )
}

export default Categories;
