import { apiDelete, apiGet, apiPost, apiPut } from "../../../utils/axios";

export function createQuestionAPI(data) {
    return apiPost(`/jury_cat/questions`, data)
}

export function updateQuestionAPI(data, id,) {
    return apiPut(`/jury_cat/questions/${id}`, data)
}

export function getQuestionAPI(data) {
    return apiGet(`/jury_cat/questions?cat_id=${data}`)
}

export function deleteQuestionAPI(data) {
    return apiDelete(`/jury_cat/questions/${data}`)
}

export function getQuestionByFilterAPI(data) {
    let payload = {
        term: data && data.term || '',
        type: 'question'
    }
    return apiGet(`/jury_cat/questions`, payload)
}