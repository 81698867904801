import {
    createQuestionAPI,
    updateQuestionAPI,
    getQuestionAPI,
    deleteQuestionAPI,
    getQuestionByFilterAPI
} from './questionAPI'

export function CreateQuestion(data) {
    return dispatch => {
    dispatch({
      type : 'CREATE_QUESTION'
    })
    return new Promise((resolve , rej) => {
      createQuestionAPI(data).then(res => {
        dispatch({
          type : 'CREATE_QUESTION_SUCCESS',
          payload : res
        })
        return resolve(res)
      })
      .catch(err => {
        dispatch({
          type : 'CREATE_QUESTION_FAILED',
        })
        return rej(err)
      })
    })
  }
}

export const CREATE_QUESTION = 'CREATE_QUESTION' 
export const CREATE_QUESTION_SUCCESS = 'CREATE_QUESTION_SUCCESS' 
export const CREATE_QUESTION_FAILED = 'CREATE_QUESTION_FAILED' 


export function GetQuestion(data) {
    return dispatch => {
    dispatch({
      type : 'GET_QUESTION'
    })
    return new Promise((resolve , rej) => {
      getQuestionAPI(data).then(res => {
        dispatch({
          type : 'GET_QUESTION_SUCCESS',
          payload : res
        })
        return resolve(res)
      })
      .catch(err => {
        dispatch({
          type : 'GET_QUESTION_FAILED',
        })
        return rej(err)
      })
    })
  }
}

export function GetQuestionByFilter(data) {
    return dispatch => {
    dispatch({
      type : 'GET_QUESTION'
    })
    return new Promise((resolve , rej) => {
      getQuestionByFilterAPI(data).then(res => {
        dispatch({
          type : 'GET_QUESTION_SUCCESS',
          payload : res
        })
        return resolve(res)
      })
      .catch(err => {
        dispatch({
          type : 'GET_QUESTION_FAILED',
        })
        return rej(err)
      })
    })
  }
}

export const GET_QUESTION = 'GET_QUESTION' 
export const GET_QUESTION_SUCCESS = 'GET_QUESTION_SUCCESS' 
export const GET_QUESTION_FAILED = 'GET_QUESTION_FAILED' 


export function EditQuestion(data, id) {
    return dispatch => {
    dispatch({
      type : 'UPDATE_QUESTION'
    })
    return new Promise((resolve , rej) => {
      updateQuestionAPI(data, id).then(res => {
        dispatch({
          type : 'UPDATE_QUESTION_SUCCESS',
        })
        return resolve(res)
      })
      .catch(err => {
        dispatch({
          type : 'UPDATE_QUESTION_FAILED',
        })
        return rej(err)
      })
    })
  }
}

export const UPDATE_QUESTION = 'UPDATE_QUESTION' 
export const UPDATE_QUESTION_SUCCESS = 'UPDATE_QUESTION_SUCCESS' 
export const UPDATE_QUESTION_FAILED = 'UPDATE_QUESTION_FAILED' 


export function DeleteQuestion(data) {
    return dispatch => {
    dispatch({
      type : 'DELETE_QUESTION'
    })
    return new Promise((resolve , rej) => {
      deleteQuestionAPI(data).then(res => {
        dispatch({
          type : 'DELETE_QUESTION_SUCCESS',
        })
        return resolve(res)
      })
      .catch(err => {
        dispatch({
          type : 'DELETE_QUESTION_FAILED',
        })
        return rej(err)
      })
    })
  }
}

export const DELETE_QUESTION = 'DELETE_QUESTION' 
export const DELETE_QUESTION_SUCCESS = 'DELETE_QUESTION_SUCCESS' 
export const DELETE_QUESTION_FAILED = 'DELETE_QUESTION_FAILED' 
