import React, { useEffect, useState, useCallback } from 'react'
import {
    Grid,
    TableContainer,
    Table,
    TableRow,
    TableCell,
    TableBody,
    withStyles,
    Button,
    Box,
    MenuItem,
    TextField,
    InputAdornment,
    IconButton
} from '@material-ui/core'
import Datepicker from "../../components/common/Datepicker";
import CustomSelect from '../../components/common/CustomSelect'
import InputField from "../../components/common/InputField"
import { dateFilter, isPhoneNumber } from '../../constant'
import { useDispatch, useSelector } from 'react-redux';
import * as action from './actions'
import { withTranslation } from "react-i18next"
import EnhancedTableHead from '../../components/common/EnhancedTableHead'
import CustomTablePagination from '../../components/common/CustomPagination';
import CustomToolTip from "../../components/common/ToolTip";
import deleteIcon from '../../assets/images/deleteIcon.svg'
import editIcon from '../../assets/images/editIcon.svg';
import { stableSort, getComparator, tablestyle, getTimeStamps, } from "../../utils"
import loader from '../../assets/images/loader.gif'
import NoDataFound from "../../components/common/NoDataFound";
import moment from 'moment';
import CustomModal from '../../components/common/CustomModal';
import CreateEpisode from './createEpisodeForm';
import CustomDialogBox from '../../components/common/CustomDialogBox';
import ClearIcon from '@material-ui/icons/Clear';
import _ from "lodash";

const headCells = [
    { id: "index", numeric: false, disablePadding: false, label: "S.No." },
    { id: "episode", numeric: false, disablePadding: false, label: "Episode" },
    { id: "channel", numeric: false, disablePadding: false, label: "Channel" },
    { id: "question", numeric: false, disablePadding: false, label: "Start Time" },
    { id: "user_name", numeric: false, disablePadding: false, label: "End Time" },
    { id: "a", numeric: false, disablePadding: false, label: "Action" },
];


function Episode(props) {
    const { toast } = props

    const dispatch = useDispatch()

    const { episode = {}, isLoading = false } = useSelector(state => state.episode) || {}
    const { data = [], total = "", current_page = '' } = episode

    const [search, setSearch] = useState('')
    const [episodeFilterSelect, setEpisodeFilterSelect] = useState('')
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('')
    const [rowsPerPage, setRowsPerPage] = useState(25)
    const [currentPage, setCurrentPage] = useState(0)
    const [filter, setFilter] = useState({ label: 'All', value: 'all' })
    const [startDate, setStartDate] = useState("")
    const [endDate, setEndDate] = useState("")
    const [focusedInput, setFocusedInput] = useState(false)
    const [timeOut, setTimeOut] = useState(null)
    const [openEpisodeModal, setOpenEpisodeModal] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [episodeDetails, setEpisodeDetails] = useState({})
    const [openEpisodeDeleteModal, setOpenEpisodeDeleteModal] = useState(false)
    const [episodeId, setEpisodeId] = useState(null)
    const [dataSelected, setDataSelected] = useState(false)

    useEffect(() => {
        if (!startDate || !endDate) return;
        getData()
    }, [startDate, endDate])


    useEffect(() => {
        if (filter?.value) {
            const { startDate: newStartDate, endDate: newEndDate } = getTimeStamps(filter.value, startDate, endDate)
            if (filter.value !== 'customDate') {
                setEndDate(moment(newEndDate))
                setStartDate(moment(newStartDate))
            }
        }
    }, [filter])

    const startDateValue = moment(startDate).startOf('day').valueOf()
    const endDateValue = moment(endDate).endOf('day').valueOf()

    function getData() {
        const data = {
            startDate: startDateValue,
            endDate: endDateValue,
        }
        console.log(filter.value, 'fffffffffffffffff');

        if (dataSelected) data.type = 'start_time_ts'
        if (filter.value == 'all') data.type = ''

        dispatch(action.searchEpisodeByFilter({ ...data, key: episodeFilterSelect, startDate: data?.startDate, endDate: data?.endDate, limit: rowsPerPage, start: currentPage, term: search }))
    }


    const handleChange = (e) => {
        const { name = "", value = "" } = e.target;

        if (episodeFilterSelect === "episode" && value) {
            if (!isPhoneNumber.test(value)) return
        }
        setSearch(value)
        searchDebounce(value, episodeFilterSelect, rowsPerPage, dataSelected, filter, startDateValue, endDateValue);
        props.history.replace(`/episode?currentPage=${0}&rowsPerPage=${5}`)
    }

    const searchDebounce = useCallback(
        _.debounce((value, episodeFilterSelect, rowsPerPage, dataSelected, filter, startDateValue, endDateValue) => {
            if (value.length != 0) {
                let type
                if (value && value.trim())
                    if (dataSelected) type = 'created_at_ts'
                if (filter.value == 'all') type = ''

                dispatch(action.searchEpisodeByFilter({ limit: rowsPerPage, type: type, key: episodeFilterSelect, start: currentPage, term: value, startDate: startDateValue, endDate: endDateValue }));
                setCurrentPage(0)
            } else {
                dispatch(action.getEpisode({ limit: rowsPerPage }))
            }
        }, 700),
        []
    );


    const handleItemType = ({ target: { value } }) => {
        setEpisodeFilterSelect(value)
        setSearch('')
        // if (search !== '') (
        //     dispatch(action.GetJuryResult())
        // )
    }

    function handleDateChange({ startDate, endDate }) {
        if (startDate) setStartDate(startDate)
        if (endDate) setEndDate(endDate)
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc')
        setOrderBy(property)
    }

    const handleChangePage = (event, currentPage, pageLimit) => {
        setCurrentPage(currentPage)
        dispatch(action.searchEpisodeByFilter({ limit: rowsPerPage, page: currentPage + 1, term: search, key: episodeFilterSelect, startDate: startDateValue, endDate: endDateValue }));
        props.history.replace(`/episode?page=${currentPage}&limit=${rowsPerPage}`)
    }

    const handleChangeRowsPerPage = (rowsPerPage) => {
        let { value = 10 } = rowsPerPage.target;
        value = value === "All" ? props.customer.length : value
        setRowsPerPage(value)
        setCurrentPage(0)
        dispatch(action.searchEpisodeByFilter({ limit: value, start: currentPage, term: search, key: episodeFilterSelect, startDate: startDateValue, endDate: endDateValue }))
        props.history.replace(`/episode?page=${currentPage}&limit=${value}`)
    }

    const openAddEpisodeModal = () => {
        setOpenEpisodeModal(true)
    }

    const closeEpisodeModal = () => {
        setOpenEpisodeModal(false)
        setEpisodeDetails({})
        setEpisodeId(null)
        setIsEdit(false)
    }

    const editModal = (e, item) => {
        const { id = '' } = item
        setEpisodeDetails(item)
        setEpisodeId(id)
        setOpenEpisodeModal(true)
        setIsEdit(true)
    }

    const deleteModal = (id) => {
        setOpenEpisodeDeleteModal(true)
        setEpisodeId(id)
    }

    const afterAction = () => {
        setSearch('')
        setEpisodeId(null)
    }

    const deleteEpisode = () => {
        dispatch(action.deleteEpisode(episodeId))
            .then(res => {
                dispatch(action.searchEpisodeByFilter({ startDate: startDateValue, endDate: endDateValue, limit: rowsPerPage }))
                toast.success("Episode has been deleted successfully")
                setOpenEpisodeDeleteModal(false)
                afterAction()
            })
            .catch(({ message = '' }) => {
                toast.error(message || 'Oops! Something went wrong')
            })

    }

    const clearSearch = () => {
        setSearch('')
        dispatch(action.searchEpisodeByFilter())
    }

    const episodeSelect = [
        { id: 2, value: 'channel_name', label: 'Channel' },
        { id: 3, value: 'episode', label: 'Episode' },
    ]

    let labelData = episodeSelect.find(item => item.value === episodeFilterSelect);


    return (
        <div className="user-page winner">
            <Grid container spacing={3} className="mb-3 heading-sec">
                <Grid item xs={12} sm={12} md={12} lg={2} className="align-self-center">
                    <h5 className="page-heading" >Episode</h5>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={10} className="custom-date-field d-flex align-items-center justify-content-end">
                    <Box className="cust-formfields">
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={7} lg={7} >
                                {
                                    episodeFilterSelect ?
                                        <InputField type="search" value={search} name={search} label={`Search By ${labelData && labelData.label || ''}`}
                                            inputProps={{ maxlength: 40 }} placeholder="Search Episode" onChange={(e) => handleChange(e)} fullWidth
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    {search.length ? <IconButton aria-label="toggle password visibility" edge="end" onClick={() => clearSearch()}>
                                                        <ClearIcon />
                                                    </IconButton>
                                                        : null}
                                                </InputAdornment>
                                            } />
                                        :
                                        ''
                                }
                            </Grid>
                            <Grid item xs={12} sm={12} md={5} lg={5} >
                                <TextField
                                    id="outlined-select-currency"
                                    select
                                    fullWidth
                                    label="Search By"
                                    variant="outlined"
                                    onChange={handleItemType}
                                    value={episodeFilterSelect}
                                    className="form-group"
                                >
                                    {
                                        episodeSelect && episodeSelect.map(customer => {
                                            return <MenuItem key={customer.id} value={customer.value}>{customer.label} </MenuItem>
                                        })
                                    }

                                </TextField>
                            </Grid>
                        </Grid>
                    </Box>

                    <Box className="custom-box" display="flex" justifyContent="flex-end" alignItems="center">
                        <CustomSelect
                            minWidth={180}
                            className="cust-select"
                            options={dateFilter}
                            placeholder="Filter"
                            isSearchable
                            onChange={(opt) => {
                                setFilter(opt)
                                setDataSelected(true)
                                setCurrentPage(0)
                            }}
                            value={filter}
                        />
                        {
                            filter?.value !== "customDate" ?
                                ''
                                :
                                <div className="date-range-picker">
                                    <Datepicker
                                        startDate={startDate}
                                        endDate={endDate}
                                        focusedInput={focusedInput}
                                        setFocusedInput={setFocusedInput}
                                        handleDateChange={handleDateChange}
                                    />
                                </div>
                        }
                    </Box>
                    <Button onClick={openAddEpisodeModal} className="button-btn cat-button new-btn-color" disabled={isLoading} > Add Episode </Button>
                </Grid>
            </Grid>
            <div className="cust-table">
                {!isLoading ?
                    <div>
                        <TableContainer className={`${props.classes.container} cus-winners-inner`}>
                            <Table className="table-program" stickyHeader aria-label="sticky table" id="customer-table">
                                <EnhancedTableHead
                                    order={order}
                                    orderBy={orderBy}
                                    onRequestSort={handleRequestSort}
                                    headCells={headCells}
                                />
                                <TableBody>
                                    {data && data.length ?
                                        stableSort(data || [], getComparator(order, orderBy)).map((item, index) => {
                                            const { episode = "", channel_name = "", start_time_with_date = "", end_time_with_date = "" } = item

                                            return (
                                                <TableRow hover key={item.id} className="cursor_default"  >
                                                    <TableCell className="table-custom-width" data-title="S NO.">{rowsPerPage * ((currentPage + 1) - 1) + index + 1}. </TableCell>
                                                    <TableCell className="table-custom-width" data-title="episode">{episode || '-'} </TableCell>
                                                    <TableCell className="table-custom-width" data-title="channel">{channel_name || '-'} </TableCell>
                                                    <TableCell className="table-custom-width" data-title="question">{start_time_with_date && moment.utc(start_time_with_date).local().format('llll') || '-'} </TableCell>
                                                    <TableCell className="table-custom-width" data-title="question">{end_time_with_date && moment.utc(end_time_with_date).local().format('llll') || '-'} </TableCell>

                                                    <TableCell className="table-custom-width" data-title="ACTION">
                                                        <div>
                                                            <CustomToolTip title="Edit" >
                                                                <span className="edit-icon mr-1" onClick={(e) => editModal(e, item)}>
                                                                    <img src={editIcon} alt="" />
                                                                </span>
                                                            </CustomToolTip>
                                                            <CustomToolTip title="Delete" >
                                                                <span className="delete-icon" onClick={() => deleteModal(item.id)}>
                                                                    <img src={deleteIcon} alt="" />
                                                                </span>
                                                            </CustomToolTip>
                                                        </div>
                                                        {/* {
                                                            moment(new Date()).isAfter(start_time_with_date) ?
                                                                <div>
                                                                    <CustomToolTip title="Edit" >
                                                                        <span className="edit-icon mr-1" onClick={(e) => editModal(e, item)}>
                                                                            <img src={editIcon} alt="" />
                                                                        </span>
                                                                    </CustomToolTip>
                                                                    <CustomToolTip title="Delete" >
                                                                        <span className="delete-icon" onClick={() => deleteModal(item.id)}>
                                                                            <img src={deleteIcon} alt="" />
                                                                        </span>
                                                                    </CustomToolTip>
                                                                </div>
                                                                :
                                                                '-'
                                                        } */}
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })
                                        :
                                        <TableRow>
                                            <TableCell colSpan={headCells.length + 1} className="text-center"><NoDataFound /></TableCell>
                                        </TableRow>
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <CustomTablePagination
                            count={total || 0}
                            rowsPerPage={rowsPerPage || 0}
                            currentPage={currentPage}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    </div>
                    :
                    <div className="table-loader">
                        <img src={loader} alt="" />
                    </div>
                }
            </div>

            <CustomDialogBox
                handleClose={() => setOpenEpisodeDeleteModal(false)}
                confirmAction={deleteEpisode}
                open={openEpisodeDeleteModal}
                title="Warning"
                dialogtext={`Are you sure you want to delete?`}
                isLoading={isLoading}
                text="Keep Episode"
            />

            <CustomModal open={openEpisodeModal} >
                <CreateEpisode
                    {...props}
                    onClose={closeEpisodeModal}
                    toast={toast}
                    episodeDetails={episodeDetails}
                    update={isEdit}
                    afterAction={afterAction}
                    startDate={startDate}
                    endDate={endDate}
                    rowsPerPage={rowsPerPage}
                />
            </CustomModal>

        </div>
    )
}

export default withTranslation("translations")(withStyles(tablestyle)(Episode));