import {
  
GET_JURY_RESULT_LIST_REQUEST,
GET_JURY_RESULT_LIST_REQUEST_SUCCEEDED,
GET_JURY_RESULT_LIST_REQUEST_FAILED,

DELETE_JURY_RESULT,
DELETE_JURY_RESULT_SUCCESS, 
DELETE_JURY_RESULT_FAILED,

CREATE_JURY_RESULT,
CREATE_JURY_RESULT_SUCCESS, 
CREATE_JURY_RESULT_FAILED,

UPDATE_JURY_RESULT,
UPDATE_JURY_RESULT_SUCCESS,
UPDATE_JURY_RESULT_FAILED,

} from './actions'

const initState = {
  isLoading: false,
  juryResult : {},
}

export default function (state = { ...initState }, action) {
  switch (action.type) {
    case GET_JURY_RESULT_LIST_REQUEST : 
      return {...state , isLoading : true}
    
    case GET_JURY_RESULT_LIST_REQUEST_SUCCEEDED : 
      return {...state , isLoading : false , juryResult : action.payload || {} }

    case GET_JURY_RESULT_LIST_REQUEST_FAILED : 
      return {...state , isLoading : false , juryResult : {}}


    case DELETE_JURY_RESULT : 
      return {...state, isLoading : true}
    
    case DELETE_JURY_RESULT_SUCCESS : 
      return {...state, isLoading : false}

    case DELETE_JURY_RESULT_FAILED : 
      return {...state, isLoading : false}
    

    case CREATE_JURY_RESULT : 
      return {...state , isLoading : true}
    
    case CREATE_JURY_RESULT_SUCCESS : 
      return {...state, isLoading : false}
    
    case CREATE_JURY_RESULT_FAILED : 
      return {...state, isLoading : false}


    case UPDATE_JURY_RESULT : 
      return {...state, isLoading : true}
    
    case UPDATE_JURY_RESULT_SUCCESS : 
      return {...state, isLoading : false}

    case UPDATE_JURY_RESULT_FAILED : 
      return {...state, isLoading : false}
    
    default:
      return state
  }
}