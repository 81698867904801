import {
  
GET_CATEGORIES,
GET_CATEGORIES_SUCCESS,
GET_CATEGORIES_FAILED,

DELETE_CATEGORY,
DELETE_CATEGORY_SUCCESS, 
DELETE_CATEGORY_FAILED,

GET_CATEGORY_BY_ID ,
GET_CATEGORY_BY_ID_SUCCESS,
GET_CATEGORY_BY_ID_FAILED,

CREATE_CATEGORY,
CREATE_CATEGORY_SUCCESS, 
CREATE_CATEGORY_FAILED,

EDIT_CATEGORY,
EDIT_CATEGORY_SUCCESS,
EDIT_CATEGORY_FAILED,

} from './actions'

const initState = {
  isLoading: false,
  category : {},
  categoryById : {}
}

export default function (state = { ...initState }, action) {
  switch (action.type) {
    case GET_CATEGORIES : 
      return {...state , isLoading : true}
    
    case GET_CATEGORIES_SUCCESS : 
      return {...state , isLoading : false , category : action.payload || {} }

    case GET_CATEGORIES_FAILED : 
      return {...state , isLoading : false , category : {}}


    case DELETE_CATEGORY : 
      return {...state, isLoading : true}
    
    case DELETE_CATEGORY_SUCCESS : 
      return {...state, isLoading : false}

    case DELETE_CATEGORY_FAILED : 
      return {...state, isLoading : false}
    

    case GET_CATEGORY_BY_ID : 
      return {...state , isLoading : true}

    case GET_CATEGORY_BY_ID_SUCCESS : 
      return {...state, isLoading : false , categoryById : action.payload || {} }
    
    case GET_CATEGORY_BY_ID_FAILED : 
      return {...state, isLoading : false, categoryById : {} }


    case CREATE_CATEGORY : 
      return {...state , isLoading : true}
    
    case CREATE_CATEGORY_SUCCESS : 
      return {...state, isLoading : false}
    
    case CREATE_CATEGORY_FAILED : 
      return {...state, isLoading : false}


    case EDIT_CATEGORY : 
      return {...state, isLoading : true}
    
    case EDIT_CATEGORY_SUCCESS : 
      return {...state, isLoading : false}

    case EDIT_CATEGORY_FAILED : 
      return {...state, isLoading : false}
    
    default:
      return state
  }
}