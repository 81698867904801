import {
  GetTableWinnerAPI,
  DeleteTableWinerAPI,
  UpdateTableWinnerAPI,
  CreateTableWinnerAPI,
  searchTableWinnerAPI
} from './apis'

export function GetTableWinner(data) {
    return dispatch => {
        dispatch({
            type: GET_TABLE_WINNER_LIST_REQUEST,
        })
        return new Promise((resolve, rej) => {
            GetTableWinnerAPI(data).then(res => {
                dispatch({
                    type: GET_TABLE_WINNER_LIST_REQUEST_SUCCEEDED,
                    payload: res
                })
                return resolve(res)
            })
            .catch(err => {
                dispatch({
                    type: GET_TABLE_WINNER_LIST_REQUEST_FAILED,
                    payload: {},
                })
                return rej(err)
            })
        })
    }
}

export function GetTableWinnerFilter(data) {
    return dispatch => {
        dispatch({
            type: GET_TABLE_WINNER_LIST_REQUEST,
        })
        return new Promise((resolve, rej) => {
            searchTableWinnerAPI(data).then(res => {
                dispatch({
                    type: GET_TABLE_WINNER_LIST_REQUEST_SUCCEEDED,
                    payload: res
                })
                return resolve(res)
            })
            .catch(err => {
                dispatch({
                    type: GET_TABLE_WINNER_LIST_REQUEST_FAILED,
                    payload: {},
                })
                return rej(err)
            })
        })
    }
}


export const GET_TABLE_WINNER_LIST_REQUEST = 'GET_TABLE_WINNER_LIST_REQUEST'
export const GET_TABLE_WINNER_LIST_REQUEST_SUCCEEDED = 'GET_TABLE_WINNER_LIST_REQUEST_SUCCEEDED'
export const GET_TABLE_WINNER_LIST_REQUEST_FAILED = 'GET_TABLE_WINNER_LIST_REQUEST_FAILED'


export function DeleteTableWiner(data) {
    return dispatch => {
        dispatch({
            type: DELETE_TABLE_WINNER
        })
        return new Promise((resolve, rej) => {
            DeleteTableWinerAPI(data).then(res => {
                dispatch({
                    type: DELETE_TABLE_WINNER_SUCCESS,
                })
                return resolve(res)
            })
                .catch(err => {
                    dispatch({
                        type: DELETE_TABLE_WINNER_FAILED,
                    })
                    return rej(err)
                })
        })
    }
}

export const DELETE_TABLE_WINNER = "DELETE_TABLE_WINNER";
export const DELETE_TABLE_WINNER_SUCCESS = "DELETE_TABLE_WINNER_SUCCESS";
export const DELETE_TABLE_WINNER_FAILED = "DELETE_TABLE_WINNER_FAILED"

export function UpdateTableWinner(data, id) {
    return dispatch => {
        dispatch({
            type : 'UPDATE_TABLE_WINNER'
        })
        return new Promise((resolve, rej) => {
            UpdateTableWinnerAPI(data, id).then(res => {
                dispatch({
                    type : 'UPDATE_TABLE_WINNER_SUCCESS'
                })
                return resolve(res)
            })
            .catch(err => {
                dispatch({
                    type : 'UPDATE_TABLE_WINNER_FAILED'
                })
                return rej(err)
            })
        })
    }
}

export const UPDATE_TABLE_WINNER = "UPDATE_TABLE_WINNER"
export const UPDATE_TABLE_WINNER_SUCCESS = "UPDATE_TABLE_WINNER_SUCCESS"
export const UPDATE_TABLE_WINNER_FAILED = "UPDATE_TABLE_WINNER_FAILED"

export function CreateTableWinner(data) {
  return dispatch => {
    dispatch({
      type : 'CREATE_TABLE_WINNER'
    })
    return new Promise((resolve, rej) => {
      CreateTableWinnerAPI(data).then(res => {
        dispatch({
          type : 'CREATE_TABLE_WINNER_SUCCESS'
        })
        return resolve(res)
      })
      .catch(err => {
        dispatch({
          type : 'CREATE_TABLE_WINNER_FAILED'
        })
        return rej(err)
      })
    })
  }
}

export const CREATE_TABLE_WINNER = 'CREATE_TABLE_WINNER'
export const CREATE_TABLE_WINNER_SUCCESS = "CREATE_TABLE_WINNER_SUCCESS"
export const CREATE_TABLE_WINNER_FAILED = "CREATE_TABLE_WINNER_FAILED"
